/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqMonthlyStatsGrid from './EqMonthlyStatsGrid.js';

export default function EqMonthlyStatsFilteredGrid(props) {
	return <EqMonthlyStatsGrid
				reference="EqMonthlyStatsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}