import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import FleetsTreeEditor from '../components/Tree/FleetsTreeEditor.js';
import FleetsSideTreeEditor from '../components/Tree/FleetsSideTreeEditor.js';

export default function FleetsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'FleetsManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Fleets"
				reference="FleetsManager"
				fullModeComponent={<FleetsTreeEditor
										reference="FleetsTreeEditor"
										// useRemoteDuplicate={true}
									/>}
				sideModeComponent={<FleetsSideTreeEditor
										reference="FleetsTreeEditor"
										// useRemoteDuplicate={true}
									/>}
			/>;
}
