/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EquipmentPmSchedulesGridEditorSideA from './EquipmentPmSchedulesGridEditorSideA.js';

export default function EquipmentPmSchedulesFilteredGridEditorSideA(props) {
	return <EquipmentPmSchedulesGridEditorSideA
				reference="EquipmentPmSchedulesFilteredGridEditorSideA"
				useFilters={true}

				{...props}
			/>;
}