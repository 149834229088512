export default [
	    {
	        "id": "enterprises__name",
	        "header": "Name",
	        "fieldName": "enterprises__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 250 // MOD
	    },
	    {
	        "id": "enterprises__is_enabled",
	        "header": "Enabled?",
	        "fieldName": "enterprises__is_enabled",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "enterprises__enterprise_type_id",
	        "header": "Enterprise Type",
	        "fieldName": "enterprise_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "enterprises__enterprise_type_id"
	    },
	    {
	        "id": "enterprises__exclude_before",
	        "header": "Exclude Before",
	        "fieldName": "enterprises__exclude_before",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];