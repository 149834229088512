/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import {
	Column,
	Text,
} from 'native-base';
import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import EquipmentEditorWindow from '../Window/EquipmentEditorWindow.js';
import EquipmentGridColumns from './Columns/EquipmentGridColumns.js';
import { useSelector } from 'react-redux';
import {
	selectTreeSelection,
} from '../../models/Slices/AppSlice';
import _ from 'lodash';

export default function EquipmentGrid(props) {
	const
		treeSelection = useSelector(selectTreeSelection);

	if (_.isEmpty(treeSelection)) {
		return <Column flex={1} w="100%" alignItems="center" justifyContent="center">
					<Text textAlign="center">Please select a fleet on the tree</Text>
				</Column>;
	}

	return <Grid
				reference="EquipmentGrid"
				model="Equipment"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EquipmentEditorWindow}
				selectorId="equipment__fleet_id"
				selectorSelected={{ treeSelection }}
				columnsConfig={EquipmentGridColumns}

				{...props}
			/>;
}