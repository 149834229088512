/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import PmEventsEditor from '../../Editor/PmEventsEditor.js';
import PmEventsGridColumns from '../../Grid/Columns/PmEventsGridColumns.js';

export default function PmEventsSideGridEditorPanel(props) {
	return <GridPanel
				reference="PmEventsSideGridEditorPanel"
				model="PmEvents"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={PmEventsEditor}
				columnsConfig={PmEventsGridColumns}
				
				
				{...props}
			/>;
}