/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import WoStatusesEditor from '../../Editor/WoStatusesEditor.js';
import WoStatusesGridColumns from '../../Grid/Columns/WoStatusesGridColumns.js';

export default function WoStatusesSideGridEditorPanel(props) {
	return <GridPanel
				reference="WoStatusesSideGridEditorPanel"
				model="WoStatuses"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={WoStatusesEditor}
				columnsConfig={WoStatusesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}