import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Manufacturers = {
	
	name: 'Manufacturers',
	
	model: {

		idProperty: 'manufacturers__id',
		displayProperty: 'manufacturers__name',
		sortProperty: 'manufacturers__name',
		
		sorters: null,

		validator: yup.object({
			manufacturers__name: yup.string().required(),
			manufacturers__abbr: yup.string().max(25).nullable(),
			manufacturers__nds_abbr: yup.string().max(25).nullable(),
			manufacturers__is_equipment: yup.boolean().nullable(),
			manufacturers__is_part: yup.boolean().nullable()
		}),
		
		properties: [
			{ name: 'manufacturers__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'manufacturers__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'manufacturers__abbr', mapping: 'abbr', title: 'Abbr', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'manufacturers__nds_abbr', mapping: 'nds_abbr', title: 'NDS Abbr', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'manufacturers__is_equipment', mapping: 'is_equipment', title: 'For Equipment?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Switches', defaultValue: 1, },
			{ name: 'manufacturers__is_part', mapping: 'is_part', title: 'For Part?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Switches', }
		],

		associations: {
			hasMany: [
				'Parts',
				'Equipment'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'manufacturers__is_equipment',
			// 'manufacturers__is_part'
		],

	},

	entity: {
		methods: {

			getParts: function() {
				const Parts = this.getAssociatedRepository('Parts');
				return Parts.getBy((entity) => {
					return entity.parts__manufacturer_id === this.manufacturers__id;
				});
			},

			getEquipment: function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				return Equipment.getBy((entity) => {
					return entity.equipment__manufacturer_id === this.manufacturers__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Manufacturers;
