/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function EqMonthlyStatsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "eq_monthly_stats__equipment_id"
		                    },
		                    {
		                        "name": "eq_monthly_stats__month"
		                    },
		                    {
		                        "name": "eq_monthly_stats__latest_meter_reading"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtbf"
		                    },
		                    {
		                        "name": "eq_monthly_stats__latest_repair_date"
		                    },
		                    {
		                        "name": "eq_monthly_stats__overdue_pms"
		                    },
		                    {
		                        "name": "eq_monthly_stats__latest_pm_date"
		                    },
		                    {
		                        "name": "eq_monthly_stats__next_pm_date"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_hours"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_estimated_hours"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_available_hours"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_util"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_preventive"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_abuse"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_tires"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_repair"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_ltm"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_preventive_ltm"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_abuse_ltm"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_tires_ltm"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_repair_ltm"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_maint_combined"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_ltm_billing"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_ownership"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_cost_per_hr"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_cost_per_hr_customer"
		                    },
		                    {
		                        "name": "eq_monthly_stats__mtd_cost_per_hr_ltm"
		                    },
		                    {
		                        "name": "eq_monthly_stats__cust_month_total"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="EqMonthlyStatsEditor"
				title="EqMonthlyStats"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

