/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqPowerTypesEditor from '../Editor/EqPowerTypesEditor.js';
import EqPowerTypesGridColumns from './Columns/EqPowerTypesGridColumns.js';

export default function EqPowerTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="EqPowerTypesSideGridEditor"
				model="EqPowerTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={EqPowerTypesEditor}
				columnsConfig={EqPowerTypesGridColumns}
				
				
				{...props}
			/>;
}