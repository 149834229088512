import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Equipment = {
	
	name: 'Equipment',
	usesEnterpriseId: true,
	model: {

		idProperty: 'equipment__id',
		displayProperty: 'equipment__unit_number',
		sortProperty: 'equipment__unit_number',
		
		sorters: null,

		validator: yup.object({
			equipment__eq_status_id: yup.number().integer().required(),
			equipment__year: yup.string().max(4).nullable(),
			equipment__manufacturer_id: yup.number().integer().nullable(),
			equipment__model: yup.string().max(25).nullable(),
			equipment__serial_number: yup.string().max(50).nullable(),
			equipment__unit_number: yup.string().max(25).nullable(),
			equipment__asset_number: yup.string().max(25).nullable(),
			equipment__dept_number: yup.string().max(25).nullable(),
			equipment__business_partner_id: yup.number().integer().nullable(),
			equipment__eq_type_id: yup.number().integer().nullable(),
			equipment__tel_manufacturer_id: yup.number().integer().nullable(),
			equipment__tel_pid: yup.string().nullable(),
			equipment__comments: yup.string().nullable(),
			equipment__is_misc: yup.boolean().required(),
			equipment__is_pm_enable: yup.boolean().required(),
			equipment__pm_schedules: yup.string().nullable(),
			equipment__expected_hours_per_month: yup.number().integer().required(),
			equipment__available_hours_per_week: yup.number().integer().nullable(),
			equipment__in_service_date: yup.date().nullable(),
			equipment__in_service_meter: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'equipment__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: '10. Internal Use', },
			{ name: 'equipment__enterprise_id', mapping: 'enterprise_id', title: 'Enterprise', type: 'int', isFk: true, fkIdField: 'enterprises__id', fkDisplayField: 'enterprises__name', filterType: {"type":"EnterprisesCombo","loadAfterRender":!1}, editorType: {"type":"EnterprisesComboEditor","loadAfterRender":!1}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__fleet_id', mapping: 'fleet_id', title: 'Fleet', type: 'int', isFk: true, fkIdField: 'fleets__id', fkDisplayField: 'fleets__name', filterType: {"type":"FleetsCombo","loadAfterRender":!1}, editorType: {"type":"FleetsComboEditor","loadAfterRender":!1}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__eq_status_id', mapping: 'eq_status_id', title: 'Status', type: 'int', isFk: true, fkIdField: 'eq_statuses__id', fkDisplayField: 'eq_statuses__name', filterType: {"type":"EqStatusesCombo","loadAfterRender":!1}, editorType: {"type":"EqStatusesCombo","loadAfterRender":!1}, fieldGroup: '3. Equipment Details', defaultValue: 1, },
			{ name: 'equipment__inactive_date', mapping: 'inactive_date', title: 'Inactive Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__year', mapping: 'year', title: 'Year', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__manufacturer_id', mapping: 'manufacturer_id', title: 'Make', type: 'int', isFk: true, fkIdField: 'manufacturers__id', fkDisplayField: 'manufacturers__name', filterType: {"type":"ManufacturersCombo","loadAfterRender":!1}, editorType: {"type":"ManufacturersComboEditor","loadAfterRender":!1}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__model', mapping: 'model', title: 'Model', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__serial_number', mapping: 'serial_number', title: 'Serial #', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__unit_number', mapping: 'unit_number', title: 'Unit #', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__asset_number', mapping: 'asset_number', title: 'Asset # (Cust ID)', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__dept_number', mapping: 'dept_number', title: 'Dept #', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__business_partner_id', mapping: 'business_partner_id', title: 'Business Partner', type: 'int', isFk: true, fkIdField: 'business_partners__id', fkDisplayField: 'business_partners__name', filterType: {"type":"BusinessPartnersCombo","loadAfterRender":!1}, editorType: {"type":"BusinessPartnersComboEditor","loadAfterRender":!1}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__eq_type_id', mapping: 'eq_type_id', title: 'Type', type: 'int', isFk: true, fkIdField: 'eq_types__id', fkDisplayField: 'eq_types__name', filterType: {"type":"EqTypesCombo","loadAfterRender":!1}, editorType: {"type":"EqTypesComboEditor","loadAfterRender":!1}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__tel_manufacturer_id', mapping: 'tel_manufacturer_id', title: 'Tel Manufacturer', type: 'int', isFk: true, fkIdField: 'tel_manufacturers__id', fkDisplayField: 'tel_manufacturers__name', filterType: {"type":"TelManufacturersCombo","loadAfterRender":!1}, editorType: {"type":"TelManufacturersCombo","loadAfterRender":!1}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__tel_pid', mapping: 'tel_pid', title: 'Tel Pid', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__is_misc', mapping: 'is_misc', title: 'Misc?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Equipment Details', },
			{ name: 'equipment__initial_maint_expense', mapping: 'initial_maint_expense', title: 'Initial Maint $', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. Initial Values', },
			{ name: 'equipment__initial_book_value', mapping: 'initial_book_value', title: 'Book Value $', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. Initial Values', },
			{ name: 'equipment__purchase_price', mapping: 'purchase_price', title: 'Purchase Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. Initial Values', },
			{ name: 'equipment__warranty_expires', mapping: 'warranty_expires', title: 'Warranty Expires', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, editorType: {"type":"Date","mode":"DATE"}, fieldGroup: '5. Initial Values', },
			{ name: 'equipment__warranty_hours', mapping: 'warranty_hours', title: 'Warranty Hours', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. Initial Values', },
			{ name: 'equipment__is_pm_enable', mapping: 'is_pm_enable', title: 'Pm Enable?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. PM Setup', },
			{ name: 'equipment__pm_schedules', mapping: 'pm_schedules', title: 'PM Schedules', filterType: {"type":"PmSchedulesTag"}, viewerType: {"type":"PmSchedulesTag"}, editorType: {"type":"PmSchedulesTagEditor","loadAfterRender":!1}, fieldGroup: '4. PM Setup', },
			{ name: 'equipment__expected_hours_per_month', mapping: 'expected_hours_per_month', title: 'Expected Hours Per Month', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. PM Setup', defaultValue: 150, },
			{ name: 'equipment__available_hours_per_week', mapping: 'available_hours_per_week', title: 'Available Hours Per Week', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. PM Setup', },
			{ name: 'equipment__in_service_date', mapping: 'in_service_date', title: 'In Service', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, editorType: {"type":"Date","mode":"DATE"}, fieldGroup: '4. PM Setup', },
			{ name: 'equipment__in_service_meter', mapping: 'in_service_meter', title: 'In Service Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. PM Setup', },
			{ name: 'equipment__is_track_hours_enable', mapping: 'is_track_hours_enable', title: 'Track Hours Enable?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Usage', defaultValue: 1, },
			{ name: 'equipment__max_hours_allowed', mapping: 'max_hours_allowed', title: 'Max Hours Allowed', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '6. Usage', },
			{ name: 'equipment__expected_revenue_per_month', mapping: 'expected_revenue_per_month', title: 'Expected Revenue Per Month', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '6. Usage', },
			{ name: 'equipment__capacity', mapping: 'capacity', title: 'Capacity', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__eq_upright_type_id', mapping: 'eq_upright_type_id', title: 'Upright Type', type: 'int', isFk: true, fkIdField: 'eq_upright_types__id', fkDisplayField: 'eq_upright_types__name', filterType: {"type":"EqUprightTypesCombo","loadAfterRender":!1}, editorType: {"type":"EqUprightTypesComboEditor","loadAfterRender":!1}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__upright_lhloh', mapping: 'upright_lhloh', title: 'LH / LoH', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__eq_valve_type_id', mapping: 'eq_valve_type_id', title: 'Eq Valve Type', type: 'int', isFk: true, fkIdField: 'eq_valve_types__id', fkDisplayField: 'eq_valve_types__name', filterType: {"type":"EqValveTypesCombo","loadAfterRender":!1}, editorType: {"type":"EqValveTypesComboEditor","loadAfterRender":!1}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__eq_power_type_id', mapping: 'eq_power_type_id', title: 'Eq Power Type', type: 'int', isFk: true, fkIdField: 'eq_power_types__id', fkDisplayField: 'eq_power_types__name', filterType: {"type":"EqPowerTypesCombo","loadAfterRender":!1}, editorType: {"type":"EqPowerTypesComboEditor","loadAfterRender":!1}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__eq_tire_type_id', mapping: 'eq_tire_type_id', title: 'Eq Tire Type', type: 'int', isFk: true, fkIdField: 'eq_tire_types__id', fkDisplayField: 'eq_tire_types__name', filterType: {"type":"EqTireTypesCombo","loadAfterRender":!1}, editorType: {"type":"EqTireTypesComboEditor","loadAfterRender":!1}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__battery_size', mapping: 'battery_size', title: 'Battery Size', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__eq_voltage_id', mapping: 'eq_voltage_id', title: 'Eq Voltage', type: 'int', isFk: true, fkIdField: 'eq_voltages__id', fkDisplayField: 'eq_voltages__name', filterType: {"type":"EqVoltagesCombo","loadAfterRender":!1}, editorType: {"type":"EqVoltagesComboEditor","loadAfterRender":!1}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__eq_fork_length_id', mapping: 'eq_fork_length_id', title: 'Eq Fork Length', type: 'int', isFk: true, fkIdField: 'eq_fork_lengths__id', fkDisplayField: 'eq_fork_lengths__name', filterType: {"type":"EqForkLengthsCombo","loadAfterRender":!1}, editorType: {"type":"EqForkLengthsComboEditor","loadAfterRender":!1}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__eq_attachments', mapping: 'eq_attachments', title: 'Attachments', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__is_fluorescent_stripe', mapping: 'is_fluorescent_stripe', title: 'Fluorescent Striping?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__is_whelen_light', mapping: 'is_whelen_light', title: 'Whelen Lights?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__is_blue_led_light', mapping: 'is_blue_led_light', title: 'Blue LED Spot Light?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__is_high_vis_seatbelt', mapping: 'is_high_vis_seatbelt', title: 'Hi-Vis Seatbelt?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__is_rear_handle_horn', mapping: 'is_rear_handle_horn', title: 'Rear Handle w/Horn?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__is_contact_decals', mapping: 'is_contact_decals', title: '3-Point of Contact Decals?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__is_pallet_protection', mapping: 'is_pallet_protection', title: 'Pallet Protection?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Specifications', },
			{ name: 'equipment__is_laser_light', mapping: 'is_laser_light', title: 'Laser Light?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Specifications', },
			{ name: 'eq_stats__id', mapping: 'eq_stat.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__equipment_id', mapping: 'eq_stat.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__is_stale', mapping: 'eq_stat.is_stale', title: 'Stale?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__path', mapping: 'eq_stat.path', title: 'Path', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_meter_reading', mapping: 'eq_stat.latest_meter_reading', title: 'Latest Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_meter_date', mapping: 'eq_stat.latest_meter_date', title: 'Latest Meter Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_meter_source', mapping: 'eq_stat.latest_meter_source', title: 'Latest Meter Source', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_repair_date', mapping: 'eq_stat.latest_repair_date', title: 'Latest Repair Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_pm_data', mapping: 'eq_stat.latest_pm_data', title: 'Latest Pm Data', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__due_pms', mapping: 'eq_stat.due_pms', title: 'Due Pms', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__next_pm_due_data', mapping: 'eq_stat.next_pm_due_data', title: 'Next Pm Due Data', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__overdue_pms', mapping: 'eq_stat.overdue_pms', title: 'Overdue Pms', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__assigned_technician_data', mapping: 'eq_stat.assigned_technician_data', title: 'Assigned Technician Data', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mean_time_between_failures', mapping: 'eq_stat.mean_time_between_failures', title: 'Mean Time Between Failures', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__estimated_annual_hours', mapping: 'eq_stat.estimated_annual_hours', title: 'Estimated Annual Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__is_warranty', mapping: 'eq_stat.is_warranty', title: 'Warranty?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__is_lease', mapping: 'eq_stat.is_lease', title: 'Lease?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__is_ltm', mapping: 'eq_stat.is_ltm', title: 'Ltm?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__lease_number', mapping: 'eq_stat.lease_number', title: 'Lease Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__lease_term', mapping: 'eq_stat.lease_term', title: 'Lease Term', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__lease_expected_end_date', mapping: 'eq_stat.lease_expected_end_date', title: 'Lease Expected End Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__lease_status_id', mapping: 'eq_stat.lease_status_id', title: 'Lease Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__lease_payment', mapping: 'eq_stat.lease_payment', title: 'Lease Payment', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__lease_pays_remaining', mapping: 'eq_stat.lease_pays_remaining', title: 'Lease Pays Remaining', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__lease_midterm_checked', mapping: 'eq_stat.lease_midterm_checked', title: 'Lease Midterm Checked', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__lease_inspected', mapping: 'eq_stat.lease_inspected', title: 'Lease Inspected', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_contract_number', mapping: 'eq_stat.ltm_contract_number', title: 'Ltm Contract Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_start_date', mapping: 'eq_stat.ltm_start_date', title: 'Ltm Start Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_end_date', mapping: 'eq_stat.ltm_end_date', title: 'Ltm End Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_elapsed_annual_hours', mapping: 'eq_stat.ltm_elapsed_annual_hours', title: 'Ltm Elapsed Annual Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_max_annual_hours', mapping: 'eq_stat.ltm_max_annual_hours', title: 'Ltm Max Annual Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_elapsed_contract_hours', mapping: 'eq_stat.ltm_elapsed_contract_hours', title: 'Ltm Elapsed Contract Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_max_contract_hours', mapping: 'eq_stat.ltm_max_contract_hours', title: 'Ltm Max Contract Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_latest_invoice_date', mapping: 'eq_stat.ltm_latest_invoice_date', title: 'Ltm Latest Invoice Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltm_latest_invoice_amount', mapping: 'eq_stat.ltm_latest_invoice_amount', title: 'Ltm Latest Invoice Amount', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_hours', mapping: 'eq_stat.mtd_hours', title: 'Mtd Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_available_hours', mapping: 'eq_stat.mtd_available_hours', title: 'Mtd Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_util', mapping: 'eq_stat.mtd_util', title: 'Mtd Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint', mapping: 'eq_stat.mtd_maint', title: 'Mtd Maint', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_preventive', mapping: 'eq_stat.mtd_maint_preventive', title: 'Mtd Maint Preventive', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_abuse', mapping: 'eq_stat.mtd_maint_abuse', title: 'Mtd Maint Abuse', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_abuseRental', mapping: 'eq_stat.mtd_maint_abuseRental', title: 'Mtd Maint AbuseRental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_tires', mapping: 'eq_stat.mtd_maint_tires', title: 'Mtd Maint Tires', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_repair', mapping: 'eq_stat.mtd_maint_repair', title: 'Mtd Maint Repair', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_ltm', mapping: 'eq_stat.mtd_maint_ltm', title: 'Mtd Maint Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_preventive_ltm', mapping: 'eq_stat.mtd_maint_preventive_ltm', title: 'Mtd Maint Preventive Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_abuse_ltm', mapping: 'eq_stat.mtd_maint_abuse_ltm', title: 'Mtd Maint Abuse Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_tires_ltm', mapping: 'eq_stat.mtd_maint_tires_ltm', title: 'Mtd Maint Tires Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_repair_ltm', mapping: 'eq_stat.mtd_maint_repair_ltm', title: 'Mtd Maint Repair Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_maint_combined', mapping: 'eq_stat.mtd_maint_combined', title: 'Mtd Maint Combined', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_ltm_billing', mapping: 'eq_stat.mtd_ltm_billing', title: 'Mtd Ltm Billing', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_lease', mapping: 'eq_stat.mtd_lease', title: 'Mtd Lease', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_depreciation', mapping: 'eq_stat.mtd_depreciation', title: 'Mtd Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_cost_per_hr', mapping: 'eq_stat.mtd_cost_per_hr', title: 'Mtd Cost Per Hr', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_cost_per_hr_customer', mapping: 'eq_stat.mtd_cost_per_hr_customer', title: 'Mtd Cost Per Hr Customer', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__mtd_cost_per_hr_ltm', mapping: 'eq_stat.mtd_cost_per_hr_ltm', title: 'Mtd Cost Per Hr Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__qtd_hours', mapping: 'eq_stat.qtd_hours', title: 'Qtd Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__qtd_available_hours', mapping: 'eq_stat.qtd_available_hours', title: 'Qtd Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__qtd_util', mapping: 'eq_stat.qtd_util', title: 'Qtd Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_hours', mapping: 'eq_stat.ytd_hours', title: 'Ytd Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_available_hours', mapping: 'eq_stat.ytd_available_hours', title: 'Ytd Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_util', mapping: 'eq_stat.ytd_util', title: 'Ytd Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint', mapping: 'eq_stat.ytd_maint', title: 'Ytd Maint', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_preventive', mapping: 'eq_stat.ytd_maint_preventive', title: 'Ytd Maint Preventive', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_abuse', mapping: 'eq_stat.ytd_maint_abuse', title: 'Ytd Maint Abuse', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_abuseRental', mapping: 'eq_stat.ytd_maint_abuseRental', title: 'Ytd Maint AbuseRental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_tires', mapping: 'eq_stat.ytd_maint_tires', title: 'Ytd Maint Tires', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_repair', mapping: 'eq_stat.ytd_maint_repair', title: 'Ytd Maint Repair', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_ltm', mapping: 'eq_stat.ytd_maint_ltm', title: 'Ytd Maint Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_preventive_ltm', mapping: 'eq_stat.ytd_maint_preventive_ltm', title: 'Ytd Maint Preventive Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_abuse_ltm', mapping: 'eq_stat.ytd_maint_abuse_ltm', title: 'Ytd Maint Abuse Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_tires_ltm', mapping: 'eq_stat.ytd_maint_tires_ltm', title: 'Ytd Maint Tires Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_repair_ltm', mapping: 'eq_stat.ytd_maint_repair_ltm', title: 'Ytd Maint Repair Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_maint_combined', mapping: 'eq_stat.ytd_maint_combined', title: 'Ytd Maint Combined', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_ltm_billing', mapping: 'eq_stat.ytd_ltm_billing', title: 'Ytd Ltm Billing', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_lease', mapping: 'eq_stat.ytd_lease', title: 'Ytd Lease', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytn_depreciation', mapping: 'eq_stat.ytn_depreciation', title: 'Ytn Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_cost_per_hr', mapping: 'eq_stat.ytd_cost_per_hr', title: 'Ytd Cost Per Hr', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_cost_per_hr_customer', mapping: 'eq_stat.ytd_cost_per_hr_customer', title: 'Ytd Cost Per Hr Customer', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ytd_cost_per_hr_ltm', mapping: 'eq_stat.ytd_cost_per_hr_ltm', title: 'Ytd Cost Per Hr Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_hours', mapping: 'eq_stat.rtm_hours', title: 'Rtm Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_available_hours', mapping: 'eq_stat.rtm_available_hours', title: 'Rtm Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_util', mapping: 'eq_stat.rtm_util', title: 'Rtm Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint', mapping: 'eq_stat.rtm_maint', title: 'Rtm Maint', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_preventive', mapping: 'eq_stat.rtm_maint_preventive', title: 'Rtm Maint Preventive', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_abuse', mapping: 'eq_stat.rtm_maint_abuse', title: 'Rtm Maint Abuse', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_abuseRental', mapping: 'eq_stat.rtm_maint_abuseRental', title: 'Rtm Maint AbuseRental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_tires', mapping: 'eq_stat.rtm_maint_tires', title: 'Rtm Maint Tires', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_repair', mapping: 'eq_stat.rtm_maint_repair', title: 'Rtm Maint Repair', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_ltm', mapping: 'eq_stat.rtm_maint_ltm', title: 'Rtm Maint Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_preventive_ltm', mapping: 'eq_stat.rtm_maint_preventive_ltm', title: 'Rtm Maint Preventive Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_abuse_ltm', mapping: 'eq_stat.rtm_maint_abuse_ltm', title: 'Rtm Maint Abuse Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_tires_ltm', mapping: 'eq_stat.rtm_maint_tires_ltm', title: 'Rtm Maint Tires Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_repair_ltm', mapping: 'eq_stat.rtm_maint_repair_ltm', title: 'Rtm Maint Repair Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_maint_combined', mapping: 'eq_stat.rtm_maint_combined', title: 'Rtm Maint Combined', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_ltm_billing', mapping: 'eq_stat.rtm_ltm_billing', title: 'Rtm Ltm Billing', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_lease', mapping: 'eq_stat.rtm_lease', title: 'Rtm Lease', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_depreciation', mapping: 'eq_stat.rtm_depreciation', title: 'Rtm Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_cost_per_hr', mapping: 'eq_stat.rtm_cost_per_hr', title: 'Rtm Cost Per Hr', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_cost_per_hr_customer', mapping: 'eq_stat.rtm_cost_per_hr_customer', title: 'Rtm Cost Per Hr Customer', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__rtm_cost_per_hr_ltm', mapping: 'eq_stat.rtm_cost_per_hr_ltm', title: 'Rtm Cost Per Hr Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_hours', mapping: 'eq_stat.ltd_hours', title: 'Ltd Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_available_hours', mapping: 'eq_stat.ltd_available_hours', title: 'Ltd Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_util', mapping: 'eq_stat.ltd_util', title: 'Ltd Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint', mapping: 'eq_stat.ltd_maint', title: 'Ltd Maint', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_preventive', mapping: 'eq_stat.ltd_maint_preventive', title: 'Ltd Maint Preventive', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_abuse', mapping: 'eq_stat.ltd_maint_abuse', title: 'Ltd Maint Abuse', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_abuseRental', mapping: 'eq_stat.ltd_maint_abuseRental', title: 'Ltd Maint AbuseRental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_tires', mapping: 'eq_stat.ltd_maint_tires', title: 'Ltd Maint Tires', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_repair', mapping: 'eq_stat.ltd_maint_repair', title: 'Ltd Maint Repair', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_ltm', mapping: 'eq_stat.ltd_maint_ltm', title: 'Ltd Maint Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_preventive_ltm', mapping: 'eq_stat.ltd_maint_preventive_ltm', title: 'Ltd Maint Preventive Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_abuse_ltm', mapping: 'eq_stat.ltd_maint_abuse_ltm', title: 'Ltd Maint Abuse Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_tires_ltm', mapping: 'eq_stat.ltd_maint_tires_ltm', title: 'Ltd Maint Tires Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_repair_ltm', mapping: 'eq_stat.ltd_maint_repair_ltm', title: 'Ltd Maint Repair Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_depreciation', mapping: 'eq_stat.ltd_depreciation', title: 'Ltd Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_maint_combined', mapping: 'eq_stat.ltd_maint_combined', title: 'Ltd Maint Combined', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_ltm_billing', mapping: 'eq_stat.ltd_ltm_billing', title: 'Ltd Ltm Billing', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_lease', mapping: 'eq_stat.ltd_lease', title: 'Ltd Lease', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltn_depreciation', mapping: 'eq_stat.ltn_depreciation', title: 'Ltn Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_cost_per_hr', mapping: 'eq_stat.ltd_cost_per_hr', title: 'Ltd Cost Per Hr', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_cost_per_hr_customer', mapping: 'eq_stat.ltd_cost_per_hr_customer', title: 'Ltd Cost Per Hr Customer', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__ltd_cost_per_hr_ltm', mapping: 'eq_stat.ltd_cost_per_hr_ltm', title: 'Ltd Cost Per Hr Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__id', mapping: 'enterprise.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__name', mapping: 'enterprise.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_enabled', mapping: 'enterprise.is_enabled', title: 'Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__enterprise_type_id', mapping: 'enterprise.enterprise_type_id', title: 'Enterprise Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__pm_mode', mapping: 'enterprise.pm_mode', title: 'PM Mode', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__exclude_before', mapping: 'enterprise.exclude_before', title: 'Exclude Before', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_cost_enabled', mapping: 'enterprise.is_cost_enabled', title: 'Cost Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_demo_mode', mapping: 'enterprise.is_demo_mode', title: 'Demo Mode?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__chrome_id', mapping: 'enterprise.chrome_id', title: 'Chrome', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__id', mapping: 'fleet.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__enterprise_id', mapping: 'fleet.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__name', mapping: 'fleet.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__business_partners', mapping: 'fleet.business_partners', title: 'Business Partners', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_1', mapping: 'fleet.shift_start_1', title: 'Shift Start 1', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_2', mapping: 'fleet.shift_start_2', title: 'Shift Start 2', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_3', mapping: 'fleet.shift_start_3', title: 'Shift Start 3', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'business_partners__id', mapping: 'business_partner.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'business_partners__name', mapping: 'business_partner.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_statuses__id', mapping: 'eq_status.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_statuses__name', mapping: 'eq_status.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_fork_lengths__id', mapping: 'eq_fork_length.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_fork_lengths__name', mapping: 'eq_fork_length.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_power_types__id', mapping: 'eq_power_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_power_types__name', mapping: 'eq_power_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_power_types__sort_order', mapping: 'eq_power_type.sort_order', title: 'Sort Order', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_tire_types__id', mapping: 'eq_tire_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_tire_types__name', mapping: 'eq_tire_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__id', mapping: 'eq_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__name', mapping: 'eq_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__is_forklift', mapping: 'eq_type.is_forklift', title: 'Forklift?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__is_attachment', mapping: 'eq_type.is_attachment', title: 'Attachment?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__is_tugger', mapping: 'eq_type.is_tugger', title: 'Tugger?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__sort_order', mapping: 'eq_type.sort_order', title: 'Sort Order', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_upright_types__id', mapping: 'eq_upright_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_upright_types__name', mapping: 'eq_upright_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_valve_types__id', mapping: 'eq_valve_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_valve_types__name', mapping: 'eq_valve_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_voltages__id', mapping: 'eq_voltage.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_voltages__name', mapping: 'eq_voltage.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__id', mapping: 'manufacturer.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__name', mapping: 'manufacturer.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__abbr', mapping: 'manufacturer.abbr', title: 'Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__nds_abbr', mapping: 'manufacturer.nds_abbr', title: 'NDS Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__is_equipment', mapping: 'manufacturer.is_equipment', title: 'For Equipment?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__is_part', mapping: 'manufacturer.is_part', title: 'For Part?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_manufacturers__id', mapping: 'tel_manufacturer.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_manufacturers__name', mapping: 'tel_manufacturer.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasOne: [
				'EqStats'
			],
			hasMany: [
				'EqMonthlyStats',
				'PmEvents',
				'TelUsages',
				'WorkOrders',
				'EquipmentPmSchedules',
				'MeterReadings',
				'Attachments'
			],
			belongsTo: [
				'Enterprises',
				'Fleets',
				'BusinessPartners',
				'EqStatuses',
				'EqForkLengths',
				'EqPowerTypes',
				'EqTireTypes',
				'EqTypes',
				'EqUprightTypes',
				'EqValveTypes',
				'EqVoltages',
				'Manufacturers',
				'TelManufacturers'
			],
			belongsToMany: [
				'PmSchedules',
				'Tags'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'equipment__enterprise_id',
			// 'equipment__fleet_id',
			// 'equipment__eq_status_id',
			// 'equipment__inactive_date',
			// 'equipment__manufacturer_id',
			// 'equipment__business_partner_id',
			// 'equipment__eq_type_id',
			// 'equipment__tel_manufacturer_id',
			// 'equipment__is_misc',
			// 'equipment__initial_maint_expense',
			// 'equipment__initial_book_value',
			// 'equipment__purchase_price',
			// 'equipment__warranty_expires',
			// 'equipment__warranty_hours',
			// 'equipment__is_pm_enable',
			// 'equipment__pm_schedules',
			// 'equipment__expected_hours_per_month',
			// 'equipment__available_hours_per_week',
			// 'equipment__in_service_date',
			// 'equipment__in_service_meter',
			// 'equipment__is_track_hours_enable',
			// 'equipment__max_hours_allowed',
			// 'equipment__expected_revenue_per_month',
			// 'equipment__capacity',
			// 'equipment__eq_upright_type_id',
			// 'equipment__eq_valve_type_id',
			// 'equipment__eq_power_type_id',
			// 'equipment__eq_tire_type_id',
			// 'equipment__eq_voltage_id',
			// 'equipment__eq_fork_length_id',
			// 'equipment__is_fluorescent_stripe',
			// 'equipment__is_whelen_light',
			// 'equipment__is_blue_led_light',
			// 'equipment__is_high_vis_seatbelt',
			// 'equipment__is_rear_handle_horn',
			// 'equipment__is_contact_decals',
			// 'equipment__is_pallet_protection',
			// 'equipment__is_laser_light'
		],

	},

	entity: {
		methods: {

			getEqMonthlyStats: function() {
				const EqMonthlyStats = this.getAssociatedRepository('EqMonthlyStats');
				return EqMonthlyStats.getBy((entity) => {
					return entity.eq_monthly_stats__equipment_id === this.equipment__id;
				});
			},

			getPmEvents: function() {
				const PmEvents = this.getAssociatedRepository('PmEvents');
				return PmEvents.getBy((entity) => {
					return entity.pm_events__equipment_id === this.equipment__id;
				});
			},

			getTelUsages: function() {
				const TelUsages = this.getAssociatedRepository('TelUsages');
				return TelUsages.getBy((entity) => {
					return entity.tel_usages__equipment_id === this.equipment__id;
				});
			},

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__equipment_id === this.equipment__id;
				});
			},

			getEnterprise: async function() {
				const Enterprises = this.getAssociatedRepository('Enterprises');
				let entity = Enterprises.getById(this.equipment__enterprise_id);
				if (!entity) {
					entity = await Enterprises.getSingleEntityFromServer(this.equipment__enterprise_id);
				}
				return entity;
			},

			getFleet: async function() {
				const Fleets = this.getAssociatedRepository('Fleets');
				let entity = Fleets.getById(this.equipment__fleet_id);
				if (!entity) {
					entity = await Fleets.getSingleEntityFromServer(this.equipment__fleet_id);
				}
				return entity;
			},

			getBusinessPartner: async function() {
				const BusinessPartners = this.getAssociatedRepository('BusinessPartners');
				let entity = BusinessPartners.getById(this.equipment__business_partner_id);
				if (!entity) {
					entity = await BusinessPartners.getSingleEntityFromServer(this.equipment__business_partner_id);
				}
				return entity;
			},

			getEqStatus: async function() {
				const EqStatuses = this.getAssociatedRepository('EqStatuses');
				let entity = EqStatuses.getById(this.equipment__eq_status_id);
				if (!entity) {
					entity = await EqStatuses.getSingleEntityFromServer(this.equipment__eq_status_id);
				}
				return entity;
			},

			getEqForkLength: async function() {
				const EqForkLengths = this.getAssociatedRepository('EqForkLengths');
				let entity = EqForkLengths.getById(this.equipment__eq_fork_length_id);
				if (!entity) {
					entity = await EqForkLengths.getSingleEntityFromServer(this.equipment__eq_fork_length_id);
				}
				return entity;
			},

			getEqPowerType: async function() {
				const EqPowerTypes = this.getAssociatedRepository('EqPowerTypes');
				let entity = EqPowerTypes.getById(this.equipment__eq_power_type_id);
				if (!entity) {
					entity = await EqPowerTypes.getSingleEntityFromServer(this.equipment__eq_power_type_id);
				}
				return entity;
			},

			getEqTireType: async function() {
				const EqTireTypes = this.getAssociatedRepository('EqTireTypes');
				let entity = EqTireTypes.getById(this.equipment__eq_tire_type_id);
				if (!entity) {
					entity = await EqTireTypes.getSingleEntityFromServer(this.equipment__eq_tire_type_id);
				}
				return entity;
			},

			getEqType: async function() {
				const EqTypes = this.getAssociatedRepository('EqTypes');
				let entity = EqTypes.getById(this.equipment__eq_type_id);
				if (!entity) {
					entity = await EqTypes.getSingleEntityFromServer(this.equipment__eq_type_id);
				}
				return entity;
			},

			getEqUprightType: async function() {
				const EqUprightTypes = this.getAssociatedRepository('EqUprightTypes');
				let entity = EqUprightTypes.getById(this.equipment__eq_upright_type_id);
				if (!entity) {
					entity = await EqUprightTypes.getSingleEntityFromServer(this.equipment__eq_upright_type_id);
				}
				return entity;
			},

			getEqValveType: async function() {
				const EqValveTypes = this.getAssociatedRepository('EqValveTypes');
				let entity = EqValveTypes.getById(this.equipment__eq_valve_type_id);
				if (!entity) {
					entity = await EqValveTypes.getSingleEntityFromServer(this.equipment__eq_valve_type_id);
				}
				return entity;
			},

			getEqVoltage: async function() {
				const EqVoltages = this.getAssociatedRepository('EqVoltages');
				let entity = EqVoltages.getById(this.equipment__eq_voltage_id);
				if (!entity) {
					entity = await EqVoltages.getSingleEntityFromServer(this.equipment__eq_voltage_id);
				}
				return entity;
			},

			getEquipmentPmSchedules: function() {
				const EquipmentPmSchedules = this.getAssociatedRepository('EquipmentPmSchedules');
				return EquipmentPmSchedules.getBy((entity) => {
					return entity.equipment_pm_schedules__equipment_id === this.equipment__id;
				});
			},

			getMeterReadings: function() {
				const MeterReadings = this.getAssociatedRepository('MeterReadings');
				return MeterReadings.getBy((entity) => {
					return entity.meter_readings__equipment_id === this.equipment__id;
				});
			},

			getManufacturer: async function() {
				const Manufacturers = this.getAssociatedRepository('Manufacturers');
				let entity = Manufacturers.getById(this.equipment__manufacturer_id);
				if (!entity) {
					entity = await Manufacturers.getSingleEntityFromServer(this.equipment__manufacturer_id);
				}
				return entity;
			},

			getAttachments: function() {
				const Attachments = this.getAssociatedRepository('Attachments');
				return Attachments.getBy((entity) => {
					return entity.attachments__modelid === this.equipment__id;
				});
			},

			getTelManufacturer: async function() {
				const TelManufacturers = this.getAssociatedRepository('TelManufacturers');
				let entity = TelManufacturers.getById(this.equipment__tel_manufacturer_id);
				if (!entity) {
					entity = await TelManufacturers.getSingleEntityFromServer(this.equipment__tel_manufacturer_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Equipment;
