import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import EnterprisesFilteredInlineGridEditor from '../components/Grid/EnterprisesFilteredInlineGridEditor.js';
import EnterprisesFilteredGridEditor from '../components/Grid/EnterprisesFilteredGridEditor.js';
import EnterprisesFilteredSideGridEditor from '../components/Grid/EnterprisesFilteredSideGridEditor.js';

export default function EnterprisesManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'EnterprisesManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Enterprises"
				reference="EnterprisesManager"
				// fullModeComponent={<EnterprisesFilteredInlineGridEditor
				// 						reference="EnterprisesFilteredInlineGridEditor"
				// 						useRemoteDuplicate={true}
				// 					/>}
				fullModeComponent={<EnterprisesFilteredGridEditor
										reference="EnterprisesFilteredGridEditor"
										useRemoteDuplicate={true}
									/>}
				sideModeComponent={<EnterprisesFilteredSideGridEditor
										reference="EnterprisesFilteredGridEditor"
										useRemoteDuplicate={true}
									/>}
			/>;
}
