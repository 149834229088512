/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import EqPowerTypesEditorWindow from '../../../Window/EqPowerTypesEditorWindow.js';

function EqPowerTypesComboEditor(props) {
	return <ComboEditor
				reference="EqPowerTypesComboEditor"
				model="EqPowerTypes"
				uniqueRepository={true}
				Editor={EqPowerTypesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EqPowerTypesComboEditor;