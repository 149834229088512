/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoOtherChargesSideGridEditor from './WoOtherChargesSideGridEditor.js';

export default function WoOtherChargesFilteredSideGridEditor(props) {
	return <WoOtherChargesSideGridEditor
				reference="WoOtherChargesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}