/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function TelUsagesEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "tel_usages__equipment_id"
		                    },
		                    {
		                        "name": "tel_usages__tel_operator_id"
		                    },
		                    {
		                        "name": "tel_usages__start_date"
		                    },
		                    {
		                        "name": "tel_usages__end_date"
		                    },
		                    {
		                        "name": "tel_usages__midpoint_date"
		                    },
		                    {
		                        "name": "tel_usages__sess_login_time"
		                    },
		                    {
		                        "name": "tel_usages__session_identifier"
		                    },
		                    {
		                        "name": "tel_usages__max_height"
		                    },
		                    {
		                        "name": "tel_usages__max_weight"
		                    },
		                    {
		                        "name": "tel_usages__max_speed"
		                    },
		                    {
		                        "name": "tel_usages__sess_start_fuel_level"
		                    },
		                    {
		                        "name": "tel_usages__sess_end_fuel_level"
		                    },
		                    {
		                        "name": "tel_usages__sess_avg_load"
		                    },
		                    {
		                        "name": "tel_usages__sess_peak_load"
		                    },
		                    {
		                        "name": "tel_usages__sess_total_load"
		                    },
		                    {
		                        "name": "tel_usages__sess_weight_low"
		                    },
		                    {
		                        "name": "tel_usages__sess_weight_med"
		                    },
		                    {
		                        "name": "tel_usages__sess_weight_high"
		                    },
		                    {
		                        "name": "tel_usages__sess_weight_low_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_weight_med_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_weight_high_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_lift_count"
		                    },
		                    {
		                        "name": "tel_usages__sess_height_low"
		                    },
		                    {
		                        "name": "tel_usages__sess_height_med"
		                    },
		                    {
		                        "name": "tel_usages__sess_height_high"
		                    },
		                    {
		                        "name": "tel_usages__sess_height_low_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_height_med_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_height_high_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_avg_speed"
		                    },
		                    {
		                        "name": "tel_usages__sess_peak_speed"
		                    },
		                    {
		                        "name": "tel_usages__sess_speed_low"
		                    },
		                    {
		                        "name": "tel_usages__sess_speed_med"
		                    },
		                    {
		                        "name": "tel_usages__sess_speed_high"
		                    },
		                    {
		                        "name": "tel_usages__sess_speed_low_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_speed_med_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_speed_high_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_speed_over_level1"
		                    },
		                    {
		                        "name": "tel_usages__sess_speed_over_level2"
		                    },
		                    {
		                        "name": "tel_usages__sess_danger"
		                    },
		                    {
		                        "name": "tel_usages__sess_danger_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_key_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_operator_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_traction_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_on_seat_idle_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_off_seat_idle_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_laden_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_laden_traction_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_lift_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_hydraulic_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_forward_gear_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_reverse_gear_time"
		                    },
		                    {
		                        "name": "tel_usages__sess_distance"
		                    },
		                    {
		                        "name": "tel_usages__sess_distance_laden"
		                    },
		                    {
		                        "name": "tel_usages__ltd_key_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_operator_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_traction_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_on_seat_idle_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_off_seat_idle_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_laden_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_laden_moving_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_lift_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_hydraulic_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_forward_gear_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_reverse_gear_time"
		                    },
		                    {
		                        "name": "tel_usages__ltd_distance"
		                    },
		                    {
		                        "name": "tel_usages__ltd_distance_laden"
		                    },
		                    {
		                        "name": "tel_usages__shift"
		                    },
		                    {
		                        "name": "tel_usages__err_msg"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="TelUsagesEditor"
				title="TelUsages"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

