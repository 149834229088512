/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import WorkOrdersTagsGridColumns from '../../Grid/Columns/WorkOrdersTagsGridColumns.js';

export default function WorkOrdersTagsGridPanel(props) {
	return <GridPanel
				reference="WorkOrdersTagsGridPanel"
				model="WorkOrdersTags"
				usePermissions={true}
				columnsConfig={WorkOrdersTagsGridColumns}

				{...props}
			/>;
}