/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import BusinessPartnersGridColumns from '../../Grid/Columns/BusinessPartnersGridColumns.js';

export default function BusinessPartnersGridPanel(props) {
	return <GridPanel
				reference="BusinessPartnersGridPanel"
				model="BusinessPartners"
				usePermissions={true}
				columnsConfig={BusinessPartnersGridColumns}

				{...props}
			/>;
}