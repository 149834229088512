/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import WoOtherChargesEditorWindow from '../../../Window/WoOtherChargesEditorWindow.js';

function WoOtherChargesTagEditor(props) {
	return <TagEditor
				reference="WoOtherChargesTagEditor"
				model="WoOtherCharges"
				uniqueRepository={true}
				Editor={WoOtherChargesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default WoOtherChargesTagEditor;