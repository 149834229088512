/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import BusinessPartnersFleetsGridColumnsSideB from './Columns/BusinessPartnersFleetsGridColumnsSideB.js';

export default function BusinessPartnersFleetsGridSideB(props) {
	return <Grid
				reference="BusinessPartnersFleetsGridSideB"
				model="BusinessPartnersFleets"
				usePermissions={true}
				columnsConfig={BusinessPartnersFleetsGridColumnsSideB}
				canColumnsReorder={false}
				canColumnsResize={false}

				{...props}
			/>;
}