import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Tags = {
	
	name: 'Tags',
	
	model: {

		idProperty: 'tags__id',
		displayProperty: 'tags__name',
		sortProperty: 'tags__name',
		
		sorters: null,

		validator: yup.object({
			tags__name: yup.string().required(),
			tags__tag_type_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'tags__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'tags__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'tags__tag_type_id', mapping: 'tag_type_id', title: 'Tag Type', type: 'int', isFk: true, fkIdField: 'tag_types__id', fkDisplayField: 'tag_types__name', filterType: {"type":"TagTypesCombo","loadAfterRender":!1}, editorType: {"type":"TagTypesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'tag_types__id', mapping: 'tag_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tag_types__name', mapping: 'tag_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'TagTypes'
			],
			belongsToMany: [
				'Equipment',
				'WorkOrders'
			],

		},

		ancillaryFilters: {
		    "equipment": "Equipment",
		    "work_orders": "WorkOrder"
		},

		defaultFilters: [
			// 'tags__tag_type_id'
		],

	},

	entity: {
		methods: {

			getTagType: async function() {
				const TagTypes = this.getAssociatedRepository('TagTypes');
				let entity = TagTypes.getById(this.tags__tag_type_id);
				if (!entity) {
					entity = await TagTypes.getSingleEntityFromServer(this.tags__tag_type_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Tags;
