/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilCrudLogsSideGridEditor from './UtilCrudLogsSideGridEditor.js';

export default function UtilCrudLogsFilteredSideGridEditor(props) {
	return <UtilCrudLogsSideGridEditor
				reference="UtilCrudLogsFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}