/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import PartsPartsEditorWindow from '../../Window/PartsPartsEditorWindow.js';
import PartsPartsGridColumns from '../../Grid/Columns/PartsPartsGridColumns.js';

export default function PartsPartsGridEditorPanel(props) {
	return <GridPanel
				reference="PartsPartsGridEditorPanel"
				model="PartsParts"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={PartsPartsEditorWindow}
				columnsConfig={PartsPartsGridColumns}
				
				
				{...props}
			/>;
}