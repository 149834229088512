export default [
	    {
	        "id": "equipment_tags__tag_id",
	        "header": "Tag",
	        "fieldName": "tags__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "equipment_tags__tag_id",
	        "flex": 1,
	        "minWidth": 150
	    }
	];