/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import UtilProcessLogsGridColumns from '../../Grid/Columns/UtilProcessLogsGridColumns.js';

export default function UtilProcessLogsGridPanel(props) {
	return <GridPanel
				reference="UtilProcessLogsGridPanel"
				model="UtilProcessLogs"
				usePermissions={true}
				columnsConfig={UtilProcessLogsGridColumns}

				{...props}
			/>;
}