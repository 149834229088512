/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EnterpriseTypesEditor from '../Editor/EnterpriseTypesEditor.js';
import EnterpriseTypesGridColumns from './Columns/EnterpriseTypesGridColumns.js';

export default function EnterpriseTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="EnterpriseTypesSideGridEditor"
				model="EnterpriseTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={EnterpriseTypesEditor}
				columnsConfig={EnterpriseTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}