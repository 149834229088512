export default [
	    {
	        "id": "tags__name",
	        "header": "Name",
	        "fieldName": "tags__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "tags__tag_type_id",
	        "header": "Tag Type",
	        "fieldName": "tag_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "tags__tag_type_id"
	    }
	];