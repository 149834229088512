export default [
	    {
	        "id": "work_orders__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders__equipment_id"
	    },
	    {
	        "id": "work_orders__wo_number",
	        "header": "Work Order Number",
	        "fieldName": "work_orders__wo_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__segment",
	        "header": "Segment",
	        "fieldName": "work_orders__segment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__reference",
	        "header": "Reference",
	        "fieldName": "work_orders__reference",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__wo_status_id",
	        "header": "Status",
	        "fieldName": "wo_statuses__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders__wo_status_id"
	    },
	    {
	        "id": "work_orders__total_parts",
	        "header": "Total Parts",
	        "fieldName": "work_orders__total_parts",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__total_labor",
	        "header": "Total Labor",
	        "fieldName": "work_orders__total_labor",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__total_other",
	        "header": "Total Other",
	        "fieldName": "work_orders__total_other",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__total",
	        "header": "Total",
	        "fieldName": "work_orders__total",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__open_date",
	        "header": "Open Date",
	        "fieldName": "work_orders__open_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "work_orders__close_date",
	        "header": "Close Date",
	        "fieldName": "work_orders__close_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "work_orders__invoiced_date",
	        "header": "Invoiced Date",
	        "fieldName": "work_orders__invoiced_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__completed_date",
	        "header": "Completed Date",
	        "fieldName": "work_orders__completed_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__time_out_of_service",
	        "header": "Time Out Of Service",
	        "fieldName": "work_orders__time_out_of_service",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__wo_mode_id",
	        "header": "Mode",
	        "fieldName": "wo_modes__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders__wo_mode_id"
	    },
	    {
	        "id": "work_orders__wo_priority_id",
	        "header": "Priority",
	        "fieldName": "wo_priorities__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders__wo_priority_id"
	    },
	    {
	        "id": "work_orders__wo_class_id",
	        "header": "Class",
	        "fieldName": "wo_classes__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders__wo_class_id"
	    },
	    {
	        "id": "work_orders__invoice_number",
	        "header": "Invoice Number",
	        "fieldName": "work_orders__invoice_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__po_number",
	        "header": "PO Number",
	        "fieldName": "work_orders__po_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__pm_schedule_id",
	        "header": "PM Schedule",
	        "fieldName": "pm_schedules__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders__pm_schedule_id"
	    },
	    {
	        "id": "work_orders__tags",
	        "header": "Tags",
	        "fieldName": "work_orders__tags",
	        "isSortable": false,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "work_orders__meter_reading",
	        "header": "Meter Reading",
	        "fieldName": "work_orders__meter_reading",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__comments",
	        "header": "Comments",
	        "fieldName": "work_orders__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "work_orders__wo_location_id",
	        "header": "Location",
	        "fieldName": "wo_locations__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders__wo_location_id"
	    },
	    {
	        "id": "work_orders__repaired_by_company",
	        "header": "Repaired By Company",
	        "fieldName": "work_orders__repaired_by_company",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__repaired_by_branch",
	        "header": "Repaired By Branch",
	        "fieldName": "work_orders__repaired_by_branch",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__is_ltm",
	        "header": "Ltm?",
	        "fieldName": "work_orders__is_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__is_scheduled",
	        "header": "Scheduled?",
	        "fieldName": "work_orders__is_scheduled",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__is_verified",
	        "header": "Verified?",
	        "fieldName": "work_orders__is_verified",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "work_orders__business_partner_id",
	        "header": "Business Partner",
	        "fieldName": "business_partners__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders__business_partner_id"
	    },
	    {
	        "id": "work_orders__bill_to_name",
	        "header": "Bill To Name",
	        "fieldName": "work_orders__bill_to_name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__bill_to_street1",
	        "header": "Bill To Street 1",
	        "fieldName": "work_orders__bill_to_street1",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__bill_to_street2",
	        "header": "Bill To Street 2",
	        "fieldName": "work_orders__bill_to_street2",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__bill_to_city",
	        "header": "Bill To City",
	        "fieldName": "work_orders__bill_to_city",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__bill_to_state_abbr",
	        "header": "Bill To State",
	        "fieldName": "work_orders__bill_to_state_abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__bill_to_zip",
	        "header": "Bill To Zip",
	        "fieldName": "work_orders__bill_to_zip",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__ship_to_name",
	        "header": "Ship To Name",
	        "fieldName": "work_orders__ship_to_name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__ship_to_street1",
	        "header": "Ship To Street 1",
	        "fieldName": "work_orders__ship_to_street1",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__ship_to_street2",
	        "header": "Ship To Street 2",
	        "fieldName": "work_orders__ship_to_street2",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__ship_to_city",
	        "header": "Ship To City",
	        "fieldName": "work_orders__ship_to_city",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__ship_to_state_abbr",
	        "header": "Ship To State",
	        "fieldName": "work_orders__ship_to_state_abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "work_orders__ship_to_zip",
	        "header": "Ship To Zip",
	        "fieldName": "work_orders__ship_to_zip",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];