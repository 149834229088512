export default [
	    {
	        "id": "work_orders_tags__work_order_id",
	        "header": "Work Order",
	        "fieldName": "work_orders__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "work_orders_tags__work_order_id",
	        "flex": 1,
	        "minWidth": 150
	    }
	];