export default [
	    {
	        "id": "parts_part_types__part_type_id",
	        "header": "Part Type",
	        "fieldName": "part_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "parts_part_types__part_type_id",
	        "flex": 1,
	        "minWidth": 150
	    }
	];