export default [
	    {
	        "id": "eq_types__name",
	        "header": "Name",
	        "fieldName": "eq_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_types__is_forklift",
	        "header": "Forklift?",
	        "fieldName": "eq_types__is_forklift",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_types__is_attachment",
	        "header": "Attachment?",
	        "fieldName": "eq_types__is_attachment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_types__is_tugger",
	        "header": "Tugger?",
	        "fieldName": "eq_types__is_tugger",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_types__sort_order",
	        "header": "Sort Order",
	        "fieldName": "eq_types__sort_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];