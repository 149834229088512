/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import FleetStatsEditor from '../../Editor/FleetStatsEditor.js';
import FleetStatsGridColumns from '../../Grid/Columns/FleetStatsGridColumns.js';

export default function FleetStatsSideGridEditorPanel(props) {
	return <GridPanel
				reference="FleetStatsSideGridEditorPanel"
				model="FleetStats"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={FleetStatsEditor}
				columnsConfig={FleetStatsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}