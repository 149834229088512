import * as yup from 'yup'; // https://github.com/jquense/yup#string

const BusinessPartnersFleets = {
	
	name: 'BusinessPartnersFleets',
	
	model: {

		idProperty: 'business_partners_fleets__id',
		displayProperty: 'business_partners_fleets__id',
		sortProperty: 'business_partners_fleets__id',
		
		sorters: null,

		validator: yup.object({
			business_partners_fleets__business_partner_id: yup.number().integer().required(),
			business_partners_fleets__fleet_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'business_partners_fleets__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'business_partners_fleets__business_partner_id', mapping: 'business_partner_id', title: 'Business Partner', type: 'int', isFk: true, fkIdField: 'business_partners__id', fkDisplayField: 'business_partners__name', filterType: {"type":"BusinessPartnersCombo","loadAfterRender":!1}, editorType: {"type":"BusinessPartnersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'business_partners_fleets__fleet_id', mapping: 'fleet_id', title: 'Fleet', type: 'int', isFk: true, fkIdField: 'fleets__id', fkDisplayField: 'fleets__name', filterType: {"type":"FleetsCombo","loadAfterRender":!1}, editorType: {"type":"FleetsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'business_partners__id', mapping: 'business_partner.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'business_partners__name', mapping: 'business_partner.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__id', mapping: 'fleet.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__enterprise_id', mapping: 'fleet.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__name', mapping: 'fleet.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__business_partners', mapping: 'fleet.business_partners', title: 'Business Partners', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_1', mapping: 'fleet.shift_start_1', title: 'Shift Start 1', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_2', mapping: 'fleet.shift_start_2', title: 'Shift Start 2', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_3', mapping: 'fleet.shift_start_3', title: 'Shift Start 3', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'BusinessPartners',
				'Fleets'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'business_partners_fleets__business_partner_id',
			// 'business_partners_fleets__fleet_id'
		],

	},

	entity: {
		methods: {

			getBusinessPartner: async function() {
				const BusinessPartners = this.getAssociatedRepository('BusinessPartners');
				let entity = BusinessPartners.getById(this.business_partners_fleets__business_partner_id);
				if (!entity) {
					entity = await BusinessPartners.getSingleEntityFromServer(this.business_partners_fleets__business_partner_id);
				}
				return entity;
			},

			getFleet: async function() {
				const Fleets = this.getAssociatedRepository('Fleets');
				let entity = Fleets.getById(this.business_partners_fleets__fleet_id);
				if (!entity) {
					entity = await Fleets.getSingleEntityFromServer(this.business_partners_fleets__fleet_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default BusinessPartnersFleets;
