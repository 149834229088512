import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Fleets = {
	
	name: 'Fleets',
	usesEnterpriseId: true,
	model: {

		idProperty: 'fleets__id',
		displayProperty: 'fleets__name',
		sortProperty: 'fleets__name',
		isTree: true,
		parentIdProperty: 'parentId',
		depthProperty: 'depth',
		hasChildrenProperty: 'hasChildren',

		
		sorters: null,

		validator: yup.object({
			fleets__name: yup.string().required(),
			fleets__business_partners: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'parentId', mapping: 'parentId', type: 'int', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'hasChildren', mapping: 'hasChildren', type: 'bool', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'depth', mapping: 'depth', type: 'int', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'fleets__enterprise_id', mapping: 'enterprise_id', title: 'Enterprise', type: 'int', isFk: true, fkIdField: 'enterprises__id', fkDisplayField: 'enterprises__name', filterType: {"type":"EnterprisesCombo","loadAfterRender":!1}, editorType: {"type":"EnterprisesComboEditor","loadAfterRender":!1}, fieldGroup: '1. Details', },
			{ name: 'fleets__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'fleets__business_partners', mapping: 'business_partners', title: 'Business Partners', filterType: {"type":"BusinessPartnersTag"}, viewerType: {"type":"BusinessPartnersTag"}, editorType: {"type":"BusinessPartnersTagEditor","loadAfterRender":!1}, fieldGroup: '1. Details', },
			{ name: 'fleets__shift_start_1', mapping: 'shift_start_1', title: 'Shift Start 1', type: 'time', filterType: {"type":"DateRange","mode":"TIME"}, editorType: {"type":"Date","mode":"TIME"}, fieldGroup: '2. Shifts', },
			{ name: 'fleets__shift_start_2', mapping: 'shift_start_2', title: 'Shift Start 2', type: 'time', filterType: {"type":"DateRange","mode":"TIME"}, editorType: {"type":"Date","mode":"TIME"}, fieldGroup: '2. Shifts', },
			{ name: 'fleets__shift_start_3', mapping: 'shift_start_3', title: 'Shift Start 3', type: 'time', filterType: {"type":"DateRange","mode":"TIME"}, editorType: {"type":"Date","mode":"TIME"}, fieldGroup: '2. Shifts', },
			// { name: 'fleet_stats__id', mapping: 'fleet_stat.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__fleet_id', mapping: 'fleet_stat.fleet_id', title: 'Fleet', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__is_stale', mapping: 'fleet_stat.is_stale', title: 'Stale?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__path', mapping: 'fleet_stat.path', title: 'Path', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__total_active_equipment', mapping: 'fleet_stat.total_active_equipment', title: 'Total Active Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__total_inactive_equipment', mapping: 'fleet_stat.total_inactive_equipment', title: 'Total Inactive Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__total_pm_equipment', mapping: 'fleet_stat.total_pm_equipment', title: 'Total Pm Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__total_pm_will_call_equipment', mapping: 'fleet_stat.total_pm_will_call_equipment', title: 'Total Pm Will Call Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__total_eq_onRent', mapping: 'fleet_stat.total_eq_onRent', title: 'Total Eq OnRent', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__total_eq_coming_off_rent', mapping: 'fleet_stat.total_eq_coming_off_rent', title: 'Total Eq Coming Off Rent', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__total_eq_overdue_rent', mapping: 'fleet_stat.total_eq_overdue_rent', title: 'Total Eq Overdue Rent', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtbf', mapping: 'fleet_stat.mtbf', title: 'Mtbf', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtbf_count', mapping: 'fleet_stat.mtbf_count', title: 'Mtbf Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__latest_repair_date', mapping: 'fleet_stat.latest_repair_date', title: 'Latest Repair Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__latest_invoice_date_rental', mapping: 'fleet_stat.latest_invoice_date_rental', title: 'Latest Invoice Date Rental', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__due_pms', mapping: 'fleet_stat.due_pms', title: 'Due Pms', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__overdue_pms', mapping: 'fleet_stat.overdue_pms', title: 'Overdue Pms', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__overdue_pm_equipment', mapping: 'fleet_stat.overdue_pm_equipment', title: 'Overdue Pm Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__pm_equipment_hy', mapping: 'fleet_stat.pm_equipment_hy', title: 'PM Equipment Hy', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__pm_equipment_ya', mapping: 'fleet_stat.pm_equipment_ya', title: 'PM Equipment Ya', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__pm_equipment_other', mapping: 'fleet_stat.pm_equipment_other', title: 'PM Equipment Other', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__latest_pm_date', mapping: 'fleet_stat.latest_pm_date', title: 'Latest Pm Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__leases_active', mapping: 'fleet_stat.leases_active', title: 'Leases Active', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__leases_extended', mapping: 'fleet_stat.leases_extended', title: 'Leases Extended', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__leases_percent_extended', mapping: 'fleet_stat.leases_percent_extended', title: 'Leases Percent Extended', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__lease_total_monthly_payment', mapping: 'fleet_stat.lease_total_monthly_payment', title: 'Lease Total Monthly Payment', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__lease_avg_months_to_end', mapping: 'fleet_stat.lease_avg_months_to_end', title: 'Lease Avg Months To End', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__lease_avg_term', mapping: 'fleet_stat.lease_avg_term', title: 'Lease Avg Term', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_hours', mapping: 'fleet_stat.mtd_hours', title: 'Mtd Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_available_hours', mapping: 'fleet_stat.mtd_available_hours', title: 'Mtd Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_util', mapping: 'fleet_stat.mtd_util', title: 'Mtd Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint', mapping: 'fleet_stat.mtd_maint', title: 'Mtd Maint', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_preventive', mapping: 'fleet_stat.mtd_maint_preventive', title: 'Mtd Maint Preventive', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_abuse', mapping: 'fleet_stat.mtd_maint_abuse', title: 'Mtd Maint Abuse', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_abuse_rental', mapping: 'fleet_stat.mtd_maint_abuse_rental', title: 'Mtd Maint Abuse Rental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_tires', mapping: 'fleet_stat.mtd_maint_tires', title: 'Mtd Maint Tires', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_repair', mapping: 'fleet_stat.mtd_maint_repair', title: 'Mtd Maint Repair', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_ltm', mapping: 'fleet_stat.mtd_maint_ltm', title: 'Mtd Maint Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_preventive_ltm', mapping: 'fleet_stat.mtd_maint_preventive_ltm', title: 'Mtd Maint Preventive Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_abuse_ltm', mapping: 'fleet_stat.mtd_maint_abuse_ltm', title: 'Mtd Maint Abuse Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_tires_ltm', mapping: 'fleet_stat.mtd_maint_tires_ltm', title: 'Mtd Maint Tires Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_repair_ltm', mapping: 'fleet_stat.mtd_maint_repair_ltm', title: 'Mtd Maint Repair Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_maint_combined', mapping: 'fleet_stat.mtd_maint_combined', title: 'Mtd Maint Combined', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_ltm_billing', mapping: 'fleet_stat.mtd_ltm_billing', title: 'Mtd Ltm Billing', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_lease', mapping: 'fleet_stat.mtd_lease', title: 'Mtd Lease', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_depreciation', mapping: 'fleet_stat.mtd_depreciation', title: 'Mtd Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_cost_per_hr', mapping: 'fleet_stat.mtd_cost_per_hr', title: 'Mtd Cost Per Hr', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_cost_per_hr_customer', mapping: 'fleet_stat.mtd_cost_per_hr_customer', title: 'Mtd Cost Per Hr Customer', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_cost_per_hr_ltm', mapping: 'fleet_stat.mtd_cost_per_hr_ltm', title: 'Mtd Cost Per Hr Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__mtd_rental', mapping: 'fleet_stat.mtd_rental', title: 'Mtd Rental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__qtd_hours', mapping: 'fleet_stat.qtd_hours', title: 'Qtd Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__qtd_available_hours', mapping: 'fleet_stat.qtd_available_hours', title: 'Qtd Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__qtd_util', mapping: 'fleet_stat.qtd_util', title: 'Qtd Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_hours', mapping: 'fleet_stat.ytd_hours', title: 'Ytd Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_available_hours', mapping: 'fleet_stat.ytd_available_hours', title: 'Ytd Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_util', mapping: 'fleet_stat.ytd_util', title: 'Ytd Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint', mapping: 'fleet_stat.ytd_maint', title: 'Ytd Maint', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_preventive', mapping: 'fleet_stat.ytd_maint_preventive', title: 'Ytd Maint Preventive', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_abuse', mapping: 'fleet_stat.ytd_maint_abuse', title: 'Ytd Maint Abuse', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_abuse_rental', mapping: 'fleet_stat.ytd_maint_abuse_rental', title: 'Ytd Maint Abuse Rental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_tires', mapping: 'fleet_stat.ytd_maint_tires', title: 'Ytd Maint Tires', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_repair', mapping: 'fleet_stat.ytd_maint_repair', title: 'Ytd Maint Repair', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_ltm', mapping: 'fleet_stat.ytd_maint_ltm', title: 'Ytd Maint Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_preventive_ltm', mapping: 'fleet_stat.ytd_maint_preventive_ltm', title: 'Ytd Maint Preventive Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_abuse_ltm', mapping: 'fleet_stat.ytd_maint_abuse_ltm', title: 'Ytd Maint Abuse Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_tires_ltm', mapping: 'fleet_stat.ytd_maint_tires_ltm', title: 'Ytd Maint Tires Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_repair_ltm', mapping: 'fleet_stat.ytd_maint_repair_ltm', title: 'Ytd Maint Repair Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_maint_combined', mapping: 'fleet_stat.ytd_maint_combined', title: 'Ytd Maint Combined', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_ltm_billing', mapping: 'fleet_stat.ytd_ltm_billing', title: 'Ytd Ltm Billing', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_lease', mapping: 'fleet_stat.ytd_lease', title: 'Ytd Lease', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_depreciation', mapping: 'fleet_stat.ytd_depreciation', title: 'Ytd Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_cost_per_hr', mapping: 'fleet_stat.ytd_cost_per_hr', title: 'Ytd Cost Per Hr', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_cost_per_hr_customer', mapping: 'fleet_stat.ytd_cost_per_hr_customer', title: 'Ytd Cost Per Hr Customer', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_cost_per_hr_ltm', mapping: 'fleet_stat.ytd_cost_per_hr_ltm', title: 'Ytd Cost Per Hr Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ytd_rental', mapping: 'fleet_stat.ytd_rental', title: 'Ytd Rental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_hours', mapping: 'fleet_stat.rtm_hours', title: 'Rtm Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_available_hours', mapping: 'fleet_stat.rtm_available_hours', title: 'Rtm Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_util', mapping: 'fleet_stat.rtm_util', title: 'Rtm Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint', mapping: 'fleet_stat.rtm_maint', title: 'Rtm Maint', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_preventive', mapping: 'fleet_stat.rtm_maint_preventive', title: 'Rtm Maint Preventive', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_abuse', mapping: 'fleet_stat.rtm_maint_abuse', title: 'Rtm Maint Abuse', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_abuse_rental', mapping: 'fleet_stat.rtm_maint_abuse_rental', title: 'Rtm Maint Abuse Rental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_tires', mapping: 'fleet_stat.rtm_maint_tires', title: 'Rtm Maint Tires', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_repair', mapping: 'fleet_stat.rtm_maint_repair', title: 'Rtm Maint Repair', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_ltm', mapping: 'fleet_stat.rtm_maint_ltm', title: 'Rtm Maint Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_preventive_ltm', mapping: 'fleet_stat.rtm_maint_preventive_ltm', title: 'Rtm Maint Preventive Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_abuse_ltm', mapping: 'fleet_stat.rtm_maint_abuse_ltm', title: 'Rtm Maint Abuse Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_tires_ltm', mapping: 'fleet_stat.rtm_maint_tires_ltm', title: 'Rtm Maint Tires Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_repair_ltm', mapping: 'fleet_stat.rtm_maint_repair_ltm', title: 'Rtm Maint Repair Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_maint_combined', mapping: 'fleet_stat.rtm_maint_combined', title: 'Rtm Maint Combined', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_ltm_billing', mapping: 'fleet_stat.rtm_ltm_billing', title: 'Rtm Ltm Billing', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_lease', mapping: 'fleet_stat.rtm_lease', title: 'Rtm Lease', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_depreciation', mapping: 'fleet_stat.rtm_depreciation', title: 'Rtm Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_cost_per_hr', mapping: 'fleet_stat.rtm_cost_per_hr', title: 'Rtm Cost Per Hr', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_cost_per_hr_customer', mapping: 'fleet_stat.rtm_cost_per_hr_customer', title: 'Rtm Cost Per Hr Customer', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_cost_per_hr_ltm', mapping: 'fleet_stat.rtm_cost_per_hr_ltm', title: 'Rtm Cost Per Hr Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__rtm_rental', mapping: 'fleet_stat.rtm_rental', title: 'Rtm Rental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_hours', mapping: 'fleet_stat.ltd_hours', title: 'Ltd Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_available_hours', mapping: 'fleet_stat.ltd_available_hours', title: 'Ltd Available Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_util', mapping: 'fleet_stat.ltd_util', title: 'Ltd Util', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint', mapping: 'fleet_stat.ltd_maint', title: 'Ltd Maint', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_preventive', mapping: 'fleet_stat.ltd_maint_preventive', title: 'Ltd Maint Preventive', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_abuse', mapping: 'fleet_stat.ltd_maint_abuse', title: 'Ltd Maint Abuse', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_abuse_rental', mapping: 'fleet_stat.ltd_maint_abuse_rental', title: 'Ltd Maint Abuse Rental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_tires', mapping: 'fleet_stat.ltd_maint_tires', title: 'Ltd Maint Tires', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_repair', mapping: 'fleet_stat.ltd_maint_repair', title: 'Ltd Maint Repair', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_ltm', mapping: 'fleet_stat.ltd_maint_ltm', title: 'Ltd Maint Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_preventive_ltm', mapping: 'fleet_stat.ltd_maint_preventive_ltm', title: 'Ltd Maint Preventive Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_abuse_ltm', mapping: 'fleet_stat.ltd_maint_abuse_ltm', title: 'Ltd Maint Abuse Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_tires_ltm', mapping: 'fleet_stat.ltd_maint_tires_ltm', title: 'Ltd Maint Tires Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_repair_ltm', mapping: 'fleet_stat.ltd_maint_repair_ltm', title: 'Ltd Maint Repair Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_maint_combined', mapping: 'fleet_stat.ltd_maint_combined', title: 'Ltd Maint Combined', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_ltm_billing', mapping: 'fleet_stat.ltd_ltm_billing', title: 'Ltd Ltm Billing', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_lease', mapping: 'fleet_stat.ltd_lease', title: 'Ltd Lease', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_depreciation', mapping: 'fleet_stat.ltd_depreciation', title: 'Ltd Depreciation', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_cost_per_hr', mapping: 'fleet_stat.ltd_cost_per_hr', title: 'Ltd Cost Per Hr', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_cost_per_hr_customer', mapping: 'fleet_stat.ltd_cost_per_hr_customer', title: 'Ltd Cost Per Hr Customer', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_cost_per_hr_ltm', mapping: 'fleet_stat.ltd_cost_per_hr_ltm', title: 'Ltd Cost Per Hr Ltm', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			// { name: 'fleet_stats__ltd_rental', mapping: 'fleet_stat.ltd_rental', title: 'Ltd Rental', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__id', mapping: 'enterprise.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__name', mapping: 'enterprise.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_enabled', mapping: 'enterprise.is_enabled', title: 'Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__enterprise_type_id', mapping: 'enterprise.enterprise_type_id', title: 'Enterprise Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__pm_mode', mapping: 'enterprise.pm_mode', title: 'PM Mode', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__exclude_before', mapping: 'enterprise.exclude_before', title: 'Exclude Before', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_cost_enabled', mapping: 'enterprise.is_cost_enabled', title: 'Cost Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_demo_mode', mapping: 'enterprise.is_demo_mode', title: 'Demo Mode?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__chrome_id', mapping: 'enterprise.chrome_id', title: 'Chrome', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasOne: [
				'FleetStats'
			],
			hasMany: [
				'Equipment'
			],
			belongsTo: [
				'Enterprises'
			],
			belongsToMany: [
				'BusinessPartners'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'fleets__enterprise_id',
			// 'fleets__business_partners',
			// 'fleets__shift_start_1',
			// 'fleets__shift_start_2',
			// 'fleets__shift_start_3'
		],

	},

	entity: {
		methods: {

			getEquipment: function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				return Equipment.getBy((entity) => {
					return entity.equipment__fleet_id === this.fleets__id;
				});
			},

			getEnterprise: async function() {
				const Enterprises = this.getAssociatedRepository('Enterprises');
				let entity = Enterprises.getById(this.fleets__enterprise_id);
				if (!entity) {
					entity = await Enterprises.getSingleEntityFromServer(this.fleets__enterprise_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Fleets;
