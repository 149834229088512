/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import PartsPartTypesGridColumnsSideB from './Columns/PartsPartTypesGridColumnsSideB.js';

export default function PartsPartTypesGridSideB(props) {
	return <Grid
				reference="PartsPartTypesGridSideB"
				model="PartsPartTypes"
				usePermissions={true}
				columnsConfig={PartsPartTypesGridColumnsSideB}
				canColumnsReorder={false}
				canColumnsResize={false}

				{...props}
			/>;
}