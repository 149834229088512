/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { useEffect, } from 'react';
import {
	Column,
	Text,
} from 'native-base';
import Tree from '@onehat/ui/src/Components/Tree/Tree.js';
import oneHatData from '@onehat/data';
import getNodeIcon from '../../functions/getNodeIcon.js';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectEnterpriseId,
	setTreeSelection,
} from '../../models/Slices/AppSlice';
import getDisplayTextFromFleetTreeSearchResults from '../../functions/getDisplayTextFromFleetTreeSearchResults.js';

export default function FleetsTree(props) {
	const
		enterpriseId = useSelector(selectEnterpriseId),
		dispatch = useDispatch(),
		Fleets = oneHatData.getRepository('Fleets'),
		onChangeSelection = (selection) => {
			dispatch(setTreeSelection(selection));
		};

	useEffect(() => {
	
		if (!enterpriseId) {
			return;
		}

		// when changing enterprise, reload the tree
		Fleets.setBaseParam('conditions[fleets__enterprise_id]', enterpriseId);
		Fleets.loadRootNodes(1);

	}, [enterpriseId]);
	
	if (!enterpriseId) {
		return <Column flex={1} w="100%" alignItems="center" justifyContent="center">
					<Text>No enterprise selected</Text>
				</Column>;
	}

	return <Tree
				reference="FleetsTree"
				disableWithData={true}
				Repository={FleetLites}
				usePermissions={true}

				getNodeIcon={getNodeIcon}
				onChangeSelection={onChangeSelection}
				getDisplayTextFromSearchResults={getDisplayTextFromFleetTreeSearchResults}
				
				{...props}
			/>;
}