/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilCrudLogsGridEditorPanel from './UtilCrudLogsGridEditor.js';

export default function UtilCrudLogsFilteredGridEditorPanel(props) {
	return <UtilCrudLogsGridEditorPanel
				reference="UtilCrudLogsFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}