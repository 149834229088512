export default [
	    {
	        "id": "util_crud_logs__datetime",
	        "header": "Datetime",
	        "fieldName": "util_crud_logs__datetime",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "util_crud_logs__model",
	        "header": "Model",
	        "fieldName": "util_crud_logs__model",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "util_crud_logs__modelid",
	        "header": "Modelid",
	        "fieldName": "util_crud_logs__modelid",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "util_crud_logs__operation",
	        "header": "Operation",
	        "fieldName": "util_crud_logs__operation",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "util_crud_logs__display_value",
	        "header": "Display Value",
	        "fieldName": "util_crud_logs__display_value",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "util_crud_logs__user_id",
	        "header": "User",
	        "fieldName": "users__username",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "util_crud_logs__user_id"
	    },
	    {
	        "id": "util_crud_logs__json",
	        "header": "Json",
	        "fieldName": "util_crud_logs__json",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];