/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ArrayCombo from '@onehat/ui/src/Components/Form/Field/Combo/ArrayCombo.js';


const data = [
	['BusinessPartnersFleets', 'BusinessPartnersFleets'],
	['BusinessPartners', 'BusinessPartners'],
	['Enterprises', 'Enterprises'],
	['EqTypes', 'EqTypes'],
	['EquipmentPmSchedules', 'EquipmentPmSchedules'],
	['Equipment', 'Equipment'],
	['Fleets', 'Fleets'],
	['Groups', 'Groups'],
	['Manufacturers', 'Manufacturers'],
	['MeterReadings', 'MeterReadings'],
	['Parts', 'Parts'],
	['PmEvents', 'PmEvents'],
	['PmSchedules', 'PmSchedules'],
	['Users', 'Users'],
	['WorkOrders', 'WorkOrders'],
];

function CrudModelsCombo(props) {
	return <ArrayCombo
				data={data}
				disableDirectEntry={true}
				{...props}
			/>;
}

export default CrudModelsCombo;
