/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import PmSchedulesGridColumns from '../../Grid/Columns/PmSchedulesGridColumns.js';

export default function PmSchedulesGridPanel(props) {
	return <GridPanel
				reference="PmSchedulesGridPanel"
				model="PmSchedules"
				usePermissions={true}
				columnsConfig={PmSchedulesGridColumns}

				{...props}
			/>;
}