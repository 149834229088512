import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Chromes = {
	
	name: 'Chromes',
	
	model: {

		idProperty: 'chromes__id',
		displayProperty: 'chromes__name',
		sortProperty: 'chromes__name',
		
		sorters: null,

		validator: yup.object({
			chromes__name: yup.string().max(60).required(),
			chromes__url: yup.string().max(60).nullable(),
			chromes__abbr: yup.string().max(2).required(),
			chromes__is_enabled: yup.boolean().required()
		}),
		
		properties: [
			{ name: 'chromes__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'chromes__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'chromes__url', mapping: 'url', title: 'Url', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'chromes__abbr', mapping: 'abbr', title: 'Abbr', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'chromes__is_enabled', mapping: 'is_enabled', title: 'Enabled?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', defaultValue: 1, }
		],

		associations: {
			hasMany: [
				'Enterprises'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'chromes__is_enabled'
		],

	},

	entity: {
		methods: {

			getEnterprises: function() {
				const Enterprises = this.getAssociatedRepository('Enterprises');
				return Enterprises.getBy((entity) => {
					return entity.enterprises__chrome_id === this.chromes__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Chromes;
