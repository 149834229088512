export default [
	    {
	        "id": "parts_part_types__part_id",
	        "header": "Part",
	        "fieldName": "parts__part_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "parts_part_types__part_id",
	        "flex": 1,
	        "minWidth": 150
	    }
	];