/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import PartsPartTypesEditorWindow from '../../Window/PartsPartTypesEditorWindow.js';
import PartsPartTypesGridColumns from '../../Grid/Columns/PartsPartTypesGridColumns.js';

export default function PartsPartTypesGridEditorPanel(props) {
	return <GridPanel
				reference="PartsPartTypesGridEditorPanel"
				model="PartsPartTypes"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={PartsPartTypesEditorWindow}
				columnsConfig={PartsPartTypesGridColumns}
				
				
				{...props}
			/>;
}