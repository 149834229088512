/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import EnterprisesGridColumns from '../../Grid/Columns/EnterprisesGridColumns.js';

export default function EnterprisesGridPanel(props) {
	return <GridPanel
				reference="EnterprisesGridPanel"
				model="Enterprises"
				usePermissions={true}
				columnsConfig={EnterprisesGridColumns}

				{...props}
			/>;
}