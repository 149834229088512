/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqForkLengthsGrid from './EqForkLengthsGrid.js';

export default function EqForkLengthsFilteredGrid(props) {
	return <EqForkLengthsGrid
				reference="EqForkLengthsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}