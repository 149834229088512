/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import EqVoltagesGridColumns from './Columns/EqVoltagesGridColumns.js';

export default function EqVoltagesGrid(props) {
	return <Grid
				reference="EqVoltagesGrid"
				model="EqVoltages"
				usePermissions={true}
				columnsConfig={EqVoltagesGridColumns}

				{...props}
			/>;
}