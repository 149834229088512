export default [
	    {
	        "id": "wo_labors__work_order_id",
	        "header": "Work Order",
	        "fieldName": "work_orders__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "wo_labors__work_order_id"
	    },
	    {
	        "id": "wo_labors__wo_labor_type_id",
	        "header": "Wo Labor Type",
	        "fieldName": "wo_labor_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "wo_labors__wo_labor_type_id"
	    },
	    {
	        "id": "wo_labors__date",
	        "header": "Date",
	        "fieldName": "wo_labors__date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "wo_labors__hours",
	        "header": "Hours",
	        "fieldName": "wo_labors__hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "wo_labors__description",
	        "header": "Description",
	        "fieldName": "wo_labors__description",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "wo_labors__pm_technician_id",
	        "header": "PM Technician",
	        "fieldName": "users__username",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "wo_labors__pm_technician_id"
	    },
	    {
	        "id": "wo_labors__hourly_rate",
	        "header": "Hourly Rate",
	        "fieldName": "wo_labors__hourly_rate",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "wo_labors__unit_price",
	        "header": "Unit Price",
	        "fieldName": "wo_labors__unit_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "wo_labors__overtime_price",
	        "header": "Overtime Price",
	        "fieldName": "wo_labors__overtime_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "wo_labors__sell_price",
	        "header": "Sell Price",
	        "fieldName": "wo_labors__sell_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "wo_labors__is_checked",
	        "header": "Checked?",
	        "fieldName": "wo_labors__is_checked",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];