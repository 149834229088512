/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import PartsPartsEditorWindow from '../../../Window/PartsPartsEditorWindow.js';

function PartsPartsComboEditor(props) {
	return <ComboEditor
				reference="PartsPartsComboEditor"
				model="PartsParts"
				uniqueRepository={true}
				Editor={PartsPartsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default PartsPartsComboEditor;