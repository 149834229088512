/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EquipmentPmSchedulesGridPanel from './EquipmentPmSchedulesGrid.js';

export default function EquipmentPmSchedulesFilteredGridPanel(props) {
	return <EquipmentPmSchedulesGridPanel
				reference="EquipmentPmSchedulesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}