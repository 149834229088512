import * as yup from 'yup'; // https://github.com/jquense/yup#string

const TagTypes = {
	
	name: 'TagTypes',
	
	model: {

		idProperty: 'tag_types__id',
		displayProperty: 'tag_types__name',
		sortProperty: 'tag_types__name',
		
		sorters: null,

		validator: yup.object({
			tag_types__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'tag_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'tag_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'Tags'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getTags: function() {
				const Tags = this.getAssociatedRepository('Tags');
				return Tags.getBy((entity) => {
					return entity.tags__tag_type_id === this.tag_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default TagTypes;
