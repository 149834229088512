import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Users = {
	
	name: 'Users',
	
	model: {

		idProperty: 'users__id',
		displayProperty: 'users__username',
		sortProperty: 'users__username',
		
		sorters: null,

		validator: yup.object({
			users__username: yup.string().max(50).nullable(),
			users__email: yup.string().max(50).nullable(),
			users__password: yup.string().nullable(),
			users__first_name: yup.string().max(50).nullable(),
			users__last_name: yup.string().max(50).nullable(),
			users__groups: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'users__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'users__username', mapping: 'username', title: 'Username', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'users__email', mapping: 'email', title: 'Email', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'users__password', mapping: 'password', title: 'Password', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'users__first_name', mapping: 'first_name', title: 'First Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'users__last_name', mapping: 'last_name', title: 'Last Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'users__token', mapping: 'token', title: 'Token', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'users__token_generated', mapping: 'token_generated', title: 'Token Generated', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'users__expo_token', mapping: 'expo_token', title: 'Expo Token', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'users__password_reset', mapping: 'password_reset', title: 'Password Reset', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'users__full_name', mapping: 'full_name', title: 'Full Name', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '2. Stats', },
			{ name: 'users__last_login', mapping: 'last_login', title: 'Last Login', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, isEditingDisabled: true, fieldGroup: '2. Stats', },
			{ name: 'users__login_count', mapping: 'login_count', title: 'Login Count', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '2. Stats', },
			{ name: 'users__groups', mapping: 'groups', title: 'Groups', filterType: {"type":"GroupsTag"}, viewerType: {"type":"GroupsTag"}, editorType: {"type":"GroupsTagEditor","loadAfterRender":!1}, fieldGroup: '1. Details', }
		],

		associations: {
			hasMany: [
				'WorkOrders',
				'Permissions',
				'UtilCrudLogs',
				'UtilErrorLogs',
				'PmEvents',
				'WoLabors',
				'EquipmentPmSchedules'
			],
			belongsToMany: [
				'Groups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'users__token_generated',
			// 'users__last_login',
			// 'users__login_count',
			// 'users__groups'
		],

	},

	entity: {
		methods: {

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__user_id === this.users__id;
				});
			},

			getPermissions: function() {
				const Permissions = this.getAssociatedRepository('Permissions');
				return Permissions.getBy((entity) => {
					return entity.permissions__user_id === this.users__id;
				});
			},

			getUtilCrudLogs: function() {
				const UtilCrudLogs = this.getAssociatedRepository('UtilCrudLogs');
				return UtilCrudLogs.getBy((entity) => {
					return entity.util_crud_logs__user_id === this.users__id;
				});
			},

			getUtilErrorLogs: function() {
				const UtilErrorLogs = this.getAssociatedRepository('UtilErrorLogs');
				return UtilErrorLogs.getBy((entity) => {
					return entity.util_error_logs__user_id === this.users__id;
				});
			},

			getPmEvents: function() {
				const PmEvents = this.getAssociatedRepository('PmEvents');
				return PmEvents.getBy((entity) => {
					return entity.pm_events__user_id === this.users__id;
				});
			},

			getWoLabors: function() {
				const WoLabors = this.getAssociatedRepository('WoLabors');
				return WoLabors.getBy((entity) => {
					return entity.wo_labors__pm_technician_id === this.users__id;
				});
			},

			getEquipmentPmSchedules: function() {
				const EquipmentPmSchedules = this.getAssociatedRepository('EquipmentPmSchedules');
				return EquipmentPmSchedules.getBy((entity) => {
					return entity.equipment_pm_schedules__pm_technician_id === this.users__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Users;
