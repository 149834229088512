/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WoClassesEditorWindow from '../Window/WoClassesEditorWindow.js';
import WoClassesGridColumns from './Columns/WoClassesGridColumns.js';

export default function WoClassesGridEditor(props) {
	return <WindowedGridEditor
				reference="WoClassesGridEditor"
				model="WoClasses"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={WoClassesEditorWindow}
				columnsConfig={WoClassesGridColumns}
				
				
				{...props}
			/>;
}