/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import TagsEditorWindow from '../../Window/TagsEditorWindow.js';
import TagsGridColumns from '../../Grid/Columns/TagsGridColumns.js';

export default function TagsGridEditorPanel(props) {
	return <GridPanel
				reference="TagsGridEditorPanel"
				model="Tags"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={TagsEditorWindow}
				columnsConfig={TagsGridColumns}
				
				
				{...props}
			/>;
}