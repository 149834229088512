/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilErrorLogsSideGridEditor from './UtilErrorLogsSideGridEditor.js';

export default function UtilErrorLogsFilteredSideGridEditor(props) {
	return <UtilErrorLogsSideGridEditor
				reference="UtilErrorLogsFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}