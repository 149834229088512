/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import UtilProcessLogsGridColumns from '../../Grid/Columns/UtilProcessLogsGridColumns.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function UtilProcessLogsInlineGridEditorPanel(props) {
	return <GridPanel
				reference="UtilProcessLogsInlineGridEditorPanel"
				model="UtilProcessLogs"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={UtilProcessLogsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}