/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoOtherChargeTypesGridEditor from './WoOtherChargeTypesGridEditor.js';

export default function WoOtherChargeTypesFilteredGridEditor(props) {
	return <WoOtherChargeTypesGridEditor
				reference="WoOtherChargeTypesFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}