/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqStatsEditor from '../Editor/EqStatsEditor.js';
import EqStatsGridColumns from './Columns/EqStatsGridColumns.js';

export default function EqStatsSideGridEditor(props) {
	return <SideGridEditor
				reference="EqStatsSideGridEditor"
				model="EqStats"
				usePermissions={true}
				isCollapsible={false}
				Editor={EqStatsEditor}
				columnsConfig={EqStatsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}