export default [
	    {
	        "id": "util_error_logs__datetime",
	        "header": "Datetime",
	        "fieldName": "util_error_logs__datetime",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "util_error_logs__location",
	        "header": "Location",
	        "fieldName": "util_error_logs__location",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "util_error_logs__util_error_code_id",
	        "header": "Util Error Code",
	        "fieldName": "util_error_codes__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "util_error_logs__util_error_code_id"
	    },
	    {
	        "id": "util_error_logs__message",
	        "header": "Message",
	        "fieldName": "util_error_logs__message",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "util_error_logs__request",
	        "header": "Request",
	        "fieldName": "util_error_logs__request",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "util_error_logs__exception",
	        "header": "Exception",
	        "fieldName": "util_error_logs__exception",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "util_error_logs__json",
	        "header": "Json",
	        "fieldName": "util_error_logs__json",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "util_error_logs__user_id",
	        "header": "User",
	        "fieldName": "users__username",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "util_error_logs__user_id"
	    }
	];