export default [
	    {
	        "id": "eq_monthly_stats__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "eq_monthly_stats__equipment_id"
	    },
	    {
	        "id": "eq_monthly_stats__month",
	        "header": "Month",
	        "fieldName": "eq_monthly_stats__month",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__latest_meter_reading",
	        "header": "Latest Meter Reading",
	        "fieldName": "eq_monthly_stats__latest_meter_reading",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtbf",
	        "header": "Mtbf",
	        "fieldName": "eq_monthly_stats__mtbf",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__latest_repair_date",
	        "header": "Latest Repair Date",
	        "fieldName": "eq_monthly_stats__latest_repair_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__overdue_pms",
	        "header": "Overdue Pms",
	        "fieldName": "eq_monthly_stats__overdue_pms",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__latest_pm_date",
	        "header": "Latest Pm Date",
	        "fieldName": "eq_monthly_stats__latest_pm_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__next_pm_date",
	        "header": "Next Pm Date",
	        "fieldName": "eq_monthly_stats__next_pm_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_hours",
	        "header": "Mtd Hours",
	        "fieldName": "eq_monthly_stats__mtd_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_estimated_hours",
	        "header": "Mtd Estimated Hours",
	        "fieldName": "eq_monthly_stats__mtd_estimated_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_available_hours",
	        "header": "Mtd Available Hours",
	        "fieldName": "eq_monthly_stats__mtd_available_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_util",
	        "header": "Mtd Util",
	        "fieldName": "eq_monthly_stats__mtd_util",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint",
	        "header": "Mtd Maint",
	        "fieldName": "eq_monthly_stats__mtd_maint",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_preventive",
	        "header": "Mtd Maint Preventive",
	        "fieldName": "eq_monthly_stats__mtd_maint_preventive",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_abuse",
	        "header": "Mtd Maint Abuse",
	        "fieldName": "eq_monthly_stats__mtd_maint_abuse",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_tires",
	        "header": "Mtd Maint Tires",
	        "fieldName": "eq_monthly_stats__mtd_maint_tires",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_repair",
	        "header": "Mtd Maint Repair",
	        "fieldName": "eq_monthly_stats__mtd_maint_repair",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_ltm",
	        "header": "Mtd Maint Ltm",
	        "fieldName": "eq_monthly_stats__mtd_maint_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_preventive_ltm",
	        "header": "Mtd Maint Preventive Ltm",
	        "fieldName": "eq_monthly_stats__mtd_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_abuse_ltm",
	        "header": "Mtd Maint Abuse Ltm",
	        "fieldName": "eq_monthly_stats__mtd_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_tires_ltm",
	        "header": "Mtd Maint Tires Ltm",
	        "fieldName": "eq_monthly_stats__mtd_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_repair_ltm",
	        "header": "Mtd Maint Repair Ltm",
	        "fieldName": "eq_monthly_stats__mtd_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_maint_combined",
	        "header": "Mtd Maint Combined",
	        "fieldName": "eq_monthly_stats__mtd_maint_combined",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_ltm_billing",
	        "header": "Mtd Ltm Billing",
	        "fieldName": "eq_monthly_stats__mtd_ltm_billing",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_ownership",
	        "header": "Mtd Ownership",
	        "fieldName": "eq_monthly_stats__mtd_ownership",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_cost_per_hr",
	        "header": "Mtd Cost Per Hr",
	        "fieldName": "eq_monthly_stats__mtd_cost_per_hr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_cost_per_hr_customer",
	        "header": "Mtd Cost Per Hr Customer",
	        "fieldName": "eq_monthly_stats__mtd_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__mtd_cost_per_hr_ltm",
	        "header": "Mtd Cost Per Hr Ltm",
	        "fieldName": "eq_monthly_stats__mtd_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_monthly_stats__cust_month_total",
	        "header": "Cust Month Total",
	        "fieldName": "eq_monthly_stats__cust_month_total",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];