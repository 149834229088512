/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PmScheduleModesGrid from './PmScheduleModesGrid.js';

export default function PmScheduleModesFilteredGrid(props) {
	return <PmScheduleModesGrid
				reference="PmScheduleModesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}