export default [
	    {
	        "id": "groups_users__user_id",
	        "header": "User",
	        "fieldName": "users__username",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "flex": 1,
	        "minWidth": 150
	    }
	];