export default [
	    {
	        "id": "addresses__title",
	        "header": "Title",
	        "fieldName": "addresses__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "addresses__is_enabled",
	        "header": "Enabled?",
	        "fieldName": "addresses__is_enabled",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "addresses__street1",
	        "header": "Street1",
	        "fieldName": "addresses__street1",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "addresses__street2",
	        "header": "Street2",
	        "fieldName": "addresses__street2",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "addresses__city",
	        "header": "City",
	        "fieldName": "addresses__city",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "addresses__state_abbr",
	        "header": "State Abbr",
	        "fieldName": "addresses__state_abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "addresses__zip",
	        "header": "Zip",
	        "fieldName": "addresses__zip",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "addresses__phone",
	        "header": "Phone",
	        "fieldName": "addresses__phone",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "addresses__fax",
	        "header": "Fax",
	        "fieldName": "addresses__fax",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "addresses__comments",
	        "header": "Comments",
	        "fieldName": "addresses__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];