/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import WoModesEditorWindow from '../../Window/WoModesEditorWindow.js';
import WoModesGridColumns from '../../Grid/Columns/WoModesGridColumns.js';

export default function WoModesGridEditorPanel(props) {
	return <GridPanel
				reference="WoModesGridEditorPanel"
				model="WoModes"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={WoModesEditorWindow}
				columnsConfig={WoModesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}