/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EqVoltagesEditorWindow from '../../../Window/EqVoltagesEditorWindow.js';

function EqVoltagesTag(props) {
	return <Tag
				reference="EqVoltagesTag"
				model="EqVoltages"
				uniqueRepository={true}
				Editor={EqVoltagesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EqVoltagesTag;