/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import FleetsEditorWindow from '../Window/FleetsEditorWindow.js';
import FleetsGridColumns from './Columns/FleetsGridColumns.js';

export default function FleetsGridEditor(props) {
	return <WindowedGridEditor
				reference="FleetsGridEditor"
				model="Fleets"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={FleetsEditorWindow}
				columnsConfig={FleetsGridColumns}
				
				
				{...props}
			/>;
}