/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import BusinessPartnersEditorWindow from '../../../Window/BusinessPartnersEditorWindow.js';

function BusinessPartnersTagEditor(props) {
	return <TagEditor
				reference="BusinessPartnersTagEditor"
				model="BusinessPartners"
				uniqueRepository={true}
				Editor={BusinessPartnersEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default BusinessPartnersTagEditor;