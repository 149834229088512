/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqTireTypesEditorWindow from '../Window/EqTireTypesEditorWindow.js';
import EqTireTypesGridColumns from './Columns/EqTireTypesGridColumns.js';

export default function EqTireTypesGridEditor(props) {
	return <WindowedGridEditor
				reference="EqTireTypesGridEditor"
				model="EqTireTypes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EqTireTypesEditorWindow}
				columnsConfig={EqTireTypesGridColumns}
				
				
				{...props}
			/>;
}