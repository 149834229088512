/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import WorkOrdersGridColumns from '../../Grid/Columns/WorkOrdersGridColumns.js';

export default function WorkOrdersGridPanel(props) {
	return <GridPanel
				reference="WorkOrdersGridPanel"
				model="WorkOrders"
				usePermissions={true}
				columnsConfig={WorkOrdersGridColumns}

				{...props}
			/>;
}