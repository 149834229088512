/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import PartTypesEditorWindow from '../../../Window/PartTypesEditorWindow.js';

function PartTypesTagEditor(props) {
	return <TagEditor
				reference="PartTypesTagEditor"
				model="PartTypes"
				uniqueRepository={true}
				Editor={PartTypesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default PartTypesTagEditor;