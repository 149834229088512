/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import BusinessPartnersFleetsGridEditorSideB from './BusinessPartnersFleetsGridEditorSideB.js';

export default function BusinessPartnersFleetsFilteredGridEditorSideB(props) {
	return <BusinessPartnersFleetsGridEditorSideB
				reference="BusinessPartnersFleetsFilteredGridEditorSideB"
				useFilters={true}

				{...props}
			/>;
}