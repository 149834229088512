/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { InlineGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqPowerTypesGridColumns from './Columns/EqPowerTypesGridColumns.js';

export default function EqPowerTypesInlineGridEditor(props) {
	return <InlineGridEditor
				reference="EqPowerTypesInlineGridEditor"
				model="EqPowerTypes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				columnsConfig={EqPowerTypesGridColumns}
				
				
				{...props}
			/>;
}