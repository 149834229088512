/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoLaborsGridPanel from './WoLaborsGrid.js';

export default function WoLaborsFilteredGridPanel(props) {
	return <WoLaborsGridPanel
				reference="WoLaborsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}