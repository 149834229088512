import * as React from "react"
import Svg, { Defs, G, Path } from "react-native-svg"
import { Icon } from 'native-base';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props) {
  return (
    <Icon
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 750.6 536.8"
      {...props}
    >
      <Defs></Defs>
      <G id="Layer_1-2">
        <Path
          className="cls-1"
          d="M413.8 2.4c-23.3 2.9-14.6 24.8-9.1 39.6 46.7 138.7 91.5 278 139.3 416.3 5.6 12.1 20.1 8.6 29.9 4.5 13.4-4.3 26.7-8.8 40-13.2 31.9-10.5 63.8-21 95.7-31.6 15-6.4 47.6-8.2 39.8-31.7-6.5-15.3-22.6-9-34.5-4.9-47.5 15.6-95.1 31.2-142.6 47-3.2 1.1-3.8.8-4.9-2.5-43.8-132.3-88-264.4-132-396.7-3.7-11-7-28.3-21.7-26.9zM203.5 469.8c0 37-30 67-67 67s-67-30-67-67 30-67 67-67 67 30 67 67zm-67-40c-22.1 0-40 17.9-40 40s17.9 40 40 40 40-17.9 40-40-17.9-40-40-40zM476.5 469.8c0 37-30 67-67 67s-67-30-67-67 30-67 67-67 67 30 67 67zm-67-40c-22.1 0-40 17.9-40 40s17.9 40 40 40 40-17.9 40-40-17.9-40-40-40z"
        />
        <Path
          className="cls-1"
          d="M118.7 143.3v92.8c0 3.5 0 3.6 3.5 3.6 31.7 0 63.4-.2 95 0 .9.3 2.1.8 2.5 1.6 4.4 7.5 8.8 15.2 13.1 22.8 10.2 17.9 20.5 35.7 30.6 53.7 1.3 2.2 2.7 2.8 5 2.8h99c1.4 0 1.9-.6 1.7-2-1.8-11.6-3.6-23.1-5.6-34.7-9.2-47.8-18.6-95.7-30.6-142.9-11.8-39-29.7-95.3-79.1-94.2-43.5.4-87 .1-130.5.1-1.8 0-4.7-.6-4.6 2.1v94.3zM45 460.6H21.7c-9.1-35.9-17-72.2-20.5-109.1-1.1-15.9-2.7-32.5 2.3-47.8 1.7-10.2 10.2-18.4 21-17.9 14.7-.2 29.3 0 44 0q3.8 0 3.8-3.8V143.2 4.9c0-3.9 0-4 4-4 71.1 2.6 143.8-5.4 214.2 3.5 44.2 16.1 66.4 62.2 83.8 102.7 26 63.3 39.1 131.4 48.2 199 1.1 22.6 26.5 22.5 42 13.9 2.7-.6 11.2-7.4 12.2-3.3 3.8 12 45 143.9 45 143.9h-20.5c-4.6-46.5-43.8-82.8-91.5-82.8s-86.9 36.3-91.5 82.8h-89.9c-4.6-46.5-43.8-82.8-91.5-82.8s-86.9 36.3-91.5 82.8z"
        />
      </G>
    </Icon>
  )
}

export default SvgComponent
