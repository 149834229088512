export default [
	    {
	        "id": "eq_stats__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__is_stale",
	        "header": "Stale?",
	        "fieldName": "eq_stats__is_stale",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__path",
	        "header": "Path",
	        "fieldName": "eq_stats__path",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__latest_meter_reading",
	        "header": "Latest Meter Reading",
	        "fieldName": "eq_stats__latest_meter_reading",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__latest_meter_date",
	        "header": "Latest Meter Date",
	        "fieldName": "eq_stats__latest_meter_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__latest_meter_source",
	        "header": "Latest Meter Source",
	        "fieldName": "eq_stats__latest_meter_source",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__latest_repair_date",
	        "header": "Latest Repair Date",
	        "fieldName": "eq_stats__latest_repair_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__latest_pm_data",
	        "header": "Latest Pm Data",
	        "fieldName": "eq_stats__latest_pm_data",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__due_pms",
	        "header": "Due Pms",
	        "fieldName": "eq_stats__due_pms",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__next_pm_due_data",
	        "header": "Next Pm Due Data",
	        "fieldName": "eq_stats__next_pm_due_data",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__overdue_pms",
	        "header": "Overdue Pms",
	        "fieldName": "eq_stats__overdue_pms",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__assigned_technician_data",
	        "header": "Assigned Technician Data",
	        "fieldName": "eq_stats__assigned_technician_data",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__mean_time_between_failures",
	        "header": "Mean Time Between Failures",
	        "fieldName": "eq_stats__mean_time_between_failures",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__estimated_annual_hours",
	        "header": "Estimated Annual Hours",
	        "fieldName": "eq_stats__estimated_annual_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__is_warranty",
	        "header": "Warranty?",
	        "fieldName": "eq_stats__is_warranty",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__is_lease",
	        "header": "Lease?",
	        "fieldName": "eq_stats__is_lease",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__is_ltm",
	        "header": "Ltm?",
	        "fieldName": "eq_stats__is_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__lease_number",
	        "header": "Lease Number",
	        "fieldName": "eq_stats__lease_number",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__lease_term",
	        "header": "Lease Term",
	        "fieldName": "eq_stats__lease_term",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__lease_expected_end_date",
	        "header": "Lease Expected End Date",
	        "fieldName": "eq_stats__lease_expected_end_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__lease_status_id",
	        "header": "Lease Status",
	        "fieldName": "eq_stats__lease_status_id",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__lease_payment",
	        "header": "Lease Payment",
	        "fieldName": "eq_stats__lease_payment",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__lease_pays_remaining",
	        "header": "Lease Pays Remaining",
	        "fieldName": "eq_stats__lease_pays_remaining",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__lease_midterm_checked",
	        "header": "Lease Midterm Checked",
	        "fieldName": "eq_stats__lease_midterm_checked",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__lease_inspected",
	        "header": "Lease Inspected",
	        "fieldName": "eq_stats__lease_inspected",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltm_contract_number",
	        "header": "Ltm Contract Number",
	        "fieldName": "eq_stats__ltm_contract_number",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__ltm_start_date",
	        "header": "Ltm Start Date",
	        "fieldName": "eq_stats__ltm_start_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltm_end_date",
	        "header": "Ltm End Date",
	        "fieldName": "eq_stats__ltm_end_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltm_elapsed_annual_hours",
	        "header": "Ltm Elapsed Annual Hours",
	        "fieldName": "eq_stats__ltm_elapsed_annual_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltm_max_annual_hours",
	        "header": "Ltm Max Annual Hours",
	        "fieldName": "eq_stats__ltm_max_annual_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltm_elapsed_contract_hours",
	        "header": "Ltm Elapsed Contract Hours",
	        "fieldName": "eq_stats__ltm_elapsed_contract_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltm_max_contract_hours",
	        "header": "Ltm Max Contract Hours",
	        "fieldName": "eq_stats__ltm_max_contract_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltm_latest_invoice_date",
	        "header": "Ltm Latest Invoice Date",
	        "fieldName": "eq_stats__ltm_latest_invoice_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltm_latest_invoice_amount",
	        "header": "Ltm Latest Invoice Amount",
	        "fieldName": "eq_stats__ltm_latest_invoice_amount",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_hours",
	        "header": "Mtd Hours",
	        "fieldName": "eq_stats__mtd_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_available_hours",
	        "header": "Mtd Available Hours",
	        "fieldName": "eq_stats__mtd_available_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_util",
	        "header": "Mtd Util",
	        "fieldName": "eq_stats__mtd_util",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint",
	        "header": "Mtd Maint",
	        "fieldName": "eq_stats__mtd_maint",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_preventive",
	        "header": "Mtd Maint Preventive",
	        "fieldName": "eq_stats__mtd_maint_preventive",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_abuse",
	        "header": "Mtd Maint Abuse",
	        "fieldName": "eq_stats__mtd_maint_abuse",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_abuseRental",
	        "header": "Mtd Maint AbuseRental",
	        "fieldName": "eq_stats__mtd_maint_abuseRental",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_tires",
	        "header": "Mtd Maint Tires",
	        "fieldName": "eq_stats__mtd_maint_tires",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_repair",
	        "header": "Mtd Maint Repair",
	        "fieldName": "eq_stats__mtd_maint_repair",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_ltm",
	        "header": "Mtd Maint Ltm",
	        "fieldName": "eq_stats__mtd_maint_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_preventive_ltm",
	        "header": "Mtd Maint Preventive Ltm",
	        "fieldName": "eq_stats__mtd_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_abuse_ltm",
	        "header": "Mtd Maint Abuse Ltm",
	        "fieldName": "eq_stats__mtd_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_tires_ltm",
	        "header": "Mtd Maint Tires Ltm",
	        "fieldName": "eq_stats__mtd_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_repair_ltm",
	        "header": "Mtd Maint Repair Ltm",
	        "fieldName": "eq_stats__mtd_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_maint_combined",
	        "header": "Mtd Maint Combined",
	        "fieldName": "eq_stats__mtd_maint_combined",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_ltm_billing",
	        "header": "Mtd Ltm Billing",
	        "fieldName": "eq_stats__mtd_ltm_billing",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_lease",
	        "header": "Mtd Lease",
	        "fieldName": "eq_stats__mtd_lease",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_depreciation",
	        "header": "Mtd Depreciation",
	        "fieldName": "eq_stats__mtd_depreciation",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_cost_per_hr",
	        "header": "Mtd Cost Per Hr",
	        "fieldName": "eq_stats__mtd_cost_per_hr",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_cost_per_hr_customer",
	        "header": "Mtd Cost Per Hr Customer",
	        "fieldName": "eq_stats__mtd_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__mtd_cost_per_hr_ltm",
	        "header": "Mtd Cost Per Hr Ltm",
	        "fieldName": "eq_stats__mtd_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__qtd_hours",
	        "header": "Qtd Hours",
	        "fieldName": "eq_stats__qtd_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__qtd_available_hours",
	        "header": "Qtd Available Hours",
	        "fieldName": "eq_stats__qtd_available_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__qtd_util",
	        "header": "Qtd Util",
	        "fieldName": "eq_stats__qtd_util",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_hours",
	        "header": "Ytd Hours",
	        "fieldName": "eq_stats__ytd_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_available_hours",
	        "header": "Ytd Available Hours",
	        "fieldName": "eq_stats__ytd_available_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_util",
	        "header": "Ytd Util",
	        "fieldName": "eq_stats__ytd_util",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint",
	        "header": "Ytd Maint",
	        "fieldName": "eq_stats__ytd_maint",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_preventive",
	        "header": "Ytd Maint Preventive",
	        "fieldName": "eq_stats__ytd_maint_preventive",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_abuse",
	        "header": "Ytd Maint Abuse",
	        "fieldName": "eq_stats__ytd_maint_abuse",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_abuseRental",
	        "header": "Ytd Maint AbuseRental",
	        "fieldName": "eq_stats__ytd_maint_abuseRental",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_tires",
	        "header": "Ytd Maint Tires",
	        "fieldName": "eq_stats__ytd_maint_tires",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_repair",
	        "header": "Ytd Maint Repair",
	        "fieldName": "eq_stats__ytd_maint_repair",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_ltm",
	        "header": "Ytd Maint Ltm",
	        "fieldName": "eq_stats__ytd_maint_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_preventive_ltm",
	        "header": "Ytd Maint Preventive Ltm",
	        "fieldName": "eq_stats__ytd_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_abuse_ltm",
	        "header": "Ytd Maint Abuse Ltm",
	        "fieldName": "eq_stats__ytd_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_tires_ltm",
	        "header": "Ytd Maint Tires Ltm",
	        "fieldName": "eq_stats__ytd_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_repair_ltm",
	        "header": "Ytd Maint Repair Ltm",
	        "fieldName": "eq_stats__ytd_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_maint_combined",
	        "header": "Ytd Maint Combined",
	        "fieldName": "eq_stats__ytd_maint_combined",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_ltm_billing",
	        "header": "Ytd Ltm Billing",
	        "fieldName": "eq_stats__ytd_ltm_billing",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_lease",
	        "header": "Ytd Lease",
	        "fieldName": "eq_stats__ytd_lease",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytn_depreciation",
	        "header": "Ytn Depreciation",
	        "fieldName": "eq_stats__ytn_depreciation",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_cost_per_hr",
	        "header": "Ytd Cost Per Hr",
	        "fieldName": "eq_stats__ytd_cost_per_hr",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_cost_per_hr_customer",
	        "header": "Ytd Cost Per Hr Customer",
	        "fieldName": "eq_stats__ytd_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ytd_cost_per_hr_ltm",
	        "header": "Ytd Cost Per Hr Ltm",
	        "fieldName": "eq_stats__ytd_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_hours",
	        "header": "Rtm Hours",
	        "fieldName": "eq_stats__rtm_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_available_hours",
	        "header": "Rtm Available Hours",
	        "fieldName": "eq_stats__rtm_available_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_util",
	        "header": "Rtm Util",
	        "fieldName": "eq_stats__rtm_util",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint",
	        "header": "Rtm Maint",
	        "fieldName": "eq_stats__rtm_maint",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_preventive",
	        "header": "Rtm Maint Preventive",
	        "fieldName": "eq_stats__rtm_maint_preventive",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_abuse",
	        "header": "Rtm Maint Abuse",
	        "fieldName": "eq_stats__rtm_maint_abuse",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_abuseRental",
	        "header": "Rtm Maint AbuseRental",
	        "fieldName": "eq_stats__rtm_maint_abuseRental",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_tires",
	        "header": "Rtm Maint Tires",
	        "fieldName": "eq_stats__rtm_maint_tires",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_repair",
	        "header": "Rtm Maint Repair",
	        "fieldName": "eq_stats__rtm_maint_repair",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_ltm",
	        "header": "Rtm Maint Ltm",
	        "fieldName": "eq_stats__rtm_maint_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_preventive_ltm",
	        "header": "Rtm Maint Preventive Ltm",
	        "fieldName": "eq_stats__rtm_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_abuse_ltm",
	        "header": "Rtm Maint Abuse Ltm",
	        "fieldName": "eq_stats__rtm_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_tires_ltm",
	        "header": "Rtm Maint Tires Ltm",
	        "fieldName": "eq_stats__rtm_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_repair_ltm",
	        "header": "Rtm Maint Repair Ltm",
	        "fieldName": "eq_stats__rtm_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_maint_combined",
	        "header": "Rtm Maint Combined",
	        "fieldName": "eq_stats__rtm_maint_combined",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_ltm_billing",
	        "header": "Rtm Ltm Billing",
	        "fieldName": "eq_stats__rtm_ltm_billing",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_lease",
	        "header": "Rtm Lease",
	        "fieldName": "eq_stats__rtm_lease",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_depreciation",
	        "header": "Rtm Depreciation",
	        "fieldName": "eq_stats__rtm_depreciation",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_cost_per_hr",
	        "header": "Rtm Cost Per Hr",
	        "fieldName": "eq_stats__rtm_cost_per_hr",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_cost_per_hr_customer",
	        "header": "Rtm Cost Per Hr Customer",
	        "fieldName": "eq_stats__rtm_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__rtm_cost_per_hr_ltm",
	        "header": "Rtm Cost Per Hr Ltm",
	        "fieldName": "eq_stats__rtm_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_hours",
	        "header": "Ltd Hours",
	        "fieldName": "eq_stats__ltd_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_available_hours",
	        "header": "Ltd Available Hours",
	        "fieldName": "eq_stats__ltd_available_hours",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_util",
	        "header": "Ltd Util",
	        "fieldName": "eq_stats__ltd_util",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint",
	        "header": "Ltd Maint",
	        "fieldName": "eq_stats__ltd_maint",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_preventive",
	        "header": "Ltd Maint Preventive",
	        "fieldName": "eq_stats__ltd_maint_preventive",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_abuse",
	        "header": "Ltd Maint Abuse",
	        "fieldName": "eq_stats__ltd_maint_abuse",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_abuseRental",
	        "header": "Ltd Maint AbuseRental",
	        "fieldName": "eq_stats__ltd_maint_abuseRental",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_tires",
	        "header": "Ltd Maint Tires",
	        "fieldName": "eq_stats__ltd_maint_tires",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_repair",
	        "header": "Ltd Maint Repair",
	        "fieldName": "eq_stats__ltd_maint_repair",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_ltm",
	        "header": "Ltd Maint Ltm",
	        "fieldName": "eq_stats__ltd_maint_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_preventive_ltm",
	        "header": "Ltd Maint Preventive Ltm",
	        "fieldName": "eq_stats__ltd_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_abuse_ltm",
	        "header": "Ltd Maint Abuse Ltm",
	        "fieldName": "eq_stats__ltd_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_tires_ltm",
	        "header": "Ltd Maint Tires Ltm",
	        "fieldName": "eq_stats__ltd_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_repair_ltm",
	        "header": "Ltd Maint Repair Ltm",
	        "fieldName": "eq_stats__ltd_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_depreciation",
	        "header": "Ltd Depreciation",
	        "fieldName": "eq_stats__ltd_depreciation",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_maint_combined",
	        "header": "Ltd Maint Combined",
	        "fieldName": "eq_stats__ltd_maint_combined",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_ltm_billing",
	        "header": "Ltd Ltm Billing",
	        "fieldName": "eq_stats__ltd_ltm_billing",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_lease",
	        "header": "Ltd Lease",
	        "fieldName": "eq_stats__ltd_lease",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltn_depreciation",
	        "header": "Ltn Depreciation",
	        "fieldName": "eq_stats__ltn_depreciation",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_cost_per_hr",
	        "header": "Ltd Cost Per Hr",
	        "fieldName": "eq_stats__ltd_cost_per_hr",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_cost_per_hr_customer",
	        "header": "Ltd Cost Per Hr Customer",
	        "fieldName": "eq_stats__ltd_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__ltd_cost_per_hr_ltm",
	        "header": "Ltd Cost Per Hr Ltm",
	        "fieldName": "eq_stats__ltd_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];