/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import BusinessPartnersEditorWindow from '../../Window/BusinessPartnersEditorWindow.js';
import BusinessPartnersGridColumns from '../../Grid/Columns/BusinessPartnersGridColumns.js';

export default function BusinessPartnersGridEditorPanel(props) {
	return <GridPanel
				reference="BusinessPartnersGridEditorPanel"
				model="BusinessPartners"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={BusinessPartnersEditorWindow}
				columnsConfig={BusinessPartnersGridColumns}
				
				
				{...props}
			/>;
}