/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import EqValveTypesEditorWindow from '../../Window/EqValveTypesEditorWindow.js';
import EqValveTypesGridColumns from '../../Grid/Columns/EqValveTypesGridColumns.js';

export default function EqValveTypesGridEditorPanel(props) {
	return <GridPanel
				reference="EqValveTypesGridEditorPanel"
				model="EqValveTypes"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={EqValveTypesEditorWindow}
				columnsConfig={EqValveTypesGridColumns}
				
				
				{...props}
			/>;
}