/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WoLaborTypesEditorWindow from '../Window/WoLaborTypesEditorWindow.js';
import WoLaborTypesGridColumns from './Columns/WoLaborTypesGridColumns.js';

export default function WoLaborTypesGridEditor(props) {
	return <WindowedGridEditor
				reference="WoLaborTypesGridEditor"
				model="WoLaborTypes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={WoLaborTypesEditorWindow}
				columnsConfig={WoLaborTypesGridColumns}
				
				
				{...props}
			/>;
}