/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import PartsPartsEditor from '../../Editor/PartsPartsEditor.js';
import PartsPartsGridColumns from '../../Grid/Columns/PartsPartsGridColumns.js';

export default function PartsPartsSideGridEditorPanel(props) {
	return <GridPanel
				reference="PartsPartsSideGridEditorPanel"
				model="PartsParts"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={PartsPartsEditor}
				columnsConfig={PartsPartsGridColumns}
				
				
				{...props}
			/>;
}