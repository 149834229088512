/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import AddressesEditorWindow from '../../Window/AddressesEditorWindow.js';
import AddressesGridColumns from '../../Grid/Columns/AddressesGridColumns.js';

export default function AddressesGridEditorPanel(props) {
	return <GridPanel
				reference="AddressesGridEditorPanel"
				model="Addresses"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={AddressesEditorWindow}
				columnsConfig={AddressesGridColumns}
				
				
				{...props}
			/>;
}