export default [
	    {
	        "id": "parts_part_types__part_id",
	        "header": "Part",
	        "fieldName": "parts__part_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "parts_part_types__part_id"
	    },
	    {
	        "id": "parts_part_types__part_type_id",
	        "header": "Part Type",
	        "fieldName": "part_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "parts_part_types__part_type_id"
	    }
	];