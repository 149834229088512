/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PartsWorkOrdersSideGridEditor from './PartsWorkOrdersSideGridEditor.js';

export default function PartsWorkOrdersFilteredSideGridEditor(props) {
	return <PartsWorkOrdersSideGridEditor
				reference="PartsWorkOrdersFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}