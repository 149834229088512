/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function FleetStatsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "fleet_stats__is_stale"
		                    },
		                    {
		                        "name": "fleet_stats__path"
		                    },
		                    {
		                        "name": "fleet_stats__total_active_equipment"
		                    },
		                    {
		                        "name": "fleet_stats__total_inactive_equipment"
		                    },
		                    {
		                        "name": "fleet_stats__total_pm_equipment"
		                    },
		                    {
		                        "name": "fleet_stats__total_pm_will_call_equipment"
		                    },
		                    {
		                        "name": "fleet_stats__total_eq_onRent"
		                    },
		                    {
		                        "name": "fleet_stats__total_eq_coming_off_rent"
		                    },
		                    {
		                        "name": "fleet_stats__total_eq_overdue_rent"
		                    },
		                    {
		                        "name": "fleet_stats__mtbf"
		                    },
		                    {
		                        "name": "fleet_stats__mtbf_count"
		                    },
		                    {
		                        "name": "fleet_stats__latest_repair_date"
		                    },
		                    {
		                        "name": "fleet_stats__latest_invoice_date_rental"
		                    },
		                    {
		                        "name": "fleet_stats__due_pms"
		                    },
		                    {
		                        "name": "fleet_stats__overdue_pms"
		                    },
		                    {
		                        "name": "fleet_stats__overdue_pm_equipment"
		                    },
		                    {
		                        "name": "fleet_stats__pm_equipment_hy"
		                    },
		                    {
		                        "name": "fleet_stats__pm_equipment_ya"
		                    },
		                    {
		                        "name": "fleet_stats__pm_equipment_other"
		                    },
		                    {
		                        "name": "fleet_stats__latest_pm_date"
		                    },
		                    {
		                        "name": "fleet_stats__leases_active"
		                    },
		                    {
		                        "name": "fleet_stats__leases_extended"
		                    },
		                    {
		                        "name": "fleet_stats__leases_percent_extended"
		                    },
		                    {
		                        "name": "fleet_stats__lease_total_monthly_payment"
		                    },
		                    {
		                        "name": "fleet_stats__lease_avg_months_to_end"
		                    },
		                    {
		                        "name": "fleet_stats__lease_avg_term"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_hours"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_available_hours"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_util"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_preventive"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_abuse"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_abuse_rental"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_tires"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_repair"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_preventive_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_abuse_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_tires_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_repair_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_maint_combined"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_ltm_billing"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_lease"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_depreciation"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_cost_per_hr"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_cost_per_hr_customer"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_cost_per_hr_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__mtd_rental"
		                    },
		                    {
		                        "name": "fleet_stats__qtd_hours"
		                    },
		                    {
		                        "name": "fleet_stats__qtd_available_hours"
		                    },
		                    {
		                        "name": "fleet_stats__qtd_util"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_hours"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_available_hours"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_util"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_preventive"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_abuse"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_abuse_rental"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_tires"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_repair"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_preventive_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_abuse_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_tires_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_repair_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_maint_combined"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_ltm_billing"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_lease"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_depreciation"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_cost_per_hr"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_cost_per_hr_customer"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_cost_per_hr_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ytd_rental"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_hours"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_available_hours"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_util"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_preventive"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_abuse"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_abuse_rental"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_tires"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_repair"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_preventive_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_abuse_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_tires_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_repair_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_maint_combined"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_ltm_billing"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_lease"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_depreciation"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_cost_per_hr"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_cost_per_hr_customer"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_cost_per_hr_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__rtm_rental"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_hours"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_available_hours"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_util"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_preventive"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_abuse"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_abuse_rental"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_tires"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_repair"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_preventive_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_abuse_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_tires_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_repair_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_maint_combined"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_ltm_billing"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_lease"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_depreciation"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_cost_per_hr"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_cost_per_hr_customer"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_cost_per_hr_ltm"
		                    },
		                    {
		                        "name": "fleet_stats__ltd_rental"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="FleetStatsEditor"
				title="FleetStats"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

