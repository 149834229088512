/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import UtilProcessLogsEditorWindow from '../../../Window/UtilProcessLogsEditorWindow.js';

function UtilProcessLogsTagEditor(props) {
	return <TagEditor
				reference="UtilProcessLogsTagEditor"
				model="UtilProcessLogs"
				uniqueRepository={true}
				Editor={UtilProcessLogsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default UtilProcessLogsTagEditor;