/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilErrorLogsInlineGridEditor from './UtilErrorLogsInlineGridEditor.js';

export default function UtilErrorLogsFilteredInlineGridEditor(props) {
	return <UtilErrorLogsInlineGridEditor
				reference="UtilErrorLogsFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}