import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
// import PmEventsFilteredGridEditor from '../components/Grid/PmEventsFilteredGridEditor.js';
// import PmEventsFilteredSideGridEditor from '../components/Grid/PmEventsFilteredSideGridEditor.js';
import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';
// import UpcomingPmsGrid from '../components/Grid/UpcomingPmsGrid.js';
import Bell from '@onehat/ui/src/Components/Icons/Bell.js';
import ClockRegular from '@onehat/ui/src/Components/Icons/ClockRegular.js';
import _ from 'lodash';

export default function Setup(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'Setup';

	if (!isActive) {
		return null;
	}

	const defaultFilters = [
		'pm_events__equipment_id',
		'pm_events__date',
		'pm_events__pm_event_type_id',
	];
	return <TabBar
				tabs={[
					// {
					// 	title: 'Upcoming PMs',
					// 	_icon: { as: Bell },
					// 	content: <UpcomingPmsGrid reference="UpcomingPmsGrid" />,
					// },
					// {
					// 	title: 'PM Events',
					// 	_icon: { as: ClockRegular },
					// 	content: <ManagerScreen
					// 				title="PmEvents"
					// 				reference="Setup"
					// 				fullModeComponent={<PmEventsFilteredGridEditor
					// 										reference="pmEventsFilteredGridEditor"
					// 										defaultFilters={defaultFilters}
					// 									/>}
					// 				sideModeComponent={<PmEventsFilteredSideGridEditor
					// 										reference="pmEventsFilteredSideGridEditor"
					// 										defaultFilters={defaultFilters}
					// 									/>}
					// 			/>,
					// },
				]}
			/>;
}
