/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EnterpriseTypesGridEditorPanel from './EnterpriseTypesGridEditor.js';

export default function EnterpriseTypesFilteredGridEditorPanel(props) {
	return <EnterpriseTypesGridEditorPanel
				reference="EnterpriseTypesFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}