/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import UtilErrorCodesEditorWindow from '../../../Window/UtilErrorCodesEditorWindow.js';

function UtilErrorCodesTagEditor(props) {
	return <TagEditor
				reference="UtilErrorCodesTagEditor"
				model="UtilErrorCodes"
				uniqueRepository={true}
				Editor={UtilErrorCodesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default UtilErrorCodesTagEditor;