/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EquipmentPmSchedulesEditorWindowSideA from '../Window/EquipmentPmSchedulesEditorWindowSideA.js';
import EquipmentPmSchedulesGridColumnsSideA from './Columns/EquipmentPmSchedulesGridColumnsSideA.js';

export default function EquipmentPmSchedulesGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="EquipmentPmSchedulesGridEditorSideA"
				model="EquipmentPmSchedules"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EquipmentPmSchedulesEditorWindowSideA}
				columnsConfig={EquipmentPmSchedulesGridColumnsSideA}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}