/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import EqMonthlyStatsEditorWindow from '../../Window/EqMonthlyStatsEditorWindow.js';
import EqMonthlyStatsGridColumns from '../../Grid/Columns/EqMonthlyStatsGridColumns.js';

export default function EqMonthlyStatsGridEditorPanel(props) {
	return <GridPanel
				reference="EqMonthlyStatsGridEditorPanel"
				model="EqMonthlyStats"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={EqMonthlyStatsEditorWindow}
				columnsConfig={EqMonthlyStatsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}