/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import MhBranchesGridEditorPanel from './MhBranchesGridEditor.js';

export default function MhBranchesFilteredGridEditorPanel(props) {
	return <MhBranchesGridEditorPanel
				reference="MhBranchesFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}