/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import TelOperatorsEditorWindow from '../../../Window/TelOperatorsEditorWindow.js';

function TelOperatorsComboEditor(props) {
	return <ComboEditor
				reference="TelOperatorsComboEditor"
				model="TelOperators"
				uniqueRepository={true}
				Editor={TelOperatorsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default TelOperatorsComboEditor;