/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import EnterprisesEditor from '../../Editor/EnterprisesEditor.js';
import EnterprisesGridColumns from '../../Grid/Columns/EnterprisesGridColumns.js';

export default function EnterprisesSideGridEditorPanel(props) {
	return <GridPanel
				reference="EnterprisesSideGridEditorPanel"
				model="Enterprises"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={EnterprisesEditor}
				columnsConfig={EnterprisesGridColumns}
				
				disableAdd={true}
				{...props}
			/>;
}