/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WorkOrdersTagsEditorWindowSideA from '../Window/WorkOrdersTagsEditorWindowSideA.js';
import WorkOrdersTagsGridColumnsSideA from './Columns/WorkOrdersTagsGridColumnsSideA.js';

export default function WorkOrdersTagsGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="WorkOrdersTagsGridEditorSideA"
				model="WorkOrdersTags"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={WorkOrdersTagsEditorWindowSideA}
				columnsConfig={WorkOrdersTagsGridColumnsSideA}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}