/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PartsEditor from '../Editor/PartsEditor.js';
import PartsGridColumns from './Columns/PartsGridColumns.js';

export default function PartsSideGridEditor(props) {
	return <SideGridEditor
				reference="PartsSideGridEditor"
				model="Parts"
				usePermissions={true}
				isCollapsible={false}
				Editor={PartsEditor}
				columnsConfig={PartsGridColumns}
				
				
				{...props}
			/>;
}