/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqTypesEditorWindow from '../Window/EqTypesEditorWindow.js';
import EqTypesGridColumns from './Columns/EqTypesGridColumns.js';

export default function EqTypesGridEditor(props) {
	return <WindowedGridEditor
				reference="EqTypesGridEditor"
				model="EqTypes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EqTypesEditorWindow}
				columnsConfig={EqTypesGridColumns}
				
				disableDelete={true}
				{...props}
			/>;
}