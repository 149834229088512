/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EquipmentPmSchedulesEditorWindowSideB from '../Window/EquipmentPmSchedulesEditorWindowSideB.js';
import EquipmentPmSchedulesGridColumnsSideB from './Columns/EquipmentPmSchedulesGridColumnsSideB.js';

export default function EquipmentPmSchedulesGridEditorSideB(props) {
	return <WindowedGridEditor
				reference="EquipmentPmSchedulesGridEditorSideB"
				model="EquipmentPmSchedules"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EquipmentPmSchedulesEditorWindowSideB}
				columnsConfig={EquipmentPmSchedulesGridColumnsSideB}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}