/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import TelOperatorsSideGridEditorPanel from './TelOperatorsSideGridEditor.js';


export default function TelOperatorsFilteredSideGridEditorPanel(props) {
	return <TelOperatorsSideGridEditorPanel
				reference="TelOperatorsFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}