/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import TelUsagesGridEditor from './TelUsagesGridEditor.js';

export default function TelUsagesFilteredGridEditor(props) {
	return <TelUsagesGridEditor
				reference="TelUsagesFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}