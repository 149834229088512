/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PartsPartTypesEditor from '../Editor/PartsPartTypesEditor.js';
import PartsPartTypesGridColumns from './Columns/PartsPartTypesGridColumns.js';

export default function PartsPartTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="PartsPartTypesSideGridEditor"
				model="PartsPartTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={PartsPartTypesEditor}
				columnsConfig={PartsPartTypesGridColumns}
				
				
				{...props}
			/>;
}