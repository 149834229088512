/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import TagTypesEditor from '../Editor/TagTypesEditor.js';
import TagTypesGridColumns from './Columns/TagTypesGridColumns.js';

export default function TagTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="TagTypesSideGridEditor"
				model="TagTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={TagTypesEditor}
				columnsConfig={TagTypesGridColumns}
				
				
				{...props}
			/>;
}