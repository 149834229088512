/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilErrorCodesGridEditor from './UtilErrorCodesGridEditor.js';

export default function UtilErrorCodesFilteredGridEditor(props) {
	return <UtilErrorCodesGridEditor
				reference="UtilErrorCodesFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}