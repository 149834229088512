/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EnterprisesEditor from '../Editor/EnterprisesEditor.js';
import EnterprisesGridColumns from './Columns/EnterprisesGridColumns.js';

export default function EnterprisesSideGridEditor(props) {
	return <SideGridEditor
				reference="EnterprisesSideGridEditor"
				model="Enterprises"
				usePermissions={true}
				isCollapsible={false}
				Editor={EnterprisesEditor}
				columnsConfig={EnterprisesGridColumns}
				
				disableAdd={true}
				{...props}
			/>;
}