/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import BusinessPartnersFleetsSideGridEditorPanel from './BusinessPartnersFleetsSideGridEditor.js';


export default function BusinessPartnersFleetsFilteredSideGridEditorPanel(props) {
	return <BusinessPartnersFleetsSideGridEditorPanel
				reference="BusinessPartnersFleetsFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}