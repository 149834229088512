/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqMonthlyStatsGridEditorPanel from './EqMonthlyStatsGridEditor.js';

export default function EqMonthlyStatsFilteredGridEditorPanel(props) {
	return <EqMonthlyStatsGridEditorPanel
				reference="EqMonthlyStatsFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}