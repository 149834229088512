export default [
	    {
	        "id": "fleet_stats__is_stale",
	        "header": "Stale?",
	        "fieldName": "fleet_stats__is_stale",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__path",
	        "header": "Path",
	        "fieldName": "fleet_stats__path",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "fleet_stats__total_active_equipment",
	        "header": "Total Active Equipment",
	        "fieldName": "fleet_stats__total_active_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_inactive_equipment",
	        "header": "Total Inactive Equipment",
	        "fieldName": "fleet_stats__total_inactive_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_pm_equipment",
	        "header": "Total Pm Equipment",
	        "fieldName": "fleet_stats__total_pm_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_pm_will_call_equipment",
	        "header": "Total Pm Will Call Equipment",
	        "fieldName": "fleet_stats__total_pm_will_call_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_eq_onRent",
	        "header": "Total Eq OnRent",
	        "fieldName": "fleet_stats__total_eq_onRent",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_eq_coming_off_rent",
	        "header": "Total Eq Coming Off Rent",
	        "fieldName": "fleet_stats__total_eq_coming_off_rent",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_eq_overdue_rent",
	        "header": "Total Eq Overdue Rent",
	        "fieldName": "fleet_stats__total_eq_overdue_rent",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtbf",
	        "header": "Mtbf",
	        "fieldName": "fleet_stats__mtbf",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtbf_count",
	        "header": "Mtbf Count",
	        "fieldName": "fleet_stats__mtbf_count",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__latest_repair_date",
	        "header": "Latest Repair Date",
	        "fieldName": "fleet_stats__latest_repair_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__latest_invoice_date_rental",
	        "header": "Latest Invoice Date Rental",
	        "fieldName": "fleet_stats__latest_invoice_date_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__due_pms",
	        "header": "Due Pms",
	        "fieldName": "fleet_stats__due_pms",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__overdue_pms",
	        "header": "Overdue Pms",
	        "fieldName": "fleet_stats__overdue_pms",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__overdue_pm_equipment",
	        "header": "Overdue Pm Equipment",
	        "fieldName": "fleet_stats__overdue_pm_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__pm_equipment_hy",
	        "header": "PM Equipment Hy",
	        "fieldName": "fleet_stats__pm_equipment_hy",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__pm_equipment_ya",
	        "header": "PM Equipment Ya",
	        "fieldName": "fleet_stats__pm_equipment_ya",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__pm_equipment_other",
	        "header": "PM Equipment Other",
	        "fieldName": "fleet_stats__pm_equipment_other",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__latest_pm_date",
	        "header": "Latest Pm Date",
	        "fieldName": "fleet_stats__latest_pm_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__leases_active",
	        "header": "Leases Active",
	        "fieldName": "fleet_stats__leases_active",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__leases_extended",
	        "header": "Leases Extended",
	        "fieldName": "fleet_stats__leases_extended",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__leases_percent_extended",
	        "header": "Leases Percent Extended",
	        "fieldName": "fleet_stats__leases_percent_extended",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__lease_total_monthly_payment",
	        "header": "Lease Total Monthly Payment",
	        "fieldName": "fleet_stats__lease_total_monthly_payment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__lease_avg_months_to_end",
	        "header": "Lease Avg Months To End",
	        "fieldName": "fleet_stats__lease_avg_months_to_end",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__lease_avg_term",
	        "header": "Lease Avg Term",
	        "fieldName": "fleet_stats__lease_avg_term",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_hours",
	        "header": "Mtd Hours",
	        "fieldName": "fleet_stats__mtd_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_available_hours",
	        "header": "Mtd Available Hours",
	        "fieldName": "fleet_stats__mtd_available_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_util",
	        "header": "Mtd Util",
	        "fieldName": "fleet_stats__mtd_util",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint",
	        "header": "Mtd Maint",
	        "fieldName": "fleet_stats__mtd_maint",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_preventive",
	        "header": "Mtd Maint Preventive",
	        "fieldName": "fleet_stats__mtd_maint_preventive",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_abuse",
	        "header": "Mtd Maint Abuse",
	        "fieldName": "fleet_stats__mtd_maint_abuse",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_abuse_rental",
	        "header": "Mtd Maint Abuse Rental",
	        "fieldName": "fleet_stats__mtd_maint_abuse_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_tires",
	        "header": "Mtd Maint Tires",
	        "fieldName": "fleet_stats__mtd_maint_tires",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_repair",
	        "header": "Mtd Maint Repair",
	        "fieldName": "fleet_stats__mtd_maint_repair",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_ltm",
	        "header": "Mtd Maint Ltm",
	        "fieldName": "fleet_stats__mtd_maint_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_preventive_ltm",
	        "header": "Mtd Maint Preventive Ltm",
	        "fieldName": "fleet_stats__mtd_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_abuse_ltm",
	        "header": "Mtd Maint Abuse Ltm",
	        "fieldName": "fleet_stats__mtd_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_tires_ltm",
	        "header": "Mtd Maint Tires Ltm",
	        "fieldName": "fleet_stats__mtd_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_repair_ltm",
	        "header": "Mtd Maint Repair Ltm",
	        "fieldName": "fleet_stats__mtd_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_maint_combined",
	        "header": "Mtd Maint Combined",
	        "fieldName": "fleet_stats__mtd_maint_combined",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_ltm_billing",
	        "header": "Mtd Ltm Billing",
	        "fieldName": "fleet_stats__mtd_ltm_billing",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_lease",
	        "header": "Mtd Lease",
	        "fieldName": "fleet_stats__mtd_lease",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_depreciation",
	        "header": "Mtd Depreciation",
	        "fieldName": "fleet_stats__mtd_depreciation",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_cost_per_hr",
	        "header": "Mtd Cost Per Hr",
	        "fieldName": "fleet_stats__mtd_cost_per_hr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_cost_per_hr_customer",
	        "header": "Mtd Cost Per Hr Customer",
	        "fieldName": "fleet_stats__mtd_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_cost_per_hr_ltm",
	        "header": "Mtd Cost Per Hr Ltm",
	        "fieldName": "fleet_stats__mtd_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__mtd_rental",
	        "header": "Mtd Rental",
	        "fieldName": "fleet_stats__mtd_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__qtd_hours",
	        "header": "Qtd Hours",
	        "fieldName": "fleet_stats__qtd_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__qtd_available_hours",
	        "header": "Qtd Available Hours",
	        "fieldName": "fleet_stats__qtd_available_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__qtd_util",
	        "header": "Qtd Util",
	        "fieldName": "fleet_stats__qtd_util",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_hours",
	        "header": "Ytd Hours",
	        "fieldName": "fleet_stats__ytd_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_available_hours",
	        "header": "Ytd Available Hours",
	        "fieldName": "fleet_stats__ytd_available_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_util",
	        "header": "Ytd Util",
	        "fieldName": "fleet_stats__ytd_util",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint",
	        "header": "Ytd Maint",
	        "fieldName": "fleet_stats__ytd_maint",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_preventive",
	        "header": "Ytd Maint Preventive",
	        "fieldName": "fleet_stats__ytd_maint_preventive",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_abuse",
	        "header": "Ytd Maint Abuse",
	        "fieldName": "fleet_stats__ytd_maint_abuse",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_abuse_rental",
	        "header": "Ytd Maint Abuse Rental",
	        "fieldName": "fleet_stats__ytd_maint_abuse_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_tires",
	        "header": "Ytd Maint Tires",
	        "fieldName": "fleet_stats__ytd_maint_tires",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_repair",
	        "header": "Ytd Maint Repair",
	        "fieldName": "fleet_stats__ytd_maint_repair",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_ltm",
	        "header": "Ytd Maint Ltm",
	        "fieldName": "fleet_stats__ytd_maint_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_preventive_ltm",
	        "header": "Ytd Maint Preventive Ltm",
	        "fieldName": "fleet_stats__ytd_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_abuse_ltm",
	        "header": "Ytd Maint Abuse Ltm",
	        "fieldName": "fleet_stats__ytd_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_tires_ltm",
	        "header": "Ytd Maint Tires Ltm",
	        "fieldName": "fleet_stats__ytd_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_repair_ltm",
	        "header": "Ytd Maint Repair Ltm",
	        "fieldName": "fleet_stats__ytd_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_maint_combined",
	        "header": "Ytd Maint Combined",
	        "fieldName": "fleet_stats__ytd_maint_combined",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_ltm_billing",
	        "header": "Ytd Ltm Billing",
	        "fieldName": "fleet_stats__ytd_ltm_billing",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_lease",
	        "header": "Ytd Lease",
	        "fieldName": "fleet_stats__ytd_lease",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_depreciation",
	        "header": "Ytd Depreciation",
	        "fieldName": "fleet_stats__ytd_depreciation",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_cost_per_hr",
	        "header": "Ytd Cost Per Hr",
	        "fieldName": "fleet_stats__ytd_cost_per_hr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_cost_per_hr_customer",
	        "header": "Ytd Cost Per Hr Customer",
	        "fieldName": "fleet_stats__ytd_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_cost_per_hr_ltm",
	        "header": "Ytd Cost Per Hr Ltm",
	        "fieldName": "fleet_stats__ytd_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ytd_rental",
	        "header": "Ytd Rental",
	        "fieldName": "fleet_stats__ytd_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_hours",
	        "header": "Rtm Hours",
	        "fieldName": "fleet_stats__rtm_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_available_hours",
	        "header": "Rtm Available Hours",
	        "fieldName": "fleet_stats__rtm_available_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_util",
	        "header": "Rtm Util",
	        "fieldName": "fleet_stats__rtm_util",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint",
	        "header": "Rtm Maint",
	        "fieldName": "fleet_stats__rtm_maint",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_preventive",
	        "header": "Rtm Maint Preventive",
	        "fieldName": "fleet_stats__rtm_maint_preventive",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_abuse",
	        "header": "Rtm Maint Abuse",
	        "fieldName": "fleet_stats__rtm_maint_abuse",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_abuse_rental",
	        "header": "Rtm Maint Abuse Rental",
	        "fieldName": "fleet_stats__rtm_maint_abuse_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_tires",
	        "header": "Rtm Maint Tires",
	        "fieldName": "fleet_stats__rtm_maint_tires",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_repair",
	        "header": "Rtm Maint Repair",
	        "fieldName": "fleet_stats__rtm_maint_repair",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_ltm",
	        "header": "Rtm Maint Ltm",
	        "fieldName": "fleet_stats__rtm_maint_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_preventive_ltm",
	        "header": "Rtm Maint Preventive Ltm",
	        "fieldName": "fleet_stats__rtm_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_abuse_ltm",
	        "header": "Rtm Maint Abuse Ltm",
	        "fieldName": "fleet_stats__rtm_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_tires_ltm",
	        "header": "Rtm Maint Tires Ltm",
	        "fieldName": "fleet_stats__rtm_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_repair_ltm",
	        "header": "Rtm Maint Repair Ltm",
	        "fieldName": "fleet_stats__rtm_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_maint_combined",
	        "header": "Rtm Maint Combined",
	        "fieldName": "fleet_stats__rtm_maint_combined",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_ltm_billing",
	        "header": "Rtm Ltm Billing",
	        "fieldName": "fleet_stats__rtm_ltm_billing",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_lease",
	        "header": "Rtm Lease",
	        "fieldName": "fleet_stats__rtm_lease",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_depreciation",
	        "header": "Rtm Depreciation",
	        "fieldName": "fleet_stats__rtm_depreciation",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_cost_per_hr",
	        "header": "Rtm Cost Per Hr",
	        "fieldName": "fleet_stats__rtm_cost_per_hr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_cost_per_hr_customer",
	        "header": "Rtm Cost Per Hr Customer",
	        "fieldName": "fleet_stats__rtm_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_cost_per_hr_ltm",
	        "header": "Rtm Cost Per Hr Ltm",
	        "fieldName": "fleet_stats__rtm_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__rtm_rental",
	        "header": "Rtm Rental",
	        "fieldName": "fleet_stats__rtm_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_hours",
	        "header": "Ltd Hours",
	        "fieldName": "fleet_stats__ltd_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_available_hours",
	        "header": "Ltd Available Hours",
	        "fieldName": "fleet_stats__ltd_available_hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_util",
	        "header": "Ltd Util",
	        "fieldName": "fleet_stats__ltd_util",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint",
	        "header": "Ltd Maint",
	        "fieldName": "fleet_stats__ltd_maint",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_preventive",
	        "header": "Ltd Maint Preventive",
	        "fieldName": "fleet_stats__ltd_maint_preventive",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_abuse",
	        "header": "Ltd Maint Abuse",
	        "fieldName": "fleet_stats__ltd_maint_abuse",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_abuse_rental",
	        "header": "Ltd Maint Abuse Rental",
	        "fieldName": "fleet_stats__ltd_maint_abuse_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_tires",
	        "header": "Ltd Maint Tires",
	        "fieldName": "fleet_stats__ltd_maint_tires",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_repair",
	        "header": "Ltd Maint Repair",
	        "fieldName": "fleet_stats__ltd_maint_repair",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_ltm",
	        "header": "Ltd Maint Ltm",
	        "fieldName": "fleet_stats__ltd_maint_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_preventive_ltm",
	        "header": "Ltd Maint Preventive Ltm",
	        "fieldName": "fleet_stats__ltd_maint_preventive_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_abuse_ltm",
	        "header": "Ltd Maint Abuse Ltm",
	        "fieldName": "fleet_stats__ltd_maint_abuse_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_tires_ltm",
	        "header": "Ltd Maint Tires Ltm",
	        "fieldName": "fleet_stats__ltd_maint_tires_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_repair_ltm",
	        "header": "Ltd Maint Repair Ltm",
	        "fieldName": "fleet_stats__ltd_maint_repair_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_maint_combined",
	        "header": "Ltd Maint Combined",
	        "fieldName": "fleet_stats__ltd_maint_combined",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_ltm_billing",
	        "header": "Ltd Ltm Billing",
	        "fieldName": "fleet_stats__ltd_ltm_billing",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_lease",
	        "header": "Ltd Lease",
	        "fieldName": "fleet_stats__ltd_lease",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_depreciation",
	        "header": "Ltd Depreciation",
	        "fieldName": "fleet_stats__ltd_depreciation",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_cost_per_hr",
	        "header": "Ltd Cost Per Hr",
	        "fieldName": "fleet_stats__ltd_cost_per_hr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_cost_per_hr_customer",
	        "header": "Ltd Cost Per Hr Customer",
	        "fieldName": "fleet_stats__ltd_cost_per_hr_customer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_cost_per_hr_ltm",
	        "header": "Ltd Cost Per Hr Ltm",
	        "fieldName": "fleet_stats__ltd_cost_per_hr_ltm",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__ltd_rental",
	        "header": "Ltd Rental",
	        "fieldName": "fleet_stats__ltd_rental",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];