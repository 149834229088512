/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import PartTypesGridColumns from '../../Grid/Columns/PartTypesGridColumns.js';

export default function PartTypesGridPanel(props) {
	return <GridPanel
				reference="PartTypesGridPanel"
				model="PartTypes"
				usePermissions={true}
				columnsConfig={PartTypesGridColumns}

				{...props}
			/>;
}