/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import WorkOrdersTagsEditorWindow from '../../Window/WorkOrdersTagsEditorWindow.js';
import WorkOrdersTagsGridColumns from '../../Grid/Columns/WorkOrdersTagsGridColumns.js';

export default function WorkOrdersTagsGridEditorPanel(props) {
	return <GridPanel
				reference="WorkOrdersTagsGridEditorPanel"
				model="WorkOrdersTags"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={WorkOrdersTagsEditorWindow}
				columnsConfig={WorkOrdersTagsGridColumns}
				
				
				{...props}
			/>;
}