import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import FleetsScreen from '../components/Screens/FleetsScreen.js';
import EquipmentFilteredGridEditor from '../components/Grid/EquipmentFilteredGridEditor.js';
import EquipmentFilteredSideGridEditor from '../components/Grid/EquipmentFilteredSideGridEditor.js';
import _ from 'lodash';

export default function EquipmentManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'EquipmentManager';

	if (!isActive) {
		return null;
	}

	return <FleetsScreen
				title="Equipment"
				reference="EquipmentManager"
				fullModeComponent={<EquipmentFilteredGridEditor
										reference="EquipmentFilteredGridEditor"
										useRemoteDuplicate={true}
									/>}
				sideModeComponent={<EquipmentFilteredSideGridEditor
										reference="EquipmentFilteredGridEditor"
										useRemoteDuplicate={true}
									/>}
			/>;
}
