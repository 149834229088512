/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import UtilErrorLogsEditorWindow from '../../Window/UtilErrorLogsEditorWindow.js';
import UtilErrorLogsGridColumns from '../../Grid/Columns/UtilErrorLogsGridColumns.js';

export default function UtilErrorLogsGridEditorPanel(props) {
	return <GridPanel
				reference="UtilErrorLogsGridEditorPanel"
				model="UtilErrorLogs"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={UtilErrorLogsEditorWindow}
				columnsConfig={UtilErrorLogsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}