/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EqForkLengthsEditorWindow from '../../../Window/EqForkLengthsEditorWindow.js';

function EqForkLengthsTagEditor(props) {
	return <TagEditor
				reference="EqForkLengthsTagEditor"
				model="EqForkLengths"
				uniqueRepository={true}
				Editor={EqForkLengthsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EqForkLengthsTagEditor;