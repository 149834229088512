/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqUprightTypesEditorWindow from '../Window/EqUprightTypesEditorWindow.js';
import EqUprightTypesGridColumns from './Columns/EqUprightTypesGridColumns.js';

export default function EqUprightTypesGridEditor(props) {
	return <WindowedGridEditor
				reference="EqUprightTypesGridEditor"
				model="EqUprightTypes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EqUprightTypesEditorWindow}
				columnsConfig={EqUprightTypesGridColumns}
				
				
				{...props}
			/>;
}