/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { useState, useEffect, } from 'react';
import {
	Column,
	Text,
} from 'native-base';
import Tree from '@onehat/ui/src/Components/Tree/Tree.js';
import oneHatData from '@onehat/data';
import getNodeIcon from '../../functions/getNodeIcon.js';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectEnterpriseId,
	selectTreeSelection,
	setTreeSelection,
} from '../../models/Slices/AppSlice';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import getDisplayTextFromFleetTreeSearchResults from '../../functions/getDisplayTextFromFleetTreeSearchResults.js';
import Loading from '@onehat/ui/src/Components/Messages/Loading.js';


function FleetLitesTree(props) {
	const {
			self,
		} = props,
		enterpriseId = useSelector(selectEnterpriseId),
		treeSelection = useSelector(selectTreeSelection),
		dispatch = useDispatch(),
		[isReady, setIsReady] = useState(false),
		FleetLites = oneHatData.getRepository('FleetLites'),
		onChangeSelection = (selection) => {
			dispatch(setTreeSelection(selection));
		},
		clearTreeSelection = () => {
			dispatch(setTreeSelection([]));
		},
		onTreeLoad = () => {
			const rootNode = FleetLites.getRootNodes()[0];
			if (rootNode) {
				self.setSelection([rootNode]);
			}
		};

	useEffect(() => {
		
		if (!enterpriseId) {
			return;
		}

		FleetLites.setBaseParam('conditions[fleets__enterprise_id]', enterpriseId);
		if (FleetLites.areRootNodesLoaded) {
			FleetLites.loadRootNodes(1);
		}
		clearTreeSelection();
	
		setIsReady(true);

	}, [enterpriseId]);
	
	if (!enterpriseId) {
		return <Column flex={1} w="100%" alignItems="center" justifyContent="center">
					<Text>No enterprise selected</Text>
				</Column>;
	}

	if (!isReady) {
		return <Loading />;
	}

	return <Tree
				parent={self}
				reference="Tree"
				disableWithData={true}
				Repository={FleetLites}

				autoLoadRootNodes={false}
				getNodeIcon={getNodeIcon}
				onChangeSelection={onChangeSelection}
				onTreeLoad={onTreeLoad}
				getDisplayTextFromSearchResults={getDisplayTextFromFleetTreeSearchResults}
				
				{...props}
			/>;
}


function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="FleetLitesTree"
					{...props}
				/>;
	};
}

export default withAdditionalProps(withComponent(FleetLitesTree));