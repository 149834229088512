/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import TagTypesGridColumns from '../../Grid/Columns/TagTypesGridColumns.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function TagTypesInlineGridEditorPanel(props) {
	return <GridPanel
				reference="TagTypesInlineGridEditorPanel"
				model="TagTypes"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={TagTypesGridColumns}
				
				
				{...props}
			/>;
}