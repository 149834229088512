/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import ManufacturersGridColumns from '../../Grid/Columns/ManufacturersGridColumns.js';

export default function ManufacturersGridPanel(props) {
	return <GridPanel
				reference="ManufacturersGridPanel"
				model="Manufacturers"
				usePermissions={true}
				columnsConfig={ManufacturersGridColumns}

				{...props}
			/>;
}