// This file needs to exist, even if it's blank.
// Override the default theme by creating constants below.

// export const PANEL_HEADER_BG = {
// 	linearGradient:{
// 		colors: ['#E31E24', '#B62322', '#962B24'],
// 		start: [0, 0],
// 		end: [1, 0],
// 	},
// };

// To get linear gradients working, check out these links:
// https://i.stack.imgur.com/O4cdE.png
// https://docs.nativebase.io/box (three different sections = Expo, RN, and web)


const
	DEFAULT_FONTSIZE = 11; // 15

export default {
	
	PANEL_FOOTER_BG: '#e0e0e0', // :alpha.50
	PANEL_HEADER_BG: '#e0e0e0',
	PANEL_HEADER_BG_VERTICAL: '#e0e0e0',
	PANEL_HEADER_ICON_COLOR: '#000',
	PANEL_HEADER_TEXT_COLOR: '#000',

	TAB_ACTIVE_BG: '#fff',
	TAB_ACTIVE_HOVER_BG: 'trueGray.400',
	TAB_ACTIVE_COLOR: '#000',
	TAB_ICON_COLOR: '#000',
	TAB_ACTIVE_ICON_COLOR: '#000',
	TAB_BAR_BG: 'trueGray.300',
	TAB_BG: 'trueGray.300',
	TAB_HOVER_BG: 'trueGray.400',
	TAB_COLOR: '#000',

	MANAGER_SCREEN_TITLE: 'OpenSansCondensed_700Bold',

	DEFAULT_WINDOW_WIDTH: 1000,


};