/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PartsWorkOrdersEditor from '../Editor/PartsWorkOrdersEditor.js';
import PartsWorkOrdersGridColumns from './Columns/PartsWorkOrdersGridColumns.js';

export default function PartsWorkOrdersSideGridEditor(props) {
	return <SideGridEditor
				reference="PartsWorkOrdersSideGridEditor"
				model="PartsWorkOrders"
				usePermissions={true}
				isCollapsible={false}
				Editor={PartsWorkOrdersEditor}
				columnsConfig={PartsWorkOrdersGridColumns}
				
				
				{...props}
			/>;
}