/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqStatsGridEditorPanel from './EqStatsGridEditor.js';

export default function EqStatsFilteredGridEditorPanel(props) {
	return <EqStatsGridEditorPanel
				reference="EqStatsFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}