/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import BusinessPartnersEditorWindow from '../Window/BusinessPartnersEditorWindow.js';
import BusinessPartnersGridColumns from './Columns/BusinessPartnersGridColumns.js';

export default function BusinessPartnersGridEditor(props) {
	return <WindowedGridEditor
				reference="BusinessPartnersGridEditor"
				model="BusinessPartners"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={BusinessPartnersEditorWindow}
				columnsConfig={BusinessPartnersGridColumns}
				
				
				{...props}
			/>;
}