/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WoPrioritiesEditorWindow from '../Window/WoPrioritiesEditorWindow.js';
import WoPrioritiesGridColumns from './Columns/WoPrioritiesGridColumns.js';

export default function WoPrioritiesGridEditor(props) {
	return <WindowedGridEditor
				reference="WoPrioritiesGridEditor"
				model="WoPriorities"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={WoPrioritiesEditorWindow}
				columnsConfig={WoPrioritiesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}