/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import ChromesGridColumns from '../../Grid/Columns/ChromesGridColumns.js';

export default function ChromesGridPanel(props) {
	return <GridPanel
				reference="ChromesGridPanel"
				model="Chromes"
				usePermissions={true}
				columnsConfig={ChromesGridColumns}

				{...props}
			/>;
}