/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqVoltagesInlineGridEditorPanel from './EqVoltagesInlineGridEditor.js';

export default function EqVoltagesFilteredInlineGridEditorPanel(props) {
	return <EqVoltagesInlineGridEditorPanel
				reference="EqVoltagesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}