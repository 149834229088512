/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import WoClassesEditorWindow from '../../../Window/WoClassesEditorWindow.js';

function WoClassesTagEditor(props) {
	return <TagEditor
				reference="WoClassesTagEditor"
				model="WoClasses"
				uniqueRepository={true}
				Editor={WoClassesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default WoClassesTagEditor;