/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import WoOtherChargesEditorWindow from '../../../Window/WoOtherChargesEditorWindow.js';

function WoOtherChargesTag(props) {
	return <Tag
				reference="WoOtherChargesTag"
				model="WoOtherCharges"
				uniqueRepository={true}
				Editor={WoOtherChargesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default WoOtherChargesTag;