export default [
	    {
	        "id": "business_partners_fleets__fleet_id",
	        "header": "Fleet",
	        "fieldName": "fleets__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "business_partners_fleets__fleet_id",
	        "flex": 1,
	        "minWidth": 150
	    }
	];