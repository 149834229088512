/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ManufacturersInlineGridEditor from './ManufacturersInlineGridEditor.js';

export default function ManufacturersFilteredInlineGridEditor(props) {
	return <ManufacturersInlineGridEditor
				reference="ManufacturersFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}