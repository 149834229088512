/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import UtilErrorCodesEditorWindow from '../../../Window/UtilErrorCodesEditorWindow.js';

function UtilErrorCodesComboEditor(props) {
	return <ComboEditor
				reference="UtilErrorCodesComboEditor"
				model="UtilErrorCodes"
				uniqueRepository={true}
				Editor={UtilErrorCodesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default UtilErrorCodesComboEditor;