/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EnterprisesSideGridEditor from './EnterprisesSideGridEditor.js';

export default function EnterprisesFilteredSideGridEditor(props) {
	return <EnterprisesSideGridEditor
				reference="EnterprisesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}