import { useState, } from 'react';
import {
	Column,
	Icon,
	Image,
	Pressable,
	Row,
	Text,
} from 'native-base';
import {
	VIEW,
} from '@onehat/ui/src/Constants/Commands.js';
import withPermissions from '@onehat/ui/src/Components/Hoc/withPermissions';
import testProps from '@onehat/ui/src/Functions/testProps.js';
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import chunkArray from '../functions/chunkArray';
import Book from '@onehat/ui/src/Components/Icons/Book';
import Building from '@onehat/ui/src/Components/Icons/Building';
import Clipboard from '@onehat/ui/src/Components/Icons/Clipboard';
import FolderTree from '@onehat/ui/src/Components/Icons/FolderTree';
import Gears from '@onehat/ui/src/Components/Icons/Gears';
import OilCan from '../components/Icons/OilCan';
import Forklift from '../components/Icons/Forklift';
import Dots from '../components/Icons/Dots';
import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';
import appPng from '../components/Images/MhPmsLogo.png';
import Inflector from 'inflector-js';
import AppGlobals from '../AppGlobals';


function Box(props) {
	const {
			url,
			text,
			icon,
			onNavigate,
		} = props,
		[containerWidth, setContainerWidth] = useState(100),
		[isRendered, setIsRendered] = useState(false),
		onPress = () => {
			onNavigate(url);
		},
		onLayout= (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		};

	let fontSize = '15px',
		iconSize = '40px';
	if (containerWidth > 200) {
		fontSize = '25px';
		iconSize = '55px';
	}
	if (containerWidth > 300) {
		fontSize = '25px';
		iconSize = '55px';
	}
	if (containerWidth > 400) {
		fontSize = '35px';
		iconSize = '65px';
	}
	if (containerWidth > 500) {
		fontSize = '25px';
		iconSize = '65px';
	}

	return <Pressable
				{...testProps(url)}
				onLayout={onLayout} 
				onPress={onPress} 
				flex={1}
				_hover={{ bg: "primary.300" }}
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				bg="primary.700"
				p={5}
				mx={2}
				borderRadius={10}
			>
				{isRendered &&
					<>
						<Icon
							as={icon}
							color="#fff"
							size={iconSize}
							minHeight={5}
							mb={2}
						/>
						<Text
							fontSize={fontSize}
							lineHeight={fontSize}
							style={{
								fontFamily: 'OpenSansCondensed_700Bold',
							}}
							color="#fff"
							textAlign="center"
						>{text}</Text>
					</>}
			</Pressable>;
}

function Home(props) {
	const {
			tabs,
			onChangeCurrentTab,

			// withPermissions
			canUser,
		} = props,
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'Home',
		[containerWidth, setContainerWidth] = useState(500),
		[isRendered, setIsRendered] = useState(false),
		onNavigate = (path) => {
			const 
				whichTab = _.find(tabs, (tab) => {
					return tab.path === path;
				}),
				whichTabIx = whichTab ? tabs.indexOf(whichTab) : 0;
			onChangeCurrentTab(whichTabIx);
		},
		onLayout= (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		};

	if (!isActive) {
		return null;
	}

	const
		urlPrefix = '/' + AppGlobals.urlPrefix,
		rows = [],
		items = [];

	if (canUser(VIEW, 'Enterprises')) {
		items.push(<Box
			text="Enterprises"
			key="Enterprises"
			icon={Building}
			url={urlPrefix + Inflector.dasherize(Inflector.underscore('Enterprises'))}
			onNavigate={onNavigate}
		/>);
	}
	if (canUser(VIEW, 'Fleets')) {
		items.push(<Box
			text="Fleets"
			key="Fleets"
			icon={FolderTree}
			url={urlPrefix + Inflector.dasherize(Inflector.underscore('Fleets'))}
			onNavigate={onNavigate}
		/>);
	}
	if (canUser(VIEW, 'Equipment')) {
		items.push(<Box
			text="Equipment"
			key="Equipment"
			icon={Forklift}
			url={urlPrefix + Inflector.dasherize(Inflector.underscore('Equipment'))}
			onNavigate={onNavigate}
		/>);
	}
	if (canUser(VIEW, 'WorkOrders')) {
		items.push(<Box
			text="Work Orders"
			key="WorkOrders"
			icon={Clipboard}
			url={urlPrefix + Inflector.dasherize(Inflector.underscore('WorkOrders'))}
			onNavigate={onNavigate}
		/>);
	}
	if (canUser(VIEW, 'PmEvents')) {
		items.push(<Box
			text="Upcoming PMs"
			key="UpcomingPms"
			icon={OilCan}
			url={urlPrefix + Inflector.dasherize(Inflector.underscore('Pms'))}
			onNavigate={onNavigate}
		/>);
	}
	// if (canUser('view_reports')) {
	// 	items.push(<Box
	// 		text="Reports"
	// 		key="Reports"
	// 		icon={ChartPie}
	// 		url={urlPrefix + Inflector.dasherize(Inflector.underscore('Reports'))}
	// 		onNavigate={onNavigate}
	// 	/>);
	// }
	if (canUser(VIEW, 'Users')) {
		items.push(<Box
			text="Users"
			key="Users"
			icon={User}
			url={urlPrefix + Inflector.dasherize(Inflector.underscore('Users'))}
			onNavigate={onNavigate}
		/>);
	}
	if (canUser(VIEW, 'Groups')) {
		items.push(<Box
			text="Groups"
			key="Groups"
			icon={UserGroup}
			url={urlPrefix + Inflector.dasherize(Inflector.underscore('Groups'))}
			onNavigate={onNavigate}
		/>);
	}
	if (canUser('access_audit_app')) {
		items.push(<Box
			text="Logs"
			key="Logs"
			icon={Book}
			url={urlPrefix + Inflector.dasherize(Inflector.underscore('Logs'))}
			onNavigate={onNavigate}
		/>);
	}
	items.push(<Box
		text="Logout"
		key="Logout"
		icon={RightFromBracket}
		url={urlPrefix + Inflector.dasherize(Inflector.underscore('Logout'))}
		onNavigate={onNavigate}
	/>);
	
	let itemsPerRow = 1;
	if (containerWidth > 400) {
		itemsPerRow = 2;
	}
	if (containerWidth > 600) {
		itemsPerRow = 3;
	}
	if (containerWidth > 800) {
		itemsPerRow = 4;
	}

	const chunks = chunkArray(items, itemsPerRow);
	_.each(chunks, (items, ix) => {
		rows.push(<Row key={'row' + ix} flex={1} justifyContent="space-between" mb={3}>{items}</Row>);
	});
	
	return <Column flex={1} w="100%" p={10} onLayout={onLayout} >
				{isRendered && 
					<>
						<Column flex={3} pb={5}>
							<Image
								source={appPng}
								margin="auto"
								resizeMode="contain"
								alt="App Logo"
								flex={1}
								w="100%"
							/>
						</Column>
						<Column flex={5}>
							{rows}
						</Column>
					</>}
			</Column>;

}

export default withPermissions(Home, true);