/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import PmStatusesGridColumns from './Columns/PmStatusesGridColumns.js';

export default function PmStatusesGrid(props) {
	return <Grid
				reference="PmStatusesGrid"
				model="PmStatuses"
				usePermissions={true}
				columnsConfig={PmStatusesGridColumns}

				{...props}
			/>;
}