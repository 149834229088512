/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqForkLengthsEditor from '../Editor/EqForkLengthsEditor.js';
import EqForkLengthsGridColumns from './Columns/EqForkLengthsGridColumns.js';

export default function EqForkLengthsSideGridEditor(props) {
	return <SideGridEditor
				reference="EqForkLengthsSideGridEditor"
				model="EqForkLengths"
				usePermissions={true}
				isCollapsible={false}
				Editor={EqForkLengthsEditor}
				columnsConfig={EqForkLengthsGridColumns}
				
				
				{...props}
			/>;
}