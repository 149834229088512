/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PartsPartsGridPanel from './PartsPartsGrid.js';

export default function PartsPartsFilteredGridPanel(props) {
	return <PartsPartsGridPanel
				reference="PartsPartsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}