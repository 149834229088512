/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoModesSideGridEditorPanel from './WoModesSideGridEditor.js';


export default function WoModesFilteredSideGridEditorPanel(props) {
	return <WoModesSideGridEditorPanel
				reference="WoModesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}