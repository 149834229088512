/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PmSchedulesGrid from './PmSchedulesGrid.js';

export default function PmSchedulesFilteredGrid(props) {
	return <PmSchedulesGrid
				reference="PmSchedulesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}