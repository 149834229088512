/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqValveTypesInlineGridEditorPanel from './EqValveTypesInlineGridEditor.js';

export default function EqValveTypesFilteredInlineGridEditorPanel(props) {
	return <EqValveTypesInlineGridEditorPanel
				reference="EqValveTypesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}