/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PartsWorkOrdersInlineGridEditor from './PartsWorkOrdersInlineGridEditor.js';

export default function PartsWorkOrdersFilteredInlineGridEditor(props) {
	return <PartsWorkOrdersInlineGridEditor
				reference="PartsWorkOrdersFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}