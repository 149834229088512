import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import WorkOrdersFilteredGridEditor from '../components/Grid/WorkOrdersFilteredGridEditor.js';
import WorkOrdersFilteredSideGridEditor from '../components/Grid/WorkOrdersFilteredSideGridEditor.js';

export default function WorkOrdersManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'WorkOrdersManager';

	if (!isActive) {
		return null;
	}

	const defaultFilters = [
		// 'equipment',
		// 'work_orders__wo_class_id',
		// 'work_orders__open_date',
		// 'work_orders__customer_id',
	];
	return <ManagerScreen
				title="WorkOrders"
				reference="WorkOrdersManager"
				fullModeComponent={<WorkOrdersFilteredGridEditor
										reference="WorkOrdersFilteredGridEditor"
										useRemoteDuplicate={true}
										defaultFilters={defaultFilters}
										baseParams={{
											'conditions[work_orders__is_pm_template]': false,
										}}
									/>}
				sideModeComponent={<WorkOrdersFilteredSideGridEditor
										reference="WorkOrdersFilteredGridEditor"
										useRemoteDuplicate={true}
										defaultFilters={defaultFilters}
										baseParams={{
											'conditions[work_orders__is_pm_template]': false,
										}}
									/>}
			/>;
}
