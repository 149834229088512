/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import EqValveTypesEditor from '../../Editor/EqValveTypesEditor.js';
import EqValveTypesGridColumns from '../../Grid/Columns/EqValveTypesGridColumns.js';

export default function EqValveTypesSideGridEditorPanel(props) {
	return <GridPanel
				reference="EqValveTypesSideGridEditorPanel"
				model="EqValveTypes"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={EqValveTypesEditor}
				columnsConfig={EqValveTypesGridColumns}
				
				
				{...props}
			/>;
}