/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChromesGrid from './ChromesGrid.js';

export default function ChromesFilteredGrid(props) {
	return <ChromesGrid
				reference="ChromesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}