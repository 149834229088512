/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import WorkOrdersTagsEditor from '../../Editor/WorkOrdersTagsEditor.js';
import WorkOrdersTagsGridColumns from '../../Grid/Columns/WorkOrdersTagsGridColumns.js';

export default function WorkOrdersTagsSideGridEditorPanel(props) {
	return <GridPanel
				reference="WorkOrdersTagsSideGridEditorPanel"
				model="WorkOrdersTags"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={WorkOrdersTagsEditor}
				columnsConfig={WorkOrdersTagsGridColumns}
				
				
				{...props}
			/>;
}