import * as yup from 'yup'; // https://github.com/jquense/yup#string

const EquipmentTags = {
	
	name: 'EquipmentTags',
	
	model: {

		idProperty: 'equipment_tags__id',
		displayProperty: 'equipment_tags__id',
		sortProperty: 'equipment_tags__id',
		
		sorters: null,

		validator: yup.object({
			equipment_tags__equipment_id: yup.number().integer().required(),
			equipment_tags__tag_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'equipment_tags__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'equipment_tags__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__unit_number', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, editorType: {"type":"EquipmentComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'equipment_tags__tag_id', mapping: 'tag_id', title: 'Tag', type: 'int', isFk: true, fkIdField: 'tags__id', fkDisplayField: 'tags__name', filterType: {"type":"TagsCombo","loadAfterRender":!1}, editorType: {"type":"TagsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__enterprise_id', mapping: 'equipment.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__fleet_id', mapping: 'equipment.fleet_id', title: 'Fleet', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__manufacturer_id', mapping: 'equipment.manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial_number', mapping: 'equipment.serial_number', title: 'Serial #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__unit_number', mapping: 'equipment.unit_number', title: 'Unit #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__asset_number', mapping: 'equipment.asset_number', title: 'Asset # (Cust ID)', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__dept_number', mapping: 'equipment.dept_number', title: 'Dept #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__business_partner_id', mapping: 'equipment.business_partner_id', title: 'Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_manufacturer_id', mapping: 'equipment.tel_manufacturer_id', title: 'Tel Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_pid', mapping: 'equipment.tel_pid', title: 'Tel Pid', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_misc', mapping: 'equipment.is_misc', title: 'Misc?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_maint_expense', mapping: 'equipment.initial_maint_expense', title: 'Initial Maint $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_book_value', mapping: 'equipment.initial_book_value', title: 'Book Value $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__purchase_price', mapping: 'equipment.purchase_price', title: 'Purchase Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_expires', mapping: 'equipment.warranty_expires', title: 'Warranty Expires', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_hours', mapping: 'equipment.warranty_hours', title: 'Warranty Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'Pm Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_hours_per_month', mapping: 'equipment.expected_hours_per_month', title: 'Expected Hours Per Month', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_hours_per_week', mapping: 'equipment.available_hours_per_week', title: 'Available Hours Per Week', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_track_hours_enable', mapping: 'equipment.is_track_hours_enable', title: 'Track Hours Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__max_hours_allowed', mapping: 'equipment.max_hours_allowed', title: 'Max Hours Allowed', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_revenue_per_month', mapping: 'equipment.expected_revenue_per_month', title: 'Expected Revenue Per Month', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__capacity', mapping: 'equipment.capacity', title: 'Capacity', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_upright_type_id', mapping: 'equipment.eq_upright_type_id', title: 'Upright Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__upright_lhloh', mapping: 'equipment.upright_lhloh', title: 'LH / LoH', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_valve_type_id', mapping: 'equipment.eq_valve_type_id', title: 'Eq Valve Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_power_type_id', mapping: 'equipment.eq_power_type_id', title: 'Eq Power Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_tire_type_id', mapping: 'equipment.eq_tire_type_id', title: 'Eq Tire Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__battery_size', mapping: 'equipment.battery_size', title: 'Battery Size', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_voltage_id', mapping: 'equipment.eq_voltage_id', title: 'Eq Voltage', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_fork_length_id', mapping: 'equipment.eq_fork_length_id', title: 'Eq Fork Length', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_attachments', mapping: 'equipment.eq_attachments', title: 'Attachments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_fluorescent_stripe', mapping: 'equipment.is_fluorescent_stripe', title: 'Fluorescent Striping?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_whelen_light', mapping: 'equipment.is_whelen_light', title: 'Whelen Lights?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_blue_led_light', mapping: 'equipment.is_blue_led_light', title: 'Blue LED Spot Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_high_vis_seatbelt', mapping: 'equipment.is_high_vis_seatbelt', title: 'Hi-Vis Seatbelt?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_rear_handle_horn', mapping: 'equipment.is_rear_handle_horn', title: 'Rear Handle w/Horn?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_contact_decals', mapping: 'equipment.is_contact_decals', title: '3-Point of Contact Decals?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pallet_protection', mapping: 'equipment.is_pallet_protection', title: 'Pallet Protection?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_laser_light', mapping: 'equipment.is_laser_light', title: 'Laser Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tags__id', mapping: 'tag.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tags__name', mapping: 'tag.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tags__tag_type_id', mapping: 'tag.tag_type_id', title: 'Tag Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Equipment',
				'Tags'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'equipment_tags__equipment_id',
			// 'equipment_tags__tag_id'
		],

	},

	entity: {
		methods: {

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.equipment_tags__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.equipment_tags__equipment_id);
				}
				return entity;
			},

			getTag: async function() {
				const Tags = this.getAssociatedRepository('Tags');
				let entity = Tags.getById(this.equipment_tags__tag_id);
				if (!entity) {
					entity = await Tags.getSingleEntityFromServer(this.equipment_tags__tag_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default EquipmentTags;
