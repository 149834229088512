/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EnterprisesGridEditorPanel from './EnterprisesGridEditor.js';

export default function EnterprisesFilteredGridEditorPanel(props) {
	return <EnterprisesGridEditorPanel
				reference="EnterprisesFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}