/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import BusinessPartnersFleetsEditorWindowSideB from '../Window/BusinessPartnersFleetsEditorWindowSideB.js';
import BusinessPartnersFleetsGridColumnsSideB from './Columns/BusinessPartnersFleetsGridColumnsSideB.js';

export default function BusinessPartnersFleetsGridEditorSideB(props) {
	return <WindowedGridEditor
				reference="BusinessPartnersFleetsGridEditorSideB"
				model="BusinessPartnersFleets"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={BusinessPartnersFleetsEditorWindowSideB}
				columnsConfig={BusinessPartnersFleetsGridColumnsSideB}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}