/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import WoLaborsGridColumns from './Columns/WoLaborsGridColumns.js';

export default function WoLaborsGrid(props) {
	return <Grid
				reference="WoLaborsGrid"
				model="WoLabors"
				usePermissions={true}
				columnsConfig={WoLaborsGridColumns}

				{...props}
			/>;
}