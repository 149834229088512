/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WoClassesEditor from '../Editor/WoClassesEditor.js';
import WoClassesGridColumns from './Columns/WoClassesGridColumns.js';

export default function WoClassesSideGridEditor(props) {
	return <SideGridEditor
				reference="WoClassesSideGridEditor"
				model="WoClasses"
				usePermissions={true}
				isCollapsible={false}
				Editor={WoClassesEditor}
				columnsConfig={WoClassesGridColumns}
				
				
				{...props}
			/>;
}