/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function EqStatsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "eq_stats__is_stale",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__path",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__latest_meter_reading",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__latest_meter_date",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__latest_meter_source",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__latest_repair_date",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__latest_pm_data",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__due_pms",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__next_pm_due_data",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__overdue_pms",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__assigned_technician_data",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mean_time_between_failures",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__estimated_annual_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__is_warranty",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__is_lease",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__is_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__lease_number",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__lease_term",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__lease_expected_end_date",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__lease_status_id",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__lease_payment",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__lease_pays_remaining",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__lease_midterm_checked",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__lease_inspected",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_contract_number",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_start_date",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_end_date",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_elapsed_annual_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_max_annual_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_elapsed_contract_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_max_contract_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_latest_invoice_date",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltm_latest_invoice_amount",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_available_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_util",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_preventive",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_abuse",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_abuseRental",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_tires",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_repair",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_preventive_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_abuse_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_tires_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_repair_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_maint_combined",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_ltm_billing",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_lease",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_depreciation",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_cost_per_hr",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_cost_per_hr_customer",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__mtd_cost_per_hr_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__qtd_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__qtd_available_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__qtd_util",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_available_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_util",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_preventive",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_abuse",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_abuseRental",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_tires",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_repair",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_preventive_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_abuse_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_tires_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_repair_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_maint_combined",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_ltm_billing",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_lease",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytn_depreciation",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_cost_per_hr",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_cost_per_hr_customer",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ytd_cost_per_hr_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_available_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_util",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_preventive",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_abuse",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_abuseRental",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_tires",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_repair",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_preventive_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_abuse_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_tires_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_repair_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_maint_combined",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_ltm_billing",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_lease",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_depreciation",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_cost_per_hr",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_cost_per_hr_customer",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__rtm_cost_per_hr_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_available_hours",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_util",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_preventive",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_abuse",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_abuseRental",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_tires",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_repair",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_preventive_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_abuse_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_tires_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_repair_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_depreciation",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_maint_combined",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_ltm_billing",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_lease",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltn_depreciation",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_cost_per_hr",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_cost_per_hr_customer",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "eq_stats__ltd_cost_per_hr_ltm",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="EqStatsEditor"
				title="EqStats"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

