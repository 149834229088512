/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import EnterpriseTypesGridColumns from './Columns/EnterpriseTypesGridColumns.js';

export default function EnterpriseTypesGrid(props) {
	return <Grid
				reference="EnterpriseTypesGrid"
				model="EnterpriseTypes"
				usePermissions={true}
				columnsConfig={EnterpriseTypesGridColumns}

				{...props}
			/>;
}