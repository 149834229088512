/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useState, useEffect, } from 'react';
import { Linking } from 'react-native';
import {
	Column,
	Row,
} from 'native-base';
import Url from 'url-parse';
import {
	useLinkTo,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import {
	VERTICAL,
} from '@onehat/ui/src/Constants/Directions.js';
import {
	VIEW,
} from '@onehat/ui/src/Constants/Commands.js';
import {
	setCurrentScreen,
	selectUser,
} from '../models/Slices/AppSlice';
import {
	setAlertMessage,
	selectDebugMessage,
	selectAlertMessage,
	selectIsWaitModalShown,
} from '../models/Slices/DebugSlice';
import withPermissions from '@onehat/ui/src/Components/Hoc/withPermissions';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import WaitMessage from '@onehat/ui/src/Components/Messages/WaitMessage';
import ErrorMessage from '@onehat/ui/src/Components/Messages/ErrorMessage';
import inArray from '@onehat/ui/src/Functions/inArray';

import Book from '@onehat/ui/src/Components/Icons/Book';
import Building from '@onehat/ui/src/Components/Icons/Building';
import Clipboard from '@onehat/ui/src/Components/Icons/Clipboard';
import FolderTree from '@onehat/ui/src/Components/Icons/FolderTree';
import Gears from '@onehat/ui/src/Components/Icons/Gears';
import House from '@onehat/ui/src/Components/Icons/House';
import Hammer from '@onehat/ui/src/Components/Icons/Hammer';
import OilCan from '../components/Icons/OilCan';
import Forklift from '../components/Icons/Forklift';
import Dots from '../components/Icons/Dots';
import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';

import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';

import EnterpriseSelector from '../components/Form/Field/Combo/EnterpriseSelector.js';
import EnterprisesManager from '../screens/EnterprisesManager';
import FleetsManager from '../screens/FleetsManager';
import EquipmentManager from '../screens/EquipmentManager';
import Home from '../screens/Home';
import Logout from '../screens/Logout';
import Logs from '../screens/Logs';
// import PartsManager from '../screens/PartsManager';
import PmEventsManager from '../screens/PmEventsManager';
import Setup from '../screens/Setup';
// import ReportsManager from '../screens/ReportsManager';
import UsersManager from '../screens/UsersManager';
import GroupsManager from '../screens/GroupsManager';
import WorkOrdersManager from '../screens/WorkOrdersManager';
import Util from '../screens/Util';
import AppGlobals from '../AppGlobals';
import Inflector from 'inflector-js';
import _ from 'lodash';

const
	Stack = createStackNavigator(),
	urlPrefix = '/' + AppGlobals.urlPrefix;

function AppNavigator(props) {
	const {
			// withPermissions
			canUser,
		} = props,
		linkTo = useLinkTo(),
		dispatch = useDispatch(),
		debugMessage = useSelector(selectDebugMessage),
		alertMessage = useSelector(selectAlertMessage),
		isWaitModalShown = useSelector(selectIsWaitModalShown),
		user = useSelector(selectUser),
		[currentTabIx, setCurrentTabIx] = useState(0),
		[isReady, setIsReady] = useState(false),
		[initialRouteName, setInitialRouteName] = useState('Home'),
		onChangeCurrentTab = (ix) => {
			// update state
			setCurrentTabIx(ix);

			// switch nav
			const
				currentTab = tabs[ix],
				path = currentTab.path;
			linkTo(path);
			dispatch(setCurrentScreen(currentTab.screenName));
		};

	const tabs = [
		{
			title: 'Home',
			_icon: { as: House },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Home')),
			screenName: 'Home',
		},
	];
	if (canUser(VIEW, 'Enterprises')) {
		tabs.push({
			title: 'Enterprises',
			_icon: { as: Building },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Enterprises')),
			screenName: 'EnterprisesManager',
		});
	}
	if (canUser(VIEW, 'Fleets')) {
		tabs.push({
			title: 'Fleets',
			_icon: { as: FolderTree },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Fleets')),
			screenName: 'FleetsManager',
		});
	}
	if (canUser(VIEW, 'Equipment')) {
		tabs.push({
			title: 'Equipment',
			_icon: { as: Forklift },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Equipment')),
			screenName: 'EquipmentManager',
		});
	}
	if (canUser(VIEW, 'WorkOrders')) {
		tabs.push({
			title: 'Work Orders',
			_icon: { as: Clipboard },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('WorkOrders')),
			screenName: 'WorkOrdersManager',
		});
	}
	if (canUser(VIEW, 'PmEvents')) {
		tabs.push({
			title: 'Upcoming PMs',
			_icon: { as: OilCan },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Pms')),
			screenName: 'PmEventsManager',
		});
	}
	// if (canUser('view_reports')) {
	// 	tabs.push({
	// 		title: 'Reports',
	// 		_icon: { as: ChartPie },
	// 		path: urlPrefix + Inflector.dasherize(Inflector.underscore('reports')),
	// 		screenName: 'ReportsManager',
	// 	});
	// }
	if (canUser(VIEW, 'Users')) {
		tabs.push({
			title: 'Users',
			_icon: { as: User },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Users')),
			screenName: 'UsersManager',
		});
	}
	if (canUser(VIEW, 'Groups')) {
		tabs.push({
			title: 'Groups',
			_icon: { as: UserGroup },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Groups')),
			screenName: 'GroupsManager',
		});
	}
	if (canUser('access_setup_mode')) {
		tabs.push({
			title: 'Setup',
			_icon: { as: Gears },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Setup')),
			screenName: 'Setup',
		});
	}
	if (canUser('access_audit_app')) {
		tabs.push({
			title: 'Logs',
			_icon: { as: Book },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Logs')),
			screenName: 'Logs',
		});
	}
	tabs.push({
		title: 'Utilities',
		_icon: { as: Hammer },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Util')),
		screenName: 'Util',
	});
	tabs.push({
		title: 'Logout',
		_icon: { as: RightFromBracket },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Logout')),
		screenName: 'Logout',
	});

	useEffect(() => {
		(async () => {
			const
				initialUrl = await Linking.getInitialURL(),
				url = new Url(initialUrl),
				path = url.pathname;

			if (!user) {
				linkTo(urlPrefix + Inflector.dasherize(Inflector.underscore('Login')));
				return;
			}

			const 
				whichTab = _.find(tabs, (tab) => {
					return tab.path === path;
				}),
				whichTabIx = whichTab ? tabs.indexOf(whichTab) : 0;

			setCurrentTabIx(whichTabIx);
			if (whichTab) {
				setInitialRouteName(whichTab.screenName);
			}
			setIsReady(true);
		})();
	}, []);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}

	const navigatorOptions = {
		initialRouteName,
		screenOptions: ({ route }) => {
			return {
				headerShown: false,
				title: 'MhPms Admin App: ' + route.name.replace('Manager', ''),
			}
		},
	};

	return <Row flex={1} w="100%">
				{isWaitModalShown && <WaitMessage />}
				{alertMessage && <ErrorMessage text={alertMessage} onOk={() => dispatch(setAlertMessage(null))} />}
				{debugMessage && <ErrorMessage text={debugMessage} color="green" onOk={() => dispatch(setDebugMessage(null))} />}

				{!!currentTabIx && currentTabIx !== tabs.length -1 && // hide TabBar if showing Home or Logout
					<TabBar
						tabs={tabs}
						direction={VERTICAL}
						tabWidth={180}
						currentTabIx={currentTabIx}
						onChangeCurrentTab={onChangeCurrentTab}
					/>}
				<Column flex={1}>
					{!inArray(currentTabIx, [0,1,8,9]) && 
						<Row w="100%" px={3}>
							<EnterpriseSelector />
						</Row>}
					<Stack.Navigator {...navigatorOptions}>
						<Stack.Screen name="Home">
							{() => <Home tabs={tabs} onChangeCurrentTab={onChangeCurrentTab} />}
						</Stack.Screen>
					
						<Stack.Screen name="EnterprisesManager" component={EnterprisesManager} />
						<Stack.Screen name="FleetsManager" component={FleetsManager} />
						<Stack.Screen name="WorkOrdersManager" component={WorkOrdersManager} />
						<Stack.Screen name="EquipmentManager" component={EquipmentManager} />
						<Stack.Screen name="PmEventsManager" component={PmEventsManager} />
						{/* <Stack.Screen name="ReportsManager" component={ReportsManager} /> */}
						<Stack.Screen name="UsersManager" component={UsersManager} />
						<Stack.Screen name="GroupsManager" component={GroupsManager} />
						<Stack.Screen name="Logs" component={Logs} />
						<Stack.Screen name="Setup" component={Setup} />
						<Stack.Screen name="Util" component={Util} />
						<Stack.Screen name="Logout" component={Logout} />
					</Stack.Navigator>
				</Column>
			</Row>;
}

export default withPermissions(AppNavigator, true);
