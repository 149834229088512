/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import TelManufacturersSideGridEditor from './TelManufacturersSideGridEditor.js';

export default function TelManufacturersFilteredSideGridEditor(props) {
	return <TelManufacturersSideGridEditor
				reference="TelManufacturersFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}