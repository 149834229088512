import * as yup from 'yup'; // https://github.com/jquense/yup#string

const PmEventTypes = {
	
	name: 'PmEventTypes',
	
	model: {

		idProperty: 'pm_event_types__id',
		displayProperty: 'pm_event_types__name',
		sortProperty: 'pm_event_types__name',
		
		sorters: null,

		validator: yup.object({
			pm_event_types__name: yup.string().max(45).required(),
			pm_event_types__sort_order: yup.number().integer().nullable(),
			pm_event_types__is_manual: yup.boolean().required(),
			pm_event_types__is_reset: yup.boolean().required(),
			pm_event_types__is_override: yup.boolean().required(),
			pm_event_types__is_delay: yup.boolean().required(),
			pm_event_types__is_info: yup.boolean().required()
		}),
		
		properties: [
			{ name: 'pm_event_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'pm_event_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'pm_event_types__sort_order', mapping: 'sort_order', title: 'Sort Order', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pm_event_types__is_manual', mapping: 'is_manual', title: 'Manual?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'pm_event_types__is_reset', mapping: 'is_reset', title: 'Reset?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'pm_event_types__is_override', mapping: 'is_override', title: 'Override?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'pm_event_types__is_delay', mapping: 'is_delay', title: 'Delay?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'pm_event_types__is_info', mapping: 'is_info', title: 'Info?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'PmEvents'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'pm_event_types__sort_order',
			// 'pm_event_types__is_manual',
			// 'pm_event_types__is_reset',
			// 'pm_event_types__is_override',
			// 'pm_event_types__is_delay',
			// 'pm_event_types__is_info'
		],

	},

	entity: {
		methods: {

			getPmEvents: function() {
				const PmEvents = this.getAssociatedRepository('PmEvents');
				return PmEvents.getBy((entity) => {
					return entity.pm_events__pm_event_type_id === this.pm_event_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default PmEventTypes;
