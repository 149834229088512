/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import FleetsEditor from '../Editor/FleetsEditor.js';
import FleetsGridColumns from './Columns/FleetsGridColumns.js';

export default function FleetsSideGridEditor(props) {
	return <SideGridEditor
				reference="FleetsSideGridEditor"
				model="Fleets"
				usePermissions={true}
				isCollapsible={false}
				Editor={FleetsEditor}
				columnsConfig={FleetsGridColumns}
				
				
				{...props}
			/>;
}