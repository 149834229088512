/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ManufacturersGridEditor from './ManufacturersGridEditor.js';

export default function ManufacturersFilteredGridEditor(props) {
	return <ManufacturersGridEditor
				reference="ManufacturersFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}