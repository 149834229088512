/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EqUprightTypesEditorWindow from '../../../Window/EqUprightTypesEditorWindow.js';

function EqUprightTypesTag(props) {
	return <Tag
				reference="EqUprightTypesTag"
				model="EqUprightTypes"
				uniqueRepository={true}
				Editor={EqUprightTypesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EqUprightTypesTag;