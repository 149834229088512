/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import EquipmentPmSchedulesGridColumns from '../../Grid/Columns/EquipmentPmSchedulesGridColumns.js';

export default function EquipmentPmSchedulesGridPanel(props) {
	return <GridPanel
				reference="EquipmentPmSchedulesGridPanel"
				model="EquipmentPmSchedules"
				usePermissions={true}
				columnsConfig={EquipmentPmSchedulesGridColumns}

				{...props}
			/>;
}