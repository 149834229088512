/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqPowerTypesSideGridEditorPanel from './EqPowerTypesSideGridEditor.js';


export default function EqPowerTypesFilteredSideGridEditorPanel(props) {
	return <EqPowerTypesSideGridEditorPanel
				reference="EqPowerTypesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}