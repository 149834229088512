/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import MhBranchesGrid from './MhBranchesGrid.js';

export default function MhBranchesFilteredGrid(props) {
	return <MhBranchesGrid
				reference="MhBranchesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}