/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import EqVoltagesEditor from '../../Editor/EqVoltagesEditor.js';
import EqVoltagesGridColumns from '../../Grid/Columns/EqVoltagesGridColumns.js';

export default function EqVoltagesSideGridEditorPanel(props) {
	return <GridPanel
				reference="EqVoltagesSideGridEditorPanel"
				model="EqVoltages"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={EqVoltagesEditor}
				columnsConfig={EqVoltagesGridColumns}
				
				
				{...props}
			/>;
}