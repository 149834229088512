/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import PmEventsGridColumns from './Columns/PmEventsGridColumns.js';

export default function PmEventsGrid(props) {
	return <Grid
				reference="PmEventsGrid"
				model="PmEvents"
				usePermissions={true}
				columnsConfig={PmEventsGridColumns}

				{...props}
			/>;
}