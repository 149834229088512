/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PartTypesInlineGridEditorPanel from './PartTypesInlineGridEditor.js';

export default function PartTypesFilteredInlineGridEditorPanel(props) {
	return <PartTypesInlineGridEditorPanel
				reference="PartTypesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}