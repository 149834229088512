/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WorkOrdersTagsGridEditorSideA from './WorkOrdersTagsGridEditorSideA.js';

export default function WorkOrdersTagsFilteredGridEditorSideA(props) {
	return <WorkOrdersTagsGridEditorSideA
				reference="WorkOrdersTagsFilteredGridEditorSideA"
				useFilters={true}

				{...props}
			/>;
}