export default [
	    {
	        "id": "parts_work_orders__work_order_id",
	        "header": "Work Order",
	        "fieldName": "work_orders__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "parts_work_orders__work_order_id",
	        "flex": 1,
	        "minWidth": 150
	    },
	    {
	        "id": "parts_work_orders__qty",
	        "header": "Qty",
	        "fieldName": "parts_work_orders__qty",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__unit_price",
	        "header": "Unit Price",
	        "fieldName": "parts_work_orders__unit_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__sell_price",
	        "header": "Sell Price",
	        "fieldName": "parts_work_orders__sell_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__is_in_stock",
	        "header": "In Stock?",
	        "fieldName": "parts_work_orders__is_in_stock",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__is_checked",
	        "header": "Checked?",
	        "fieldName": "parts_work_orders__is_checked",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];