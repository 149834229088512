/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import TelOperatorsGridEditor from './TelOperatorsGridEditor.js';

export default function TelOperatorsFilteredGridEditor(props) {
	return <TelOperatorsGridEditor
				reference="TelOperatorsFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}