/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import WoLaborsEditorWindow from '../../../Window/WoLaborsEditorWindow.js';

function WoLaborsTag(props) {
	return <Tag
				reference="WoLaborsTag"
				model="WoLabors"
				uniqueRepository={true}
				Editor={WoLaborsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default WoLaborsTag;