/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqValveTypesEditor from '../Editor/EqValveTypesEditor.js';
import EqValveTypesGridColumns from './Columns/EqValveTypesGridColumns.js';

export default function EqValveTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="EqValveTypesSideGridEditor"
				model="EqValveTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={EqValveTypesEditor}
				columnsConfig={EqValveTypesGridColumns}
				
				
				{...props}
			/>;
}