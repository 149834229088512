import * as yup from 'yup'; // https://github.com/jquense/yup#string

const PartsParts = {
	
	name: 'PartsParts',
	
	model: {

		idProperty: 'parts_parts__id',
		displayProperty: 'parts_parts__id',
		sortProperty: 'parts_parts__id',
		
		sorters: null,

		validator: yup.object({
			parts_parts__part_id: yup.number().integer().required(),
			parts_parts__interchangeable_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'parts_parts__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'parts_parts__part_id', mapping: 'part_id', title: 'Part', type: 'int', isFk: true, fkIdField: 'parts__id', fkDisplayField: 'parts__part_number', filterType: {"type":"PartsCombo","loadAfterRender":!1}, editorType: {"type":"PartsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts_parts__interchangeable_id', mapping: 'interchangeable_id', title: 'Interchangeable', type: 'int', isFk: true, fkIdField: 'parts__id', fkDisplayField: 'parts__part_number', filterType: {"type":"PartsCombo","loadAfterRender":!1}, editorType: {"type":"PartsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts__id', mapping: 'part.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__part_number', mapping: 'part.part_number', title: 'Part Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__description', mapping: 'part.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__manufacturer_id', mapping: 'part.manufacturer_id', title: 'Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__part_source_id', mapping: 'part.part_source_id', title: 'Part Source', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__unit_price', mapping: 'part.unit_price', title: 'Unit Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__unit_sell', mapping: 'part.unit_sell', title: 'Unit Sell', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__ext_sell', mapping: 'part.ext_sell', title: 'Ext Sell', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__obsolete_part_numbers', mapping: 'part.obsolete_part_numbers', title: 'Obsolete Part Numbers', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Parts'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'parts_parts__part_id',
			// 'parts_parts__interchangeable_id'
		],

	},

	entity: {
		methods: {

			getPart: async function() {
				const Parts = this.getAssociatedRepository('Parts');
				let entity = Parts.getById(this.parts_parts__part_id);
				if (!entity) {
					entity = await Parts.getSingleEntityFromServer(this.parts_parts__part_id);
				}
				return entity;
			},

			getPart: async function() {
				const Parts = this.getAssociatedRepository('Parts');
				let entity = Parts.getById(this.parts_parts__interchangeable_id);
				if (!entity) {
					entity = await Parts.getSingleEntityFromServer(this.parts_parts__interchangeable_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default PartsParts;
