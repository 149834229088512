/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EnterprisesSideGridEditorPanel from './EnterprisesSideGridEditor.js';


export default function EnterprisesFilteredSideGridEditorPanel(props) {
	return <EnterprisesSideGridEditorPanel
				reference="EnterprisesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}