/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import EnterpriseTypesGridColumns from '../../Grid/Columns/EnterpriseTypesGridColumns.js';

export default function EnterpriseTypesGridPanel(props) {
	return <GridPanel
				reference="EnterpriseTypesGridPanel"
				model="EnterpriseTypes"
				usePermissions={true}
				columnsConfig={EnterpriseTypesGridColumns}

				{...props}
			/>;
}