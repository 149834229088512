/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function WorkOrdersEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Summary",
		                "reference": "summary",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__equipment_id"
		                    },
		                    {
		                        "name": "work_orders__wo_number"
		                    },
		                    {
		                        "name": "work_orders__segment"
		                    },
		                    {
		                        "name": "work_orders__reference"
		                    },
		                    {
		                        "name": "work_orders__wo_status_id"
		                    },
		                    {
		                        "name": "work_orders__total_parts",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "work_orders__total_labor",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "work_orders__total_other",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "work_orders__total",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Dates",
		                "reference": "dates",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__open_date"
		                    },
		                    {
		                        "name": "work_orders__close_date"
		                    },
		                    {
		                        "name": "work_orders__invoiced_date"
		                    },
		                    {
		                        "name": "work_orders__completed_date"
		                    },
		                    {
		                        "name": "work_orders__time_out_of_service"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Specifications",
		                "reference": "specifications",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__wo_mode_id"
		                    },
		                    {
		                        "name": "work_orders__wo_priority_id"
		                    },
		                    {
		                        "name": "work_orders__wo_class_id"
		                    },
		                    {
		                        "name": "work_orders__invoice_number"
		                    },
		                    {
		                        "name": "work_orders__po_number"
		                    },
		                    {
		                        "name": "work_orders__pm_schedule_id"
		                    },
		                    {
		                        "name": "work_orders__tags"
		                    },
		                    {
		                        "name": "work_orders__meter_reading"
		                    },
		                    {
		                        "name": "work_orders__comments"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Work Related",
		                "reference": "work_related",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__wo_location_id"
		                    },
		                    {
		                        "name": "work_orders__repaired_by_company"
		                    },
		                    {
		                        "name": "work_orders__repaired_by_branch"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Switches",
		                "reference": "switches",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__is_ltm"
		                    },
		                    {
		                        "name": "work_orders__is_scheduled"
		                    },
		                    {
		                        "name": "work_orders__is_verified"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Business Partner",
		                "reference": "business_partner",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__business_partner_id"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Bill To",
		                "reference": "bill_to",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__bill_to_name"
		                    },
		                    {
		                        "name": "work_orders__bill_to_street1"
		                    },
		                    {
		                        "name": "work_orders__bill_to_street2"
		                    },
		                    {
		                        "name": "work_orders__bill_to_city"
		                    },
		                    {
		                        "name": "work_orders__bill_to_state_abbr"
		                    },
		                    {
		                        "name": "work_orders__bill_to_zip"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Ship To",
		                "reference": "ship_to",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__ship_to_name"
		                    },
		                    {
		                        "name": "work_orders__ship_to_street1"
		                    },
		                    {
		                        "name": "work_orders__ship_to_street2"
		                    },
		                    {
		                        "name": "work_orders__ship_to_city"
		                    },
		                    {
		                        "name": "work_orders__ship_to_state_abbr"
		                    },
		                    {
		                        "name": "work_orders__ship_to_zip"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
		    {
		        "title": "Meter Readings",
		        "type": "MeterReadingsGridEditor",
		        "selectorId": "meter_readings__work_order_id"
		    },
		    {
		        "title": "Parts",
		        "type": "PartsWorkOrdersGridEditorSideA",
		        "selectorId": "parts_work_orders__work_order_id"
		    },
		    {
		        "title": "Pm Events",
		        "type": "PmEventsGridEditor",
		        "selectorId": "pm_events__work_order_id"
		    },
		    {
		        "title": "Wo Labors",
		        "type": "WoLaborsGridEditor",
		        "selectorId": "wo_labors__work_order_id"
		    },
		    {
		        "title": "Wo Other Charges",
		        "type": "WoOtherChargesGridEditor",
		        "selectorId": "wo_other_charges__work_order_id"
		    }
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="WorkOrdersEditor"
				title="WorkOrders"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

