export default [
	    {
	        "id": "tel_usages__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "tel_usages__equipment_id"
	    },
	    {
	        "id": "tel_usages__tel_operator_id",
	        "header": "Tel Operator",
	        "fieldName": "tel_operators__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "tel_usages__tel_operator_id"
	    },
	    {
	        "id": "tel_usages__start_date",
	        "header": "Start Date",
	        "fieldName": "tel_usages__start_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "tel_usages__end_date",
	        "header": "End Date",
	        "fieldName": "tel_usages__end_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "tel_usages__midpoint_date",
	        "header": "Midpoint Date",
	        "fieldName": "tel_usages__midpoint_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "tel_usages__sess_login_time",
	        "header": "Sess Login Time",
	        "fieldName": "tel_usages__sess_login_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__session_identifier",
	        "header": "Session Identifier",
	        "fieldName": "tel_usages__session_identifier",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__max_height",
	        "header": "Max Height",
	        "fieldName": "tel_usages__max_height",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__max_weight",
	        "header": "Max Weight",
	        "fieldName": "tel_usages__max_weight",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__max_speed",
	        "header": "Max Speed",
	        "fieldName": "tel_usages__max_speed",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_start_fuel_level",
	        "header": "Sess Start Fuel Level",
	        "fieldName": "tel_usages__sess_start_fuel_level",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_end_fuel_level",
	        "header": "Sess End Fuel Level",
	        "fieldName": "tel_usages__sess_end_fuel_level",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_avg_load",
	        "header": "Sess Avg Load",
	        "fieldName": "tel_usages__sess_avg_load",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_peak_load",
	        "header": "Sess Peak Load",
	        "fieldName": "tel_usages__sess_peak_load",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_total_load",
	        "header": "Sess Total Load",
	        "fieldName": "tel_usages__sess_total_load",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_weight_low",
	        "header": "Sess Weight Low",
	        "fieldName": "tel_usages__sess_weight_low",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_weight_med",
	        "header": "Sess Weight Med",
	        "fieldName": "tel_usages__sess_weight_med",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_weight_high",
	        "header": "Sess Weight High",
	        "fieldName": "tel_usages__sess_weight_high",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_weight_low_time",
	        "header": "Sess Weight Low Time",
	        "fieldName": "tel_usages__sess_weight_low_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_weight_med_time",
	        "header": "Sess Weight Med Time",
	        "fieldName": "tel_usages__sess_weight_med_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_weight_high_time",
	        "header": "Sess Weight High Time",
	        "fieldName": "tel_usages__sess_weight_high_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_lift_count",
	        "header": "Sess Lift Count",
	        "fieldName": "tel_usages__sess_lift_count",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_height_low",
	        "header": "Sess Height Low",
	        "fieldName": "tel_usages__sess_height_low",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_height_med",
	        "header": "Sess Height Med",
	        "fieldName": "tel_usages__sess_height_med",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_height_high",
	        "header": "Sess Height High",
	        "fieldName": "tel_usages__sess_height_high",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_height_low_time",
	        "header": "Sess Height Low Time",
	        "fieldName": "tel_usages__sess_height_low_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_height_med_time",
	        "header": "Sess Height Med Time",
	        "fieldName": "tel_usages__sess_height_med_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_height_high_time",
	        "header": "Sess Height High Time",
	        "fieldName": "tel_usages__sess_height_high_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_avg_speed",
	        "header": "Sess Avg Speed",
	        "fieldName": "tel_usages__sess_avg_speed",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_peak_speed",
	        "header": "Sess Peak Speed",
	        "fieldName": "tel_usages__sess_peak_speed",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_speed_low",
	        "header": "Sess Speed Low",
	        "fieldName": "tel_usages__sess_speed_low",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_speed_med",
	        "header": "Sess Speed Med",
	        "fieldName": "tel_usages__sess_speed_med",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_speed_high",
	        "header": "Sess Speed High",
	        "fieldName": "tel_usages__sess_speed_high",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_speed_low_time",
	        "header": "Sess Speed Low Time",
	        "fieldName": "tel_usages__sess_speed_low_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_speed_med_time",
	        "header": "Sess Speed Med Time",
	        "fieldName": "tel_usages__sess_speed_med_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_speed_high_time",
	        "header": "Sess Speed High Time",
	        "fieldName": "tel_usages__sess_speed_high_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_speed_over_level1",
	        "header": "Sess Speed Over Level1",
	        "fieldName": "tel_usages__sess_speed_over_level1",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_speed_over_level2",
	        "header": "Sess Speed Over Level2",
	        "fieldName": "tel_usages__sess_speed_over_level2",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_danger",
	        "header": "Sess Danger",
	        "fieldName": "tel_usages__sess_danger",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_danger_time",
	        "header": "Sess Danger Time",
	        "fieldName": "tel_usages__sess_danger_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_key_time",
	        "header": "Sess Key Time",
	        "fieldName": "tel_usages__sess_key_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_operator_time",
	        "header": "Sess Operator Time",
	        "fieldName": "tel_usages__sess_operator_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_traction_time",
	        "header": "Sess Traction Time",
	        "fieldName": "tel_usages__sess_traction_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_on_seat_idle_time",
	        "header": "Sess On Seat Idle Time",
	        "fieldName": "tel_usages__sess_on_seat_idle_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_off_seat_idle_time",
	        "header": "Sess Off Seat Idle Time",
	        "fieldName": "tel_usages__sess_off_seat_idle_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_laden_time",
	        "header": "Sess Laden Time",
	        "fieldName": "tel_usages__sess_laden_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_laden_traction_time",
	        "header": "Sess Laden Traction Time",
	        "fieldName": "tel_usages__sess_laden_traction_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_lift_time",
	        "header": "Sess Lift Time",
	        "fieldName": "tel_usages__sess_lift_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_hydraulic_time",
	        "header": "Sess Hydraulic Time",
	        "fieldName": "tel_usages__sess_hydraulic_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_forward_gear_time",
	        "header": "Sess Forward Gear Time",
	        "fieldName": "tel_usages__sess_forward_gear_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_reverse_gear_time",
	        "header": "Sess Reverse Gear Time",
	        "fieldName": "tel_usages__sess_reverse_gear_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_distance",
	        "header": "Sess Distance",
	        "fieldName": "tel_usages__sess_distance",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__sess_distance_laden",
	        "header": "Sess Distance Laden",
	        "fieldName": "tel_usages__sess_distance_laden",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_key_time",
	        "header": "Ltd Key Time",
	        "fieldName": "tel_usages__ltd_key_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_operator_time",
	        "header": "Ltd Operator Time",
	        "fieldName": "tel_usages__ltd_operator_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_traction_time",
	        "header": "Ltd Traction Time",
	        "fieldName": "tel_usages__ltd_traction_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_on_seat_idle_time",
	        "header": "Ltd On Seat Idle Time",
	        "fieldName": "tel_usages__ltd_on_seat_idle_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_off_seat_idle_time",
	        "header": "Ltd Off Seat Idle Time",
	        "fieldName": "tel_usages__ltd_off_seat_idle_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_laden_time",
	        "header": "Ltd Laden Time",
	        "fieldName": "tel_usages__ltd_laden_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_laden_moving_time",
	        "header": "Ltd Laden Moving Time",
	        "fieldName": "tel_usages__ltd_laden_moving_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_lift_time",
	        "header": "Ltd Lift Time",
	        "fieldName": "tel_usages__ltd_lift_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_hydraulic_time",
	        "header": "Ltd Hydraulic Time",
	        "fieldName": "tel_usages__ltd_hydraulic_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_forward_gear_time",
	        "header": "Ltd Forward Gear Time",
	        "fieldName": "tel_usages__ltd_forward_gear_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_reverse_gear_time",
	        "header": "Ltd Reverse Gear Time",
	        "fieldName": "tel_usages__ltd_reverse_gear_time",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_distance",
	        "header": "Ltd Distance",
	        "fieldName": "tel_usages__ltd_distance",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__ltd_distance_laden",
	        "header": "Ltd Distance Laden",
	        "fieldName": "tel_usages__ltd_distance_laden",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__shift",
	        "header": "Shift",
	        "fieldName": "tel_usages__shift",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "tel_usages__err_msg",
	        "header": "Err Msg",
	        "fieldName": "tel_usages__err_msg",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];