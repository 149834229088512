/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilProcessLogsGridEditorPanel from './UtilProcessLogsGridEditor.js';

export default function UtilProcessLogsFilteredGridEditorPanel(props) {
	return <UtilProcessLogsGridEditorPanel
				reference="UtilProcessLogsFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}