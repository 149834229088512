export default [
	    {
	        "id": "chromes__name",
	        "header": "Name",
	        "fieldName": "chromes__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "chromes__url",
	        "header": "Url",
	        "fieldName": "chromes__url",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "chromes__abbr",
	        "header": "Abbr",
	        "fieldName": "chromes__abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "chromes__is_enabled",
	        "header": "Enabled?",
	        "fieldName": "chromes__is_enabled",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];