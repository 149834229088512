export default [
	    {
	        "id": "fleets__name",
	        "header": "Name",
	        "fieldName": "fleets__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "fleets__business_partners",
	        "header": "Business Partners",
	        "fieldName": "fleets__business_partners",
	        "isSortable": false,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];