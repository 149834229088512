/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PmSchedulesEditor from '../Editor/PmSchedulesEditor.js';
import PmSchedulesGridColumns from './Columns/PmSchedulesGridColumns.js';

export default function PmSchedulesSideGridEditor(props) {
	return <SideGridEditor
				reference="PmSchedulesSideGridEditor"
				model="PmSchedules"
				usePermissions={true}
				isCollapsible={false}
				Editor={PmSchedulesEditor}
				columnsConfig={PmSchedulesGridColumns}
				
				
				{...props}
			/>;
}