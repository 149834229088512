/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import AddressesInlineGridEditorPanel from './AddressesInlineGridEditor.js';

export default function AddressesFilteredInlineGridEditorPanel(props) {
	return <AddressesInlineGridEditorPanel
				reference="AddressesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}