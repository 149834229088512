/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoOtherChargesGridPanel from './WoOtherChargesGrid.js';

export default function WoOtherChargesFilteredGridPanel(props) {
	return <WoOtherChargesGridPanel
				reference="WoOtherChargesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}