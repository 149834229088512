/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import TelOperatorsEditorWindow from '../../Window/TelOperatorsEditorWindow.js';
import TelOperatorsGridColumns from '../../Grid/Columns/TelOperatorsGridColumns.js';

export default function TelOperatorsGridEditorPanel(props) {
	return <GridPanel
				reference="TelOperatorsGridEditorPanel"
				model="TelOperators"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={TelOperatorsEditorWindow}
				columnsConfig={TelOperatorsGridColumns}
				
				
				{...props}
			/>;
}