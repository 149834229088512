import {
	COLLAPSED,
	EXPANDED,
	LEAF,
} from '@onehat/ui/src/Constants/Tree.js';
import ChevronRight from '@onehat/ui/src/Components/Icons/ChevronRight.js';
import ChevronDown from '@onehat/ui/src/Components/Icons/ChevronDown.js';
import Building from '@onehat/ui/src/Components/Icons/Building';
import Bar from '../components/Icons/ShortBar';
import Dots from '../components/Icons/Dots';
import Forklift from '../components/Icons/Forklift';

export default function getNodeIcon(which, item) {
	let icon;
	switch(item.schema.name) {
		case 'Fleets':
		case 'FleetLites':
			switch(which) {
				case COLLAPSED:
					icon = ChevronRight;
					break;
				case EXPANDED:
					icon = ChevronDown;
					break;
				case LEAF:
					icon = Building;
					break;
			}
			break;
		case 'Equipment':
			switch(which) {
				case COLLAPSED:
					icon = ChevronRight;
					break;
				case EXPANDED:
					icon = ChevronDown;
					break;
				case LEAF:
					icon = Forklift;
					break;
			}
		default:
	}
	return icon;
}