/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PmSchedulesGridPanel from './PmSchedulesGrid.js';

export default function PmSchedulesFilteredGridPanel(props) {
	return <PmSchedulesGridPanel
				reference="PmSchedulesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}