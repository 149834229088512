/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EnterpriseTypesInlineGridEditorPanel from './EnterpriseTypesInlineGridEditor.js';

export default function EnterpriseTypesFilteredInlineGridEditorPanel(props) {
	return <EnterpriseTypesInlineGridEditorPanel
				reference="EnterpriseTypesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}