/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EnterprisesGrid from './EnterprisesGrid.js';

export default function EnterprisesFilteredGrid(props) {
	return <EnterprisesGrid
				reference="EnterprisesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}