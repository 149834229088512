/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import TagsEditor from '../Editor/TagsEditor.js';
import TagsGridColumns from './Columns/TagsGridColumns.js';

export default function TagsSideGridEditor(props) {
	return <SideGridEditor
				reference="TagsSideGridEditor"
				model="Tags"
				usePermissions={true}
				isCollapsible={false}
				Editor={TagsEditor}
				columnsConfig={TagsGridColumns}
				
				
				{...props}
			/>;
}