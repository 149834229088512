/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PmScheduleModesEditor from '../Editor/PmScheduleModesEditor.js';
import PmScheduleModesGridColumns from './Columns/PmScheduleModesGridColumns.js';

export default function PmScheduleModesSideGridEditor(props) {
	return <SideGridEditor
				reference="PmScheduleModesSideGridEditor"
				model="PmScheduleModes"
				usePermissions={true}
				isCollapsible={false}
				Editor={PmScheduleModesEditor}
				columnsConfig={PmScheduleModesGridColumns}
				
				
				{...props}
			/>;
}