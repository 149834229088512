import oneHatData from '@onehat/data';
import _ from 'lodash';

export default function applyEnterpriseIdToRepositories(enterprise_id) {
	const
		enterpriseIdRepositories = oneHatData.getRepositoriesBy((repository) => repository.schema.usesEnterpriseId);
	_.each(enterpriseIdRepositories, (repository) => {
		let model = repository.schema.name;
		if (model === 'FleetLites') {
			model = 'Fleets';
		}
		repository.setBaseParam('conditions[' + model + '.enterprise_id]', enterprise_id);
		if (repository.isLoaded && !repository.isTree) {
			repository.reload();
		}
	});
}