/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EquipmentTagsEditorWindow from '../../../Window/EquipmentTagsEditorWindow.js';

function EquipmentTagsTag(props) {
	return <Tag
				reference="EquipmentTagsTag"
				model="EquipmentTags"
				uniqueRepository={true}
				Editor={EquipmentTagsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EquipmentTagsTag;