import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Parts = {
	
	name: 'Parts',
	
	model: {

		idProperty: 'parts__id',
		displayProperty: 'parts__part_number',
		sortProperty: 'parts__part_number',
		
		sorters: null,

		validator: yup.object({
			parts__part_number: yup.string().nullable(),
			parts__description: yup.string().nullable(),
			parts__manufacturer_id: yup.number().integer().nullable(),
			parts__part_source_id: yup.number().integer().nullable(),
			parts__unit_price: yup.number().required(),
			parts__unit_sell: yup.number().required(),
			parts__ext_sell: yup.number().required(),
			parts__obsolete_part_numbers: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'parts__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'parts__part_number', mapping: 'part_number', title: 'Part Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'parts__description', mapping: 'description', title: 'Description', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'parts__manufacturer_id', mapping: 'manufacturer_id', title: 'Manufacturer', type: 'int', isFk: true, fkIdField: 'manufacturers__id', fkDisplayField: 'manufacturers__name', filterType: {"type":"ManufacturersCombo","loadAfterRender":!1}, editorType: {"type":"ManufacturersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts__part_source_id', mapping: 'part_source_id', title: 'Part Source', type: 'int', isFk: true, fkIdField: 'part_sources__id', fkDisplayField: 'part_sources__name', filterType: {"type":"PartSourcesCombo","loadAfterRender":!1}, editorType: {"type":"PartSourcesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts__unit_price', mapping: 'unit_price', title: 'Unit Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts__unit_sell', mapping: 'unit_sell', title: 'Unit Sell', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts__ext_sell', mapping: 'ext_sell', title: 'Ext Sell', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts__obsolete_part_numbers', mapping: 'obsolete_part_numbers', title: 'Obsolete Part Numbers', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'manufacturers__id', mapping: 'manufacturer.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__name', mapping: 'manufacturer.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__abbr', mapping: 'manufacturer.abbr', title: 'Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__nds_abbr', mapping: 'manufacturer.nds_abbr', title: 'NDS Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__is_equipment', mapping: 'manufacturer.is_equipment', title: 'For Equipment?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__is_part', mapping: 'manufacturer.is_part', title: 'For Part?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'part_sources__id', mapping: 'part_source.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'part_sources__name', mapping: 'part_source.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'PartsParts'
			],
			belongsTo: [
				'Manufacturers',
				'PartSources'
			],
			belongsToMany: [
				'PartTypes',
				'Parts',
				'WorkOrders'
			],

		},

		ancillaryFilters: {
		    "part_types": "PartType",
		    "parts": "Part",
		    "work_orders": "WorkOrder"
		},

		defaultFilters: [
			// 'parts__manufacturer_id',
			// 'parts__part_source_id',
			// 'parts__unit_price',
			// 'parts__unit_sell',
			// 'parts__ext_sell'
		],

	},

	entity: {
		methods: {

			getManufacturer: async function() {
				const Manufacturers = this.getAssociatedRepository('Manufacturers');
				let entity = Manufacturers.getById(this.parts__manufacturer_id);
				if (!entity) {
					entity = await Manufacturers.getSingleEntityFromServer(this.parts__manufacturer_id);
				}
				return entity;
			},

			getPartSource: async function() {
				const PartSources = this.getAssociatedRepository('PartSources');
				let entity = PartSources.getById(this.parts__part_source_id);
				if (!entity) {
					entity = await PartSources.getSingleEntityFromServer(this.parts__part_source_id);
				}
				return entity;
			},

			getPartsParts: function() {
				const PartsParts = this.getAssociatedRepository('PartsParts');
				return PartsParts.getBy((entity) => {
					return entity.parts_parts__part_id === this.parts__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Parts;
