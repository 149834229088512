import { createSlice } from '@reduxjs/toolkit';
import {
	setDebugMessage,
	setAlertMessage,
	setInfoMessage,
} from './DebugSlice.js';
import { logout } from '../../RootNavigation.js';
import AppGlobals from '../../AppGlobals.js';
import oneHatData from '@onehat/data';
import getTokenHeaders from '@onehat/ui/src/Functions/getTokenHeaders.js';
import setSecure from '@onehat/ui/src/Functions/setSecure';
import deleteSecure from '@onehat/ui/src/Functions/deleteSecure';
import setSaved from '@onehat/ui/src/Functions/setSaved';
import applyEnterpriseIdToRepositories from '../../functions/applyEnterpriseIdToRepositories';
import moment from 'moment';
import _ from 'lodash';


export const appSlice = createSlice({
	name: 'app',
	initialState: {
		appState: 'active',
		user: null,
		groups: null,
		permissions: null,
		enterprise_id: AppGlobals.DEFAULT_ENTERPRISE_ID,
		treeSelection: [],
		currentScreen: null,
		currentTab: 'App',
	},
	reducers: {
		setAppState: (state, action) => {
			state.appState = action.payload
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
		setGroups: (state, action) => {
			state.groups = action.payload
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload
		},
		setEnterpriseId: (state, action) => {
			state.enterprise_id = action.payload
		},
		setTreeSelection: (state, action) => {
			state.treeSelection = action.payload
		},
		setCurrentScreen: (state, action) => {
			state.currentScreen = action.payload
		},
		setCurrentTab: (state, action) => {
			state.currentTab = action.payload;
		},
	}
});


// action definitions

export const {
	setAppState,
	setUser,
	setGroups,
	setPermissions,
	setEnterpriseId,
	setTreeSelection,
	setCurrentScreen,
	setCurrentTab,
} = appSlice.actions;



// thunks
export const loginThunk = (loginResult) => async (dispatch, getState) => {
	const {
			user: userData,
			groups,
			permissions,
		} = loginResult,
		Users = oneHatData.getRepository('Users'),
		user = await Users.createStandaloneEntity(userData);

	await dispatch(setUserThunk(user));
	await dispatch(setGroupsThunk(groups));
	await dispatch(setPermissionsThunk(permissions));
}
export const setUserThunk = (user) => async (dispatch, getState) => {
	dispatch(setUser(user?.getOriginalData()));
	
	let headers;
	if (user) {
		await setSecure('user', user);
		headers = getTokenHeaders();
	} else {
		await deleteSecure('user');
		headers = getTokenHeaders(true); // true for clearAll
	}
	if (!_.isEmpty(headers)) {
		oneHatData.setOptionsOnAllRepositories({ headers }); // already created
		oneHatData.setRepositoryGlobals({ headers }); // not yet created
	}
}
export const setGroupsThunk = (groups) => async (dispatch, getState) => {
	dispatch(setGroups(groups));

	if (!_.isEmpty(groups)) {
		await setSecure('groups', groups);
	} else {
		await deleteSecure('groups');
	}
}
export const setPermissionsThunk = (permissions) => async (dispatch, getState) => {
	if (_.isPlainObject(permissions)) {
		// login returns permissions as an object from the server,
		// so we need to convert it to an array
		permissions = _.map(permissions, (value, key) => {
			if (!parseInt(value)) {
				return null;
			}
			return key;
		});
	}
	dispatch(setPermissions(permissions));

	if (!_.isEmpty(permissions)) {
		await setSecure('permissions', permissions);
	} else {
		await deleteSecure('permissions');
	}
}

export const setEnterpriseIdThunk = (enterprise_id) => async (dispatch, getState) => {
	applyEnterpriseIdToRepositories(enterprise_id);
	dispatch(setEnterpriseId(enterprise_id));

	setSaved('enterprise_id', enterprise_id);
}

export function logoutThunk() {
	return async (dispatch, getState) => {
		
		const Users = oneHatData.getRepository('Users');
		await Users.logout();

		dispatch(setDebugMessage(null));
		dispatch(setAlertMessage(null));
		dispatch(setInfoMessage(null));

		await dispatch(setUserThunk(null));
		await dispatch(setGroupsThunk(null));
		await dispatch(setPermissionsThunk(null));

		// router.replace('/');
		logout();
	};
}




// selectors

export const selectAppState = state => state.app.appState;
export const selectUser = state => state.app.user;
export const selectGroups = state => state.app.groups;
export const selectPermissions = state => state.app.permissions;
export const selectEnterpriseId = state => state.app.enterprise_id;
export const selectTreeSelection = state => state.app.treeSelection;
export const selectCurrentScreen = state => state.app.currentScreen;
export const selectCurrentTab = state => state.app.currentTab;

export default appSlice.reducer;