/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import WoLocationsEditorWindow from '../../Window/WoLocationsEditorWindow.js';
import WoLocationsGridColumns from '../../Grid/Columns/WoLocationsGridColumns.js';

export default function WoLocationsGridEditorPanel(props) {
	return <GridPanel
				reference="WoLocationsGridEditorPanel"
				model="WoLocations"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={WoLocationsEditorWindow}
				columnsConfig={WoLocationsGridColumns}
				
				
				{...props}
			/>;
}