/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqUprightTypesGridEditor from './EqUprightTypesGridEditor.js';

export default function EqUprightTypesFilteredGridEditor(props) {
	return <EqUprightTypesGridEditor
				reference="EqUprightTypesFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}