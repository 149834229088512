/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PmScheduleModesGridPanel from './PmScheduleModesGrid.js';

export default function PmScheduleModesFilteredGridPanel(props) {
	return <PmScheduleModesGridPanel
				reference="PmScheduleModesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}