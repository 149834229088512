import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import GroupsFilteredGridEditor from '../components/Grid/GroupsFilteredGridEditor.js';
import GroupsFilteredSideGridEditor from '../components/Grid/GroupsFilteredSideGridEditor.js';

export default function GroupsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'GroupsManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Groups"
				reference="GroupsManager"
				fullModeComponent={<GroupsFilteredGridEditor
										reference="GroupsFilteredGridEditor"
										useRemoteDuplicate={true}
									/>}
				sideModeComponent={<GroupsFilteredSideGridEditor
										reference="GroupsFilteredGridEditor"
										useRemoteDuplicate={true}
									/>}
			/>;
}
