export default [
	    {
	        "id": "work_orders_tags__work_order_id",
	        "header": "Work Order",
	        "fieldName": "work_orders__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders_tags__work_order_id"
	    },
	    {
	        "id": "work_orders_tags__tag_id",
	        "header": "Tag",
	        "fieldName": "tags__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "work_orders_tags__tag_id"
	    }
	];