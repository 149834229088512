/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import BusinessPartnersFleetsEditorWindow from '../../../Window/BusinessPartnersFleetsEditorWindow.js';

function BusinessPartnersFleetsTag(props) {
	return <Tag
				reference="BusinessPartnersFleetsTag"
				model="BusinessPartnersFleets"
				uniqueRepository={true}
				Editor={BusinessPartnersFleetsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default BusinessPartnersFleetsTag;