export default [
	    {
	        "id": "manufacturers__name",
	        "header": "Name",
	        "fieldName": "manufacturers__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "manufacturers__abbr",
	        "header": "Abbr",
	        "fieldName": "manufacturers__abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "manufacturers__nds_abbr",
	        "header": "NDS Abbr",
	        "fieldName": "manufacturers__nds_abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "manufacturers__is_equipment",
	        "header": "For Equipment?",
	        "fieldName": "manufacturers__is_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "manufacturers__is_part",
	        "header": "For Part?",
	        "fieldName": "manufacturers__is_part",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];