/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WoOtherChargesEditorWindow from '../Window/WoOtherChargesEditorWindow.js';
import WoOtherChargesGridColumns from './Columns/WoOtherChargesGridColumns.js';

export default function WoOtherChargesGridEditor(props) {
	return <WindowedGridEditor
				reference="WoOtherChargesGridEditor"
				model="WoOtherCharges"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={WoOtherChargesEditorWindow}
				columnsConfig={WoOtherChargesGridColumns}
				
				
				{...props}
			/>;
}