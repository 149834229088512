// NOTE: This model is a reduced version of the Fleet model, making an optimized version of Fleets for the FleetTree.
// It utilizes the "FleetLites" controller, which auto-adds the reduced fields and contains to the request.

const FleetLites = {
	
	name: 'FleetLites',
	usesEnterpriseId: false, // it actuall DOES, but being a tree we need to handle it differently
	model: {

		idProperty: 'fleets__id',
		displayProperty: 'fleets__name',
		sortProperty: 'fleets__name',
		isTree: true,
		parentIdProperty: 'parentId',
		depthProperty: 'depth',
		hasChildrenProperty: 'hasChildren',
		
		sorters: null,

		validator: null,
		
		properties: [
			{ name: 'parentId', mapping: 'parentId', type: 'int', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'hasChildren', mapping: 'hasChildren', type: 'bool', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'depth', mapping: 'depth', type: 'int', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'fleets__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
		],

	},

	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false,
		canAdd: false,
		canEdit: false,
		canDelete: false,
	},

};

export default FleetLites;
