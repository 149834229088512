import * as yup from 'yup'; // https://github.com/jquense/yup#string

const EnterpriseTypes = {
	
	name: 'EnterpriseTypes',
	
	model: {

		idProperty: 'enterprise_types__id',
		displayProperty: 'enterprise_types__name',
		sortProperty: 'enterprise_types__name',
		
		sorters: null,

		validator: yup.object({
			enterprise_types__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'enterprise_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'enterprise_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'Enterprises'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getEnterprises: function() {
				const Enterprises = this.getAssociatedRepository('Enterprises');
				return Enterprises.getBy((entity) => {
					return entity.enterprises__enterprise_type_id === this.enterprise_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false,
	    "isPaginated": false
	},

};

export default EnterpriseTypes;
