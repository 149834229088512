/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoModesInlineGridEditorPanel from './WoModesInlineGridEditor.js';

export default function WoModesFilteredInlineGridEditorPanel(props) {
	return <WoModesInlineGridEditorPanel
				reference="WoModesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}