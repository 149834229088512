/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import FleetsGridColumns from './Columns/FleetsGridColumns.js';

export default function FleetsGrid(props) {
	return <Grid
				reference="FleetsGrid"
				model="Fleets"
				usePermissions={true}
				columnsConfig={FleetsGridColumns}

				{...props}
			/>;
}