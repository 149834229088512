import * as yup from 'yup'; // https://github.com/jquense/yup#string

const EqValveTypes = {
	
	name: 'EqValveTypes',
	
	model: {

		idProperty: 'eq_valve_types__id',
		displayProperty: 'eq_valve_types__name',
		sortProperty: 'eq_valve_types__name',
		
		sorters: null,

		validator: yup.object({
			eq_valve_types__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'eq_valve_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'eq_valve_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'Equipment'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getEquipment: function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				return Equipment.getBy((entity) => {
					return entity.equipment__eq_valve_type_id === this.eq_valve_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false,
	    "isPaginated": false
	},

};

export default EqValveTypes;
