/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { InlineGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PartsPartTypesGridColumns from './Columns/PartsPartTypesGridColumns.js';

export default function PartsPartTypesInlineGridEditor(props) {
	return <InlineGridEditor
				reference="PartsPartTypesInlineGridEditor"
				model="PartsPartTypes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				columnsConfig={PartsPartTypesGridColumns}
				
				
				{...props}
			/>;
}