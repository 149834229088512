/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqUprightTypesInlineGridEditor from './EqUprightTypesInlineGridEditor.js';

export default function EqUprightTypesFilteredInlineGridEditor(props) {
	return <EqUprightTypesInlineGridEditor
				reference="EqUprightTypesFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}