/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EnterpriseTypesGridPanel from './EnterpriseTypesGrid.js';

export default function EnterpriseTypesFilteredGridPanel(props) {
	return <EnterpriseTypesGridPanel
				reference="EnterpriseTypesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}