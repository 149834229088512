/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqMonthlyStatsInlineGridEditorPanel from './EqMonthlyStatsInlineGridEditor.js';

export default function EqMonthlyStatsFilteredInlineGridEditorPanel(props) {
	return <EqMonthlyStatsInlineGridEditorPanel
				reference="EqMonthlyStatsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}