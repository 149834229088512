import * as yup from 'yup'; // https://github.com/jquense/yup#string

const UtilErrorCodes = {
	
	name: 'UtilErrorCodes',
	
	model: {

		idProperty: 'util_error_codes__id',
		displayProperty: 'util_error_codes__name',
		sortProperty: 'util_error_codes__name',
		
		sorters: null,

		validator: yup.object({
			util_error_codes__name: yup.string().max(45).required(),
			util_error_codes__description: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'util_error_codes__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'util_error_codes__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'util_error_codes__description', mapping: 'description', title: 'Description', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'UtilErrorLogs'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getUtilErrorLogs: function() {
				const UtilErrorLogs = this.getAssociatedRepository('UtilErrorLogs');
				return UtilErrorLogs.getBy((entity) => {
					return entity.util_error_logs__util_error_code_id === this.util_error_codes__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default UtilErrorCodes;
