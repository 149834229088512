export default [
	    {
	        "id": "pm_events__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "pm_events__equipment_id"
	    },
	    {
	        "id": "pm_events__pm_event_type_id",
	        "header": "PM Event Type",
	        "fieldName": "pm_event_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "pm_events__pm_event_type_id"
	    },
	    {
	        "id": "pm_events__pm_schedule_id",
	        "header": "PM Schedule",
	        "fieldName": "pm_schedules__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "pm_events__pm_schedule_id"
	    },
	    {
	        "id": "pm_events__date",
	        "header": "Date",
	        "fieldName": "pm_events__date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "pm_events__interval",
	        "header": "Interval",
	        "fieldName": "pm_events__interval",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_events__user_id",
	        "header": "Pm Technician",
	        "fieldName": "users__username",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "pm_events__user_id"
	    },
	    {
	        "id": "pm_events__associated_date",
	        "header": "Associated Date",
	        "fieldName": "pm_events__associated_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_events__meter_reading",
	        "header": "Meter Reading",
	        "fieldName": "pm_events__meter_reading",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_events__comments",
	        "header": "Comments",
	        "fieldName": "pm_events__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "pm_events__work_order_id",
	        "header": "Work Order",
	        "fieldName": "work_orders__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "pm_events__work_order_id"
	    }
	];