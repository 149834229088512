/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import TelManufacturersGridEditorPanel from './TelManufacturersGridEditor.js';

export default function TelManufacturersFilteredGridEditorPanel(props) {
	return <TelManufacturersGridEditorPanel
				reference="TelManufacturersFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}