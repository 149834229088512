/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import WorkOrdersEditorWindow from '../../../Window/WorkOrdersEditorWindow.js';

function WorkOrdersTag(props) {
	return <Tag
				reference="WorkOrdersTag"
				model="WorkOrders"
				uniqueRepository={true}
				Editor={WorkOrdersEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default WorkOrdersTag;