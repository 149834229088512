/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EnterprisesEditorWindow from '../../../Window/EnterprisesEditorWindow.js';

function EnterprisesTag(props) {
	return <Tag
				reference="EnterprisesTag"
				model="Enterprises"
				uniqueRepository={true}
				Editor={EnterprisesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EnterprisesTag;