/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import EquipmentTagsGridColumns from '../../Grid/Columns/EquipmentTagsGridColumns.js';

export default function EquipmentTagsGridPanel(props) {
	return <GridPanel
				reference="EquipmentTagsGridPanel"
				model="EquipmentTags"
				usePermissions={true}
				columnsConfig={EquipmentTagsGridColumns}

				{...props}
			/>;
}