/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import EqTypesEditorWindow from '../../Window/EqTypesEditorWindow.js';
import EqTypesGridColumns from '../../Grid/Columns/EqTypesGridColumns.js';

export default function EqTypesGridEditorPanel(props) {
	return <GridPanel
				reference="EqTypesGridEditorPanel"
				model="EqTypes"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={EqTypesEditorWindow}
				columnsConfig={EqTypesGridColumns}
				
				disableDelete={true}
				{...props}
			/>;
}