/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoStatusesInlineGridEditor from './WoStatusesInlineGridEditor.js';

export default function WoStatusesFilteredInlineGridEditor(props) {
	return <WoStatusesInlineGridEditor
				reference="WoStatusesFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}