/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WoLocationsEditor from '../Editor/WoLocationsEditor.js';
import WoLocationsGridColumns from './Columns/WoLocationsGridColumns.js';

export default function WoLocationsSideGridEditor(props) {
	return <SideGridEditor
				reference="WoLocationsSideGridEditor"
				model="WoLocations"
				usePermissions={true}
				isCollapsible={false}
				Editor={WoLocationsEditor}
				columnsConfig={WoLocationsGridColumns}
				
				
				{...props}
			/>;
}