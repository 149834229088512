/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import AddressesGridEditorPanel from './AddressesGridEditor.js';

export default function AddressesFilteredGridEditorPanel(props) {
	return <AddressesGridEditorPanel
				reference="AddressesFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}