export default [
	    {
	        "id": "business_partners_fleets__business_partner_id",
	        "header": "Business Partner",
	        "fieldName": "business_partners__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "business_partners_fleets__business_partner_id"
	    },
	    {
	        "id": "business_partners_fleets__fleet_id",
	        "header": "Fleet",
	        "fieldName": "fleets__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "business_partners_fleets__fleet_id"
	    }
	];