/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import TelOperatorsEditor from '../../Editor/TelOperatorsEditor.js';
import TelOperatorsGridColumns from '../../Grid/Columns/TelOperatorsGridColumns.js';

export default function TelOperatorsSideGridEditorPanel(props) {
	return <GridPanel
				reference="TelOperatorsSideGridEditorPanel"
				model="TelOperators"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={TelOperatorsEditor}
				columnsConfig={TelOperatorsGridColumns}
				
				
				{...props}
			/>;
}