/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import PartsEditorWindow from '../../../Window/PartsEditorWindow.js';

function PartsTagEditor(props) {
	return <TagEditor
				reference="PartsTagEditor"
				model="Parts"
				uniqueRepository={true}
				Editor={PartsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default PartsTagEditor;