/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import UtilProcessLogsEditor from '../Editor/UtilProcessLogsEditor.js';
import UtilProcessLogsGridColumns from './Columns/UtilProcessLogsGridColumns.js';

export default function UtilProcessLogsSideGridEditor(props) {
	return <SideGridEditor
				reference="UtilProcessLogsSideGridEditor"
				model="UtilProcessLogs"
				usePermissions={true}
				isCollapsible={false}
				Editor={UtilProcessLogsEditor}
				columnsConfig={UtilProcessLogsGridColumns}
				getRowProps={(item) => {
					const props = {
						borderBottomWidth: 1,
						borderBottomColor: 'trueGray.500',
					};
					if (!item.properties.util_process_logs__success.submitValue) {
						props.bg = '#ffd1d1';
					}
					return props;
				}}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}