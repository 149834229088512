/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import WoModesGridColumns from '../../Grid/Columns/WoModesGridColumns.js';

export default function WoModesGridPanel(props) {
	return <GridPanel
				reference="WoModesGridPanel"
				model="WoModes"
				usePermissions={true}
				columnsConfig={WoModesGridColumns}

				{...props}
			/>;
}