/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WorkOrdersTagsEditor from '../Editor/WorkOrdersTagsEditor.js';
import WorkOrdersTagsGridColumns from './Columns/WorkOrdersTagsGridColumns.js';

export default function WorkOrdersTagsSideGridEditor(props) {
	return <SideGridEditor
				reference="WorkOrdersTagsSideGridEditor"
				model="WorkOrdersTags"
				usePermissions={true}
				isCollapsible={false}
				Editor={WorkOrdersTagsEditor}
				columnsConfig={WorkOrdersTagsGridColumns}
				
				
				{...props}
			/>;
}