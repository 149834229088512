/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import WoOtherChargeTypesEditorWindow from '../../../Window/WoOtherChargeTypesEditorWindow.js';

function WoOtherChargeTypesTagEditor(props) {
	return <TagEditor
				reference="WoOtherChargeTypesTagEditor"
				model="WoOtherChargeTypes"
				uniqueRepository={true}
				Editor={WoOtherChargeTypesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default WoOtherChargeTypesTagEditor;