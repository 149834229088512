import * as yup from 'yup'; // https://github.com/jquense/yup#string

const WoLocations = {
	
	name: 'WoLocations',
	
	model: {

		idProperty: 'wo_locations__id',
		displayProperty: 'wo_locations__name',
		sortProperty: 'wo_locations__name',
		
		sorters: null,

		validator: yup.object({
			wo_locations__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'wo_locations__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'wo_locations__enterprise_id', mapping: 'enterprise_id', title: 'Enterprise', type: 'int', isFk: true, fkIdField: 'enterprises__id', fkDisplayField: 'enterprises__name', filterType: {"type":"EnterprisesCombo","loadAfterRender":!1}, editorType: {"type":"EnterprisesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'wo_locations__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'enterprises__id', mapping: 'enterprise.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__name', mapping: 'enterprise.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_enabled', mapping: 'enterprise.is_enabled', title: 'Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__enterprise_type_id', mapping: 'enterprise.enterprise_type_id', title: 'Enterprise Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__pm_mode', mapping: 'enterprise.pm_mode', title: 'PM Mode', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__exclude_before', mapping: 'enterprise.exclude_before', title: 'Exclude Before', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_cost_enabled', mapping: 'enterprise.is_cost_enabled', title: 'Cost Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_demo_mode', mapping: 'enterprise.is_demo_mode', title: 'Demo Mode?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__chrome_id', mapping: 'enterprise.chrome_id', title: 'Chrome', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'WorkOrders'
			],
			belongsTo: [
				'Enterprises'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'wo_locations__enterprise_id'
		],

	},

	entity: {
		methods: {

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__wo_location_id === this.wo_locations__id;
				});
			},

			getEnterprise: async function() {
				const Enterprises = this.getAssociatedRepository('Enterprises');
				let entity = Enterprises.getById(this.wo_locations__enterprise_id);
				if (!entity) {
					entity = await Enterprises.getSingleEntityFromServer(this.wo_locations__enterprise_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default WoLocations;
