/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import WoLocationsGridColumns from './Columns/WoLocationsGridColumns.js';

export default function WoLocationsGrid(props) {
	return <Grid
				reference="WoLocationsGrid"
				model="WoLocations"
				usePermissions={true}
				columnsConfig={WoLocationsGridColumns}

				{...props}
			/>;
}