/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoLaborsGrid from './WoLaborsGrid.js';

export default function WoLaborsFilteredGrid(props) {
	return <WoLaborsGrid
				reference="WoLaborsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}