/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import MhBranchesEditorWindow from '../Window/MhBranchesEditorWindow.js';
import MhBranchesGridColumns from './Columns/MhBranchesGridColumns.js';

export default function MhBranchesGridEditor(props) {
	return <WindowedGridEditor
				reference="MhBranchesGridEditor"
				model="MhBranches"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={MhBranchesEditorWindow}
				columnsConfig={MhBranchesGridColumns}
				
				
				{...props}
			/>;
}