/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoPrioritiesSideGridEditor from './WoPrioritiesSideGridEditor.js';

export default function WoPrioritiesFilteredSideGridEditor(props) {
	return <WoPrioritiesSideGridEditor
				reference="WoPrioritiesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}