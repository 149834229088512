/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import TelManufacturersEditorWindow from '../../../Window/TelManufacturersEditorWindow.js';

function TelManufacturersComboEditor(props) {
	return <ComboEditor
				reference="TelManufacturersComboEditor"
				model="TelManufacturers"
				uniqueRepository={true}
				Editor={TelManufacturersEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default TelManufacturersComboEditor;