/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import EnterprisesGridColumns from './Columns/EnterprisesGridColumns.js';

export default function EnterprisesGrid(props) {
	return <Grid
				reference="EnterprisesGrid"
				model="Enterprises"
				usePermissions={true}
				columnsConfig={EnterprisesGridColumns}

				{...props}
			/>;
}