/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import EqPowerTypesGridColumns from '../../Grid/Columns/EqPowerTypesGridColumns.js';

export default function EqPowerTypesGridPanel(props) {
	return <GridPanel
				reference="EqPowerTypesGridPanel"
				model="EqPowerTypes"
				usePermissions={true}
				columnsConfig={EqPowerTypesGridColumns}

				{...props}
			/>;
}