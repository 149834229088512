export default [
	    {
	        "id": "util_process_logs__datetime",
	        "header": "Datetime",
	        "fieldName": "util_process_logs__datetime",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "util_process_logs__process",
	        "header": "Process",
	        "fieldName": "util_process_logs__process",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "util_process_logs__success",
	        "header": "Success",
	        "fieldName": "util_process_logs__success",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "util_process_logs__json",
	        "header": "Json",
	        "fieldName": "util_process_logs__json",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];