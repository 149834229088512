/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import PartsPartTypesEditorWindow from '../../../Window/PartsPartTypesEditorWindow.js';

function PartsPartTypesComboEditor(props) {
	return <ComboEditor
				reference="PartsPartTypesComboEditor"
				model="PartsPartTypes"
				uniqueRepository={true}
				Editor={PartsPartTypesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default PartsPartTypesComboEditor;