/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import TelOperatorsEditorWindow from '../../../Window/TelOperatorsEditorWindow.js';

function TelOperatorsTag(props) {
	return <Tag
				reference="TelOperatorsTag"
				model="TelOperators"
				uniqueRepository={true}
				Editor={TelOperatorsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default TelOperatorsTag;