/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EquipmentTagsInlineGridEditorPanel from './EquipmentTagsInlineGridEditor.js';

export default function EquipmentTagsFilteredInlineGridEditorPanel(props) {
	return <EquipmentTagsInlineGridEditorPanel
				reference="EquipmentTagsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}