/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import WorkOrdersTagsGridColumns from './Columns/WorkOrdersTagsGridColumns.js';

export default function WorkOrdersTagsGrid(props) {
	return <Grid
				reference="WorkOrdersTagsGrid"
				model="WorkOrdersTags"
				usePermissions={true}
				columnsConfig={WorkOrdersTagsGridColumns}

				{...props}
			/>;
}