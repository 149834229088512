/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import EqPowerTypesEditorWindow from '../../Window/EqPowerTypesEditorWindow.js';
import EqPowerTypesGridColumns from '../../Grid/Columns/EqPowerTypesGridColumns.js';

export default function EqPowerTypesGridEditorPanel(props) {
	return <GridPanel
				reference="EqPowerTypesGridEditorPanel"
				model="EqPowerTypes"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={EqPowerTypesEditorWindow}
				columnsConfig={EqPowerTypesGridColumns}
				
				
				{...props}
			/>;
}