/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChromesSideGridEditorPanel from './ChromesSideGridEditor.js';


export default function ChromesFilteredSideGridEditorPanel(props) {
	return <ChromesSideGridEditorPanel
				reference="ChromesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}