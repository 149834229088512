/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PmEventsGridPanel from './PmEventsGrid.js';

export default function PmEventsFilteredGridPanel(props) {
	return <PmEventsGridPanel
				reference="PmEventsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}