export default [
	    {
	        "id": "pm_statuses__name",
	        "header": "Name",
	        "fieldName": "pm_statuses__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];