import * as yup from 'yup'; // https://github.com/jquense/yup#string

const PartsWorkOrders = {
	
	name: 'PartsWorkOrders',
	
	model: {

		idProperty: 'parts_work_orders__id',
		displayProperty: 'parts_work_orders__id',
		sortProperty: 'parts_work_orders__id',
		
		sorters: null,

		validator: yup.object({
			parts_work_orders__part_id: yup.number().integer().nullable(),
			parts_work_orders__work_order_id: yup.number().integer().required(),
			parts_work_orders__qty: yup.number().required(),
			parts_work_orders__unit_price: yup.number().required(),
			parts_work_orders__sell_price: yup.number().required(),
			parts_work_orders__is_in_stock: yup.boolean().required(),
			parts_work_orders__is_checked: yup.boolean().required()
		}),
		
		properties: [
			{ name: 'parts_work_orders__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'parts_work_orders__part_id', mapping: 'part_id', title: 'Part', type: 'int', isFk: true, fkIdField: 'parts__id', fkDisplayField: 'parts__part_number', filterType: {"type":"PartsCombo","loadAfterRender":!1}, editorType: {"type":"PartsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__work_order_id', mapping: 'work_order_id', title: 'Work Order', type: 'int', isFk: true, fkIdField: 'work_orders__id', fkDisplayField: 'work_orders__title', filterType: {"type":"WorkOrdersCombo","loadAfterRender":!1}, editorType: {"type":"WorkOrdersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__qty', mapping: 'qty', title: 'Qty', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'parts_work_orders__unit_price', mapping: 'unit_price', title: 'Unit Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__sell_price', mapping: 'sell_price', title: 'Sell Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__is_in_stock', mapping: 'is_in_stock', title: 'In Stock?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'parts_work_orders__is_checked', mapping: 'is_checked', title: 'Checked?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'parts__id', mapping: 'part.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__part_number', mapping: 'part.part_number', title: 'Part Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__description', mapping: 'part.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__manufacturer_id', mapping: 'part.manufacturer_id', title: 'Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__part_source_id', mapping: 'part.part_source_id', title: 'Part Source', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__unit_price', mapping: 'part.unit_price', title: 'Unit Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__unit_sell', mapping: 'part.unit_sell', title: 'Unit Sell', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__ext_sell', mapping: 'part.ext_sell', title: 'Ext Sell', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__obsolete_part_numbers', mapping: 'part.obsolete_part_numbers', title: 'Obsolete Part Numbers', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__id', mapping: 'work_order.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__enterprise_id', mapping: 'work_order.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__equipment_id', mapping: 'work_order.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_number', mapping: 'work_order.wo_number', title: 'Work Order Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__segment', mapping: 'work_order.segment', title: 'Segment', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__title', mapping: 'work_order.title', title: 'Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__description', mapping: 'work_order.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__reference', mapping: 'work_order.reference', title: 'Reference', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_status_id', mapping: 'work_order.wo_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_parts', mapping: 'work_order.total_parts', title: 'Total Parts', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_labor', mapping: 'work_order.total_labor', title: 'Total Labor', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_other', mapping: 'work_order.total_other', title: 'Total Other', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total', mapping: 'work_order.total', title: 'Total', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__open_date', mapping: 'work_order.open_date', title: 'Open Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__close_date', mapping: 'work_order.close_date', title: 'Close Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoiced_date', mapping: 'work_order.invoiced_date', title: 'Invoiced Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__completed_date', mapping: 'work_order.completed_date', title: 'Completed Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__time_out_of_service', mapping: 'work_order.time_out_of_service', title: 'Time Out Of Service', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_mode_id', mapping: 'work_order.wo_mode_id', title: 'Mode', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_priority_id', mapping: 'work_order.wo_priority_id', title: 'Priority', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_class_id', mapping: 'work_order.wo_class_id', title: 'Class', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoice_number', mapping: 'work_order.invoice_number', title: 'Invoice Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__po_number', mapping: 'work_order.po_number', title: 'PO Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__pm_schedule_id', mapping: 'work_order.pm_schedule_id', title: 'PM Schedule', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__tags', mapping: 'work_order.tags', title: 'Tags', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__meter_reading', mapping: 'work_order.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__comments', mapping: 'work_order.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_location_id', mapping: 'work_order.wo_location_id', title: 'Location', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__repaired_by_company', mapping: 'work_order.repaired_by_company', title: 'Repaired By Company', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__repaired_by_branch', mapping: 'work_order.repaired_by_branch', title: 'Repaired By Branch', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__user_id', mapping: 'work_order.user_id', title: 'User', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_pm_template', mapping: 'work_order.is_pm_template', title: 'Pm Template?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_ltm', mapping: 'work_order.is_ltm', title: 'Ltm?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_scheduled', mapping: 'work_order.is_scheduled', title: 'Scheduled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_verified', mapping: 'work_order.is_verified', title: 'Verified?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__business_partner_id', mapping: 'work_order.business_partner_id', title: 'Business Partner', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_name', mapping: 'work_order.bill_to_name', title: 'Bill To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_street1', mapping: 'work_order.bill_to_street1', title: 'Bill To Street 1', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_street2', mapping: 'work_order.bill_to_street2', title: 'Bill To Street 2', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_city', mapping: 'work_order.bill_to_city', title: 'Bill To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_state_abbr', mapping: 'work_order.bill_to_state_abbr', title: 'Bill To State', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_zip', mapping: 'work_order.bill_to_zip', title: 'Bill To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_name', mapping: 'work_order.ship_to_name', title: 'Ship To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_street1', mapping: 'work_order.ship_to_street1', title: 'Ship To Street 1', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_street2', mapping: 'work_order.ship_to_street2', title: 'Ship To Street 2', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_city', mapping: 'work_order.ship_to_city', title: 'Ship To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_state_abbr', mapping: 'work_order.ship_to_state_abbr', title: 'Ship To State', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_zip', mapping: 'work_order.ship_to_zip', title: 'Ship To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Parts',
				'WorkOrders'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'parts_work_orders__part_id',
			// 'parts_work_orders__work_order_id',
			// 'parts_work_orders__qty',
			// 'parts_work_orders__unit_price',
			// 'parts_work_orders__sell_price',
			// 'parts_work_orders__is_in_stock',
			// 'parts_work_orders__is_checked'
		],

	},

	entity: {
		methods: {

			getPart: async function() {
				const Parts = this.getAssociatedRepository('Parts');
				let entity = Parts.getById(this.parts_work_orders__part_id);
				if (!entity) {
					entity = await Parts.getSingleEntityFromServer(this.parts_work_orders__part_id);
				}
				return entity;
			},

			getWorkOrder: async function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				let entity = WorkOrders.getById(this.parts_work_orders__work_order_id);
				if (!entity) {
					entity = await WorkOrders.getSingleEntityFromServer(this.parts_work_orders__work_order_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default PartsWorkOrders;
