/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WoPrioritiesEditor from '../Editor/WoPrioritiesEditor.js';
import WoPrioritiesGridColumns from './Columns/WoPrioritiesGridColumns.js';

export default function WoPrioritiesSideGridEditor(props) {
	return <SideGridEditor
				reference="WoPrioritiesSideGridEditor"
				model="WoPriorities"
				usePermissions={true}
				isCollapsible={false}
				Editor={WoPrioritiesEditor}
				columnsConfig={WoPrioritiesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}