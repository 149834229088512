/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import BusinessPartnersFleetsEditorWindowSideA from '../Window/BusinessPartnersFleetsEditorWindowSideA.js';
import BusinessPartnersFleetsGridColumnsSideA from './Columns/BusinessPartnersFleetsGridColumnsSideA.js';

export default function BusinessPartnersFleetsGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="BusinessPartnersFleetsGridEditorSideA"
				model="BusinessPartnersFleets"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={BusinessPartnersFleetsEditorWindowSideA}
				columnsConfig={BusinessPartnersFleetsGridColumnsSideA}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}