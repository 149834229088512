import * as yup from 'yup'; // https://github.com/jquense/yup#string

const MhBranches = {
	
	name: 'MhBranches',
	
	model: {

		idProperty: 'mh_branches__id',
		displayProperty: 'mh_branches__name',
		sortProperty: 'mh_branches__name',
		
		sorters: null,

		validator: yup.object({
			mh_branches__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'mh_branches__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'mh_branches__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default MhBranches;
