/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import BusinessPartnersEditorWindow from '../../../Window/BusinessPartnersEditorWindow.js';

function BusinessPartnersComboEditor(props) {
	return <ComboEditor
				reference="BusinessPartnersComboEditor"
				model="BusinessPartners"
				uniqueRepository={true}
				Editor={BusinessPartnersEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default BusinessPartnersComboEditor;