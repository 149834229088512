import * as yup from 'yup'; // https://github.com/jquense/yup#string

const PmSchedules = {
	
	name: 'PmSchedules',
	
	model: {

		idProperty: 'pm_schedules__id',
		displayProperty: 'pm_schedules__name',
		sortProperty: 'pm_schedules__name',
		
		sorters: null,

		validator: yup.object({
			pm_schedules__name: yup.string().required(),
			pm_schedules__interval_hours: yup.number().integer().nullable(),
			pm_schedules__interval_days: yup.number().integer().nullable(),
			pm_schedules__pm_schedule_mode_id: yup.number().integer().required(),
			pm_schedules__repeats: yup.boolean().required()
		}),
		
		properties: [
			{ name: 'pm_schedules__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'pm_schedules__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'pm_schedules__interval_hours', mapping: 'interval_hours', title: 'Interval Hours', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pm_schedules__interval_days', mapping: 'interval_days', title: 'Interval Days', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pm_schedules__pm_schedule_mode_id', mapping: 'pm_schedule_mode_id', title: 'PM Schedule Mode', type: 'int', isFk: true, fkIdField: 'pm_schedule_modes__id', fkDisplayField: 'pm_schedule_modes__name', filterType: {"type":"PmScheduleModesCombo","loadAfterRender":!1}, editorType: {"type":"PmScheduleModesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'pm_schedules__repeats', mapping: 'repeats', title: 'Repeats', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'pm_schedule_modes__id', mapping: 'pm_schedule_mode.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_schedule_modes__name', mapping: 'pm_schedule_mode.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'EquipmentPmSchedules',
				'PmEvents',
				'WorkOrders'
			],
			belongsTo: [
				'PmScheduleModes'
			],
			belongsToMany: [
				'Equipment'
			],

		},

		ancillaryFilters: {
		    "equipment": "Equipment"
		},

		defaultFilters: [
			// 'pm_schedules__interval_hours',
			// 'pm_schedules__interval_days',
			// 'pm_schedules__pm_schedule_mode_id',
			// 'pm_schedules__repeats'
		],

	},

	entity: {
		methods: {

			getEquipmentPmSchedules: function() {
				const EquipmentPmSchedules = this.getAssociatedRepository('EquipmentPmSchedules');
				return EquipmentPmSchedules.getBy((entity) => {
					return entity.equipment_pm_schedules__pm_schedule_id === this.pm_schedules__id;
				});
			},

			getPmScheduleMode: async function() {
				const PmScheduleModes = this.getAssociatedRepository('PmScheduleModes');
				let entity = PmScheduleModes.getById(this.pm_schedules__pm_schedule_mode_id);
				if (!entity) {
					entity = await PmScheduleModes.getSingleEntityFromServer(this.pm_schedules__pm_schedule_mode_id);
				}
				return entity;
			},

			getPmEvents: function() {
				const PmEvents = this.getAssociatedRepository('PmEvents');
				return PmEvents.getBy((entity) => {
					return entity.pm_events__pm_schedule_id === this.pm_schedules__id;
				});
			},

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__pm_schedule_id === this.pm_schedules__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default PmSchedules;
