/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Addresses from './Addresses.js';
// import Attachments from './Attachments.js';
import BusinessPartners from './BusinessPartners.js';
import BusinessPartnersFleets from './BusinessPartnersFleets.js';
import Chromes from './Chromes.js';
import Enterprises from './Enterprises.js';
import EnterpriseTypes from './EnterpriseTypes.js';
import Equipment from './Equipment.js';
import EquipmentPmSchedules from './EquipmentPmSchedules.js';
import EquipmentTags from './EquipmentTags.js';
import EqForkLengths from './EqForkLengths.js';
import EqMonthlyStats from './EqMonthlyStats.js';
import EqPowerTypes from './EqPowerTypes.js';
import EqStats from './EqStats.js';
import EqStatuses from './EqStatuses.js';
import EqTireTypes from './EqTireTypes.js';
import EqTypes from './EqTypes.js';
import EqUprightTypes from './EqUprightTypes.js';
import EqValveTypes from './EqValveTypes.js';
import EqVoltages from './EqVoltages.js';
import Fleets from './Fleets.js';
import FleetLites from './FleetLites.js';
import FleetStats from './FleetStats.js';
import Groups from './Groups.js';
import GroupsUsers from './GroupsUsers.js';
import Local from './Local.js';
import Manufacturers from './Manufacturers.js';
import MeterReadings from './MeterReadings.js';
import MeterSources from './MeterSources.js';
import MhBranches from './MhBranches.js';
import Parts from './Parts.js';
import PartsParts from './PartsParts.js';
import PartsPartTypes from './PartsPartTypes.js';
import PartsWorkOrders from './PartsWorkOrders.js';
import PartSources from './PartSources.js';
import PartTypes from './PartTypes.js';
import Permissions from './Permissions.js';
import PmEvents from './PmEvents.js';
import PmEventTypes from './PmEventTypes.js';
import PmSchedules from './PmSchedules.js';
import PmScheduleModes from './PmScheduleModes.js';
import PmStatuses from './PmStatuses.js';
import Prefs from './Prefs.js';
import Secure from './Secure.js';
import Tags from './Tags.js';
import TagTypes from './TagTypes.js';
import TelManufacturers from './TelManufacturers.js';
import TelOperators from './TelOperators.js';
import TelUsages from './TelUsages.js';
import Users from './Users.js';
import UtilCrudLogs from './UtilCrudLogs.js';
import UtilErrorCodes from './UtilErrorCodes.js';
import UtilErrorLogs from './UtilErrorLogs.js';
import UtilProcessLogs from './UtilProcessLogs.js';
import WorkOrders from './WorkOrders.js';
import WorkOrdersTags from './WorkOrdersTags.js';
import WoClasses from './WoClasses.js';
import WoLabors from './WoLabors.js';
import WoLaborTypes from './WoLaborTypes.js';
import WoLocations from './WoLocations.js';
import WoModes from './WoModes.js';
import WoOtherCharges from './WoOtherCharges.js';
import WoOtherChargeTypes from './WoOtherChargeTypes.js';
import WoPriorities from './WoPriorities.js';
import WoStatuses from './WoStatuses.js';

const obj = {
	Addresses,
	// Attachments,
	BusinessPartners,
	BusinessPartnersFleets,
	Chromes,
	Enterprises,
	EnterpriseTypes,
	Equipment,
	EquipmentPmSchedules,
	EquipmentTags,
	EqForkLengths,
	EqMonthlyStats,
	EqPowerTypes,
	EqStats,
	EqStatuses,
	EqTireTypes,
	EqTypes,
	EqUprightTypes,
	EqValveTypes,
	EqVoltages,
	Fleets,
	FleetLites,
	FleetStats,
	Groups,
	GroupsUsers,
	Local,
	Manufacturers,
	MeterReadings,
	MeterSources,
	MhBranches,
	Parts,
	PartsParts,
	PartsPartTypes,
	PartsWorkOrders,
	PartSources,
	PartTypes,
	Permissions,
	PmEvents,
	PmEventTypes,
	PmSchedules,
	PmScheduleModes,
	PmStatuses,
	Prefs,
	Secure,
	Tags,
	TagTypes,
	TelManufacturers,
	TelOperators,
	TelUsages,
	Users,
	UtilCrudLogs,
	UtilErrorCodes,
	UtilErrorLogs,
	UtilProcessLogs,
	WorkOrders,
	WorkOrdersTags,
	WoClasses,
	WoLabors,
	WoLaborTypes,
	WoLocations,
	WoModes,
	WoOtherCharges,
	WoOtherChargeTypes,
	WoPriorities,
	WoStatuses
};
export default obj;