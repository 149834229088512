/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { InlineGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import UtilErrorCodesGridColumns from './Columns/UtilErrorCodesGridColumns.js';

export default function UtilErrorCodesInlineGridEditor(props) {
	return <InlineGridEditor
				reference="UtilErrorCodesInlineGridEditor"
				model="UtilErrorCodes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				columnsConfig={UtilErrorCodesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}