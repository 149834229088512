/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EquipmentTagsGrid from './EquipmentTagsGrid.js';

export default function EquipmentTagsFilteredGrid(props) {
	return <EquipmentTagsGrid
				reference="EquipmentTagsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}