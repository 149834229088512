/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqTypesEditor from '../Editor/EqTypesEditor.js';
import EqTypesGridColumns from './Columns/EqTypesGridColumns.js';

export default function EqTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="EqTypesSideGridEditor"
				model="EqTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={EqTypesEditor}
				columnsConfig={EqTypesGridColumns}
				
				disableDelete={true}
				{...props}
			/>;
}