/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilCrudLogsInlineGridEditor from './UtilCrudLogsInlineGridEditor.js';

export default function UtilCrudLogsFilteredInlineGridEditor(props) {
	return <UtilCrudLogsInlineGridEditor
				reference="UtilCrudLogsFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}