import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Groups = {
	
	name: 'Groups',
	
	model: {

		idProperty: 'groups__id',
		displayProperty: 'groups__name',
		sortProperty: 'groups__name',
		
		sorters: null,

		validator: yup.object({
			groups__name: yup.string().max(50).nullable(),
			groups__description: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'groups__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'groups__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'groups__description', mapping: 'description', title: 'Description', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'Permissions'
			],
			belongsToMany: [
				'Users'
			],

		},

		ancillaryFilters: {
		    "users": "User"
		},

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getPermissions: function() {
				const Permissions = this.getAssociatedRepository('Permissions');
				return Permissions.getBy((entity) => {
					return entity.permissions__group_id === this.groups__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Groups;
