/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PmEventTypesEditorWindow from '../Window/PmEventTypesEditorWindow.js';
import PmEventTypesGridColumns from './Columns/PmEventTypesGridColumns.js';

export default function PmEventTypesGridEditor(props) {
	return <WindowedGridEditor
				reference="PmEventTypesGridEditor"
				model="PmEventTypes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={PmEventTypesEditorWindow}
				columnsConfig={PmEventTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}