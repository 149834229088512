/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import WoPrioritiesEditorWindow from '../../../Window/WoPrioritiesEditorWindow.js';

function WoPrioritiesComboEditor(props) {
	return <ComboEditor
				reference="WoPrioritiesComboEditor"
				model="WoPriorities"
				uniqueRepository={true}
				Editor={WoPrioritiesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default WoPrioritiesComboEditor;