/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import BusinessPartnersFleetsEditor from '../../Editor/BusinessPartnersFleetsEditor.js';
import BusinessPartnersFleetsGridColumns from '../../Grid/Columns/BusinessPartnersFleetsGridColumns.js';

export default function BusinessPartnersFleetsSideGridEditorPanel(props) {
	return <GridPanel
				reference="BusinessPartnersFleetsSideGridEditorPanel"
				model="BusinessPartnersFleets"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={BusinessPartnersFleetsEditor}
				columnsConfig={BusinessPartnersFleetsGridColumns}
				
				
				{...props}
			/>;
}