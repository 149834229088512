/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import EquipmentPmSchedulesEditor from '../../Editor/EquipmentPmSchedulesEditor.js';
import EquipmentPmSchedulesGridColumns from '../../Grid/Columns/EquipmentPmSchedulesGridColumns.js';

export default function EquipmentPmSchedulesSideGridEditorPanel(props) {
	return <GridPanel
				reference="EquipmentPmSchedulesSideGridEditorPanel"
				model="EquipmentPmSchedules"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={EquipmentPmSchedulesEditor}
				columnsConfig={EquipmentPmSchedulesGridColumns}
				
				
				{...props}
			/>;
}