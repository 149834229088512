export default [
	    {
	        "id": "eq_power_types__name",
	        "header": "Name",
	        "fieldName": "eq_power_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_power_types__sort_order",
	        "header": "Sort Order",
	        "fieldName": "eq_power_types__sort_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];