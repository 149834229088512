/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PmScheduleModesGridEditorPanel from './PmScheduleModesGridEditor.js';

export default function PmScheduleModesFilteredGridEditorPanel(props) {
	return <PmScheduleModesGridEditorPanel
				reference="PmScheduleModesFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}