/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PartsPartsEditor from '../Editor/PartsPartsEditor.js';
import PartsPartsGridColumns from './Columns/PartsPartsGridColumns.js';

export default function PartsPartsSideGridEditor(props) {
	return <SideGridEditor
				reference="PartsPartsSideGridEditor"
				model="PartsParts"
				usePermissions={true}
				isCollapsible={false}
				Editor={PartsPartsEditor}
				columnsConfig={PartsPartsGridColumns}
				
				
				{...props}
			/>;
}