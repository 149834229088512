/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ManufacturersGridColumns from './Columns/ManufacturersGridColumns.js';

export default function ManufacturersGrid(props) {
	return <Grid
				reference="ManufacturersGrid"
				model="Manufacturers"
				usePermissions={true}
				columnsConfig={ManufacturersGridColumns}

				{...props}
			/>;
}