import * as yup from 'yup'; // https://github.com/jquense/yup#string

const PartTypes = {
	
	name: 'PartTypes',
	
	model: {

		idProperty: 'part_types__id',
		displayProperty: 'part_types__name',
		sortProperty: 'part_types__name',
		
		sorters: null,

		validator: yup.object({
			part_types__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'part_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'part_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			belongsToMany: [
				'Parts'
			],

		},

		ancillaryFilters: {
		    "parts": "Part"
		},

		defaultFilters: [

		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default PartTypes;
