export default [
	    {
	        "id": "pm_event_types__name",
	        "header": "Name",
	        "fieldName": "pm_event_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "pm_event_types__sort_order",
	        "header": "Sort Order",
	        "fieldName": "pm_event_types__sort_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_event_types__is_manual",
	        "header": "Manual?",
	        "fieldName": "pm_event_types__is_manual",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_event_types__is_reset",
	        "header": "Reset?",
	        "fieldName": "pm_event_types__is_reset",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_event_types__is_override",
	        "header": "Override?",
	        "fieldName": "pm_event_types__is_override",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_event_types__is_delay",
	        "header": "Delay?",
	        "fieldName": "pm_event_types__is_delay",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_event_types__is_info",
	        "header": "Info?",
	        "fieldName": "pm_event_types__is_info",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];