/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import {
	ENTERPRISE_TYPES__CUSTOMER,
} from '../../constants/EnterpriseTypes.js';
import oneHatData from '@onehat/data';
import EnterprisesEditorWindow from '../Window/EnterprisesEditorWindow.js';
import EnterprisesGridColumns from './Columns/EnterprisesGridColumns.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import { EDITOR_TYPE__PLAIN } from '@onehat/ui/src/Constants/Editor.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert.js';
import withModal from '@onehat/ui/src/Components/Hoc/withModal.js';
import Plus from '@onehat/ui/src/Components/Icons/Plus.js';
import json from '@onehat/ui/src/Functions/jsonValidator';
import AppGlobals from '../../AppGlobals.js';
import * as yup from 'yup';

function EnterprisesGridEditor(props) {
	const
		{
			self,
			alert,
			showInfo,
			showModal,
			hideModal,
		} = props,
		Enterprises = oneHatData.getRepository('Enterprises'),
		onAdd = () => {
			showModal({
				title: 'Create New Enterprise',
				canClose: true,
				includeCancel: true,
				onSubmit,
				submitBtnLabel: 'Create',
				includeReset: true,
				formProps: {
					parent: self,
					editorType: EDITOR_TYPE__PLAIN,
					disableFooter: true,
					columnDefaults: {
						labelWidth: '100%',
					},
					items: [
						{
							label: 'Name',
							name: 'name',
							type: 'Input',
							required: true,
						},
						{
							label: 'Enterprise Type',
							name: 'enterprise_type_id',
							type: 'EnterpriseTypesCombo',
							loadAfterRender: false,
							required: true,
						},

						// TODO: enterprises__pm_mode
						{
							label: 'Exclude Before',
							name: 'exclude_before',
							type: 'Date',
							mode: 'DATE',
							required: true,
						},
						// TODO: enterprises__chrome_id
						{
							label: 'Business Partners',
							name: 'business_partners',
							type: 'BusinessPartnersTagEditor',
							loadAfterRender: false,
							required: true,
						},
					],
					validator: yup.object({
						name: yup.string().required(),
						enterprise_type_id: yup.number().integer().required(),
						exclude_before: yup.date().required(),
						business_partners: json(true),
					}),
					startingValues: {
						name: '',
						enterprise_type_id: ENTERPRISE_TYPES__CUSTOMER,
						exclude_before: AppGlobals.dates.today,
						business_partners: '[]',
					},
				},
				self,
				h: 600, 
				w: 400,
			});
		},
		onSubmit = async () => {
			const
				formValues = self.children.ModalForm.formGetValues(),
				result = await Enterprises._send('POST', 'Enterprises/createNewEnterprise', formValues);
			const response = Enterprises._processServerResponse(result);
			if (response.success) {

				const newId = response.root.id;
				
				// Load the single newly created entity (do this instead of reloading, so it'll be sure to be on the same page)
				await self.repository.loadOneAdditionalEntity(newId);

				// select new record
				const newEntity = self.repository.getById(newId);
				self.setSelection([newEntity]);

				showInfo('Enterprise created successfully');
				hideModal();

			} else {
				alert('Failed to create enterprise. ');
			}
		},
		buttons = [
			{
				key: 'addBtn',
				text: 'Add Enterprise Process',
				icon: Plus,
				handler: onAdd,
			},
		];

	return <WindowedGridEditor
				parent={self}
				reference="EnterprisesGridEditor"
				model="Enterprises"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EnterprisesEditorWindow}
				disableAdd={true}
				contextMenuItems={buttons}
				additionalToolbarButtons={buttons}
				columnsConfig={EnterprisesGridColumns}
				
				{...props}
			/>;
}

export default withComponent(withAlert(withModal(EnterprisesGridEditor)));