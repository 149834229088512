/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import WoPrioritiesEditorWindow from '../../Window/WoPrioritiesEditorWindow.js';
import WoPrioritiesGridColumns from '../../Grid/Columns/WoPrioritiesGridColumns.js';

export default function WoPrioritiesGridEditorPanel(props) {
	return <GridPanel
				reference="WoPrioritiesGridEditorPanel"
				model="WoPriorities"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={WoPrioritiesEditorWindow}
				columnsConfig={WoPrioritiesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}