/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import FleetsEditorWindow from '../../../Window/FleetsEditorWindow.js';

function FleetsComboEditor(props) {
	return <ComboEditor
				reference="FleetsComboEditor"
				model="Fleets"
				uniqueRepository={true}
				Editor={FleetsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default FleetsComboEditor;