/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import AddressesEditorWindow from '../../../Window/AddressesEditorWindow.js';

function AddressesTag(props) {
	return <Tag
				reference="AddressesTag"
				model="Addresses"
				uniqueRepository={true}
				Editor={AddressesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default AddressesTag;