import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Addresses = {
	
	name: 'Addresses',
	
	model: {

		idProperty: 'addresses__id',
		displayProperty: 'addresses__title',
		sortProperty: 'addresses__title',
		
		sorters: null,

		validator: yup.object({
			addresses__title: yup.string().max(50).nullable(),
			addresses__is_enabled: yup.boolean().required(),
			addresses__street1: yup.string().max(50).nullable(),
			addresses__street2: yup.string().max(50).nullable(),
			addresses__city: yup.string().max(50).nullable(),
			addresses__state_abbr: yup.string().max(2).nullable(),
			addresses__zip: yup.string().max(10).nullable(),
			addresses__phone: yup.string().nullable(),
			addresses__fax: yup.string().nullable(),
			addresses__comments: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'addresses__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'addresses__title', mapping: 'title', title: 'Title', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'addresses__is_enabled', mapping: 'is_enabled', title: 'Enabled?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'addresses__street1', mapping: 'street1', title: 'Street1', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'addresses__street2', mapping: 'street2', title: 'Street2', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'addresses__city', mapping: 'city', title: 'City', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'addresses__state_abbr', mapping: 'state_abbr', title: 'State Abbr', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'addresses__zip', mapping: 'zip', title: 'Zip', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'addresses__phone', mapping: 'phone', title: 'Phone', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'addresses__fax', mapping: 'fax', title: 'Fax', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'addresses__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'addresses__is_enabled'
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Addresses;
