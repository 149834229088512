
import {
	Row,
	Text,
} from 'native-base';
import EnterprisesCombo from './EnterprisesCombo';
import { useSelector, useDispatch } from 'react-redux';
import {
	setEnterpriseIdThunk,
	selectEnterpriseId,
} from '../../../../models/Slices/AppSlice';

export default function EnterpriseSelector(props) {
	
	const
		dispatch = useDispatch(),
		enterprise_id = useSelector(selectEnterpriseId),
		onChangeEnterprise = (enterprise_id) => {
			dispatch(setEnterpriseIdThunk(enterprise_id));
		};

	return <Row alignItems="center">
				<Text>Enterprise: </Text>
				<EnterprisesCombo
					value={enterprise_id}
					onChangeValue={onChangeEnterprise}
				/>
			</Row>;
}
