/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoOtherChargesGrid from './WoOtherChargesGrid.js';

export default function WoOtherChargesFilteredGrid(props) {
	return <WoOtherChargesGrid
				reference="WoOtherChargesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}