/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PartSourcesInlineGridEditorPanel from './PartSourcesInlineGridEditor.js';

export default function PartSourcesFilteredInlineGridEditorPanel(props) {
	return <PartSourcesInlineGridEditorPanel
				reference="PartSourcesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}