/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import MeterReadingsEditorWindow from '../Window/MeterReadingsEditorWindow.js';
import MeterReadingsGridColumns from './Columns/MeterReadingsGridColumns.js';

export default function MeterReadingsGridEditor(props) {
	return <WindowedGridEditor
				reference="MeterReadingsGridEditor"
				model="MeterReadings"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={MeterReadingsEditorWindow}
				columnsConfig={MeterReadingsGridColumns}
				
				
				{...props}
			/>;
}