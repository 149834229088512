/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqUprightTypesSideGridEditor from './EqUprightTypesSideGridEditor.js';

export default function EqUprightTypesFilteredSideGridEditor(props) {
	return <EqUprightTypesSideGridEditor
				reference="EqUprightTypesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}