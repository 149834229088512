/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqStatsInlineGridEditorPanel from './EqStatsInlineGridEditor.js';

export default function EqStatsFilteredInlineGridEditorPanel(props) {
	return <EqStatsInlineGridEditorPanel
				reference="EqStatsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}