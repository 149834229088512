/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PartSourcesEditor from '../Editor/PartSourcesEditor.js';
import PartSourcesGridColumns from './Columns/PartSourcesGridColumns.js';

export default function PartSourcesSideGridEditor(props) {
	return <SideGridEditor
				reference="PartSourcesSideGridEditor"
				model="PartSources"
				usePermissions={true}
				isCollapsible={false}
				Editor={PartSourcesEditor}
				columnsConfig={PartSourcesGridColumns}
				
				
				{...props}
			/>;
}