/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EqPowerTypesEditorWindow from '../../../Window/EqPowerTypesEditorWindow.js';

function EqPowerTypesTag(props) {
	return <Tag
				reference="EqPowerTypesTag"
				model="EqPowerTypes"
				uniqueRepository={true}
				Editor={EqPowerTypesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EqPowerTypesTag;