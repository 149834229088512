/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import WoPrioritiesEditor from '../../Editor/WoPrioritiesEditor.js';
import WoPrioritiesGridColumns from '../../Grid/Columns/WoPrioritiesGridColumns.js';

export default function WoPrioritiesSideGridEditorPanel(props) {
	return <GridPanel
				reference="WoPrioritiesSideGridEditorPanel"
				model="WoPriorities"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={WoPrioritiesEditor}
				columnsConfig={WoPrioritiesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}