export default [
	    {
	        "id": "tel_operators__name",
	        "header": "Name",
	        "fieldName": "tel_operators__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "tel_operators__card",
	        "header": "Card",
	        "fieldName": "tel_operators__card",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "tel_operators__type",
	        "header": "Type",
	        "fieldName": "tel_operators__type",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];