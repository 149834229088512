/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqMonthlyStatsGridEditor from './EqMonthlyStatsGridEditor.js';

export default function EqMonthlyStatsFilteredGridEditor(props) {
	return <EqMonthlyStatsGridEditor
				reference="EqMonthlyStatsFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}