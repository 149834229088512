/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PartsPartsGridEditor from './PartsPartsGridEditor.js';

export default function PartsPartsFilteredGridEditor(props) {
	return <PartsPartsGridEditor
				reference="PartsPartsFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}