import * as yup from 'yup'; // https://github.com/jquense/yup#string
import json from '@onehat/ui/src/Functions/jsonValidator';

const UtilProcessLogs = {
	
	name: 'UtilProcessLogs',
	
	model: {

		idProperty: 'util_process_logs__id',
		displayProperty: 'util_process_logs__process',
		sortProperty: 'util_process_logs__datetime',
		
		sorters: null,

		validator: yup.object({
			util_process_logs__datetime: yup.date().required(),
			util_process_logs__process: yup.string().max(25).required(),
			util_process_logs__success: yup.boolean().required(),
			util_process_logs__json: json().nullable()
		}),
		
		properties: [
			{ name: 'util_process_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'util_process_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'util_process_logs__process', mapping: 'process', title: 'Process', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'util_process_logs__success', mapping: 'success', title: 'Success', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'util_process_logs__json', mapping: 'json', title: 'Json', type: 'json', isFilteringDisabled: true, viewerType: {"type":"Json"}, editorType: {"type":"Json"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'util_process_logs__datetime',
			// 'util_process_logs__success'
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default UtilProcessLogs;
