/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import UtilCrudLogsEditor from '../../Editor/UtilCrudLogsEditor.js';
import UtilCrudLogsGridColumns from '../../Grid/Columns/UtilCrudLogsGridColumns.js';

export default function UtilCrudLogsSideGridEditorPanel(props) {
	return <GridPanel
				reference="UtilCrudLogsSideGridEditorPanel"
				model="UtilCrudLogs"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={UtilCrudLogsEditor}
				columnsConfig={UtilCrudLogsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}