/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import TelOperatorsEditor from '../Editor/TelOperatorsEditor.js';
import TelOperatorsGridColumns from './Columns/TelOperatorsGridColumns.js';

export default function TelOperatorsSideGridEditor(props) {
	return <SideGridEditor
				reference="TelOperatorsSideGridEditor"
				model="TelOperators"
				usePermissions={true}
				isCollapsible={false}
				Editor={TelOperatorsEditor}
				columnsConfig={TelOperatorsGridColumns}
				
				
				{...props}
			/>;
}