/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import TreePanel from '@onehat/ui/src/Components/Panel/TreePanel.js';

export default function FleetsTreePanel(props) {
	return <TreePanel
				reference="FleetsTreePanel"
				model="Fleets"
				usePermissions={true}
				{...props}
			/>;
}