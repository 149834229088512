/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import WoStatusesGridColumns from '../../Grid/Columns/WoStatusesGridColumns.js';

export default function WoStatusesGridPanel(props) {
	return <GridPanel
				reference="WoStatusesGridPanel"
				model="WoStatuses"
				usePermissions={true}
				columnsConfig={WoStatusesGridColumns}

				{...props}
			/>;
}