import * as yup from 'yup'; // https://github.com/jquense/yup#string

const TelUsages = {
	
	name: 'TelUsages',
	
	model: {

		idProperty: 'tel_usages__id',
		displayProperty: 'tel_usages__id',
		sortProperty: 'tel_usages__id',
		
		sorters: null,

		validator: yup.object({
			tel_usages__equipment_id: yup.number().integer().required(),
			tel_usages__tel_operator_id: yup.number().integer().nullable(),
			tel_usages__start_date: yup.date().nullable(),
			tel_usages__end_date: yup.date().nullable(),
			tel_usages__midpoint_date: yup.date().nullable(),
			tel_usages__sess_login_time: yup.number().integer().nullable(),
			tel_usages__session_identifier: yup.number().integer().nullable(),
			tel_usages__max_height: yup.number().nullable(),
			tel_usages__max_weight: yup.number().integer().nullable(),
			tel_usages__max_speed: yup.number().nullable(),
			tel_usages__sess_start_fuel_level: yup.number().nullable(),
			tel_usages__sess_end_fuel_level: yup.number().nullable(),
			tel_usages__sess_avg_load: yup.number().nullable(),
			tel_usages__sess_peak_load: yup.number().nullable(),
			tel_usages__sess_total_load: yup.number().nullable(),
			tel_usages__sess_weight_low: yup.number().nullable(),
			tel_usages__sess_weight_med: yup.number().nullable(),
			tel_usages__sess_weight_high: yup.number().nullable(),
			tel_usages__sess_weight_low_time: yup.number().integer().nullable(),
			tel_usages__sess_weight_med_time: yup.number().integer().nullable(),
			tel_usages__sess_weight_high_time: yup.number().integer().nullable(),
			tel_usages__sess_lift_count: yup.number().integer().nullable(),
			tel_usages__sess_height_low: yup.number().nullable(),
			tel_usages__sess_height_med: yup.number().nullable(),
			tel_usages__sess_height_high: yup.number().nullable(),
			tel_usages__sess_height_low_time: yup.number().integer().nullable(),
			tel_usages__sess_height_med_time: yup.number().integer().nullable(),
			tel_usages__sess_height_high_time: yup.number().integer().nullable(),
			tel_usages__sess_avg_speed: yup.number().nullable(),
			tel_usages__sess_peak_speed: yup.number().nullable(),
			tel_usages__sess_speed_low: yup.number().nullable(),
			tel_usages__sess_speed_med: yup.number().nullable(),
			tel_usages__sess_speed_high: yup.number().nullable(),
			tel_usages__sess_speed_low_time: yup.number().integer().nullable(),
			tel_usages__sess_speed_med_time: yup.number().integer().nullable(),
			tel_usages__sess_speed_high_time: yup.number().integer().nullable(),
			tel_usages__sess_speed_over_level1: yup.number().integer().nullable(),
			tel_usages__sess_speed_over_level2: yup.number().integer().nullable(),
			tel_usages__sess_danger: yup.number().nullable(),
			tel_usages__sess_danger_time: yup.number().integer().nullable(),
			tel_usages__sess_key_time: yup.number().integer().nullable(),
			tel_usages__sess_operator_time: yup.number().integer().nullable(),
			tel_usages__sess_traction_time: yup.number().integer().nullable(),
			tel_usages__sess_on_seat_idle_time: yup.number().integer().nullable(),
			tel_usages__sess_off_seat_idle_time: yup.number().integer().nullable(),
			tel_usages__sess_laden_time: yup.number().integer().nullable(),
			tel_usages__sess_laden_traction_time: yup.number().integer().nullable(),
			tel_usages__sess_lift_time: yup.number().integer().nullable(),
			tel_usages__sess_hydraulic_time: yup.number().integer().nullable(),
			tel_usages__sess_forward_gear_time: yup.number().integer().nullable(),
			tel_usages__sess_reverse_gear_time: yup.number().integer().nullable(),
			tel_usages__sess_distance: yup.number().nullable(),
			tel_usages__sess_distance_laden: yup.number().nullable(),
			tel_usages__ltd_key_time: yup.number().integer().nullable(),
			tel_usages__ltd_operator_time: yup.number().integer().nullable(),
			tel_usages__ltd_traction_time: yup.number().integer().nullable(),
			tel_usages__ltd_on_seat_idle_time: yup.number().integer().nullable(),
			tel_usages__ltd_off_seat_idle_time: yup.number().integer().nullable(),
			tel_usages__ltd_laden_time: yup.number().integer().nullable(),
			tel_usages__ltd_laden_moving_time: yup.number().integer().nullable(),
			tel_usages__ltd_lift_time: yup.number().integer().nullable(),
			tel_usages__ltd_hydraulic_time: yup.number().integer().nullable(),
			tel_usages__ltd_forward_gear_time: yup.number().integer().nullable(),
			tel_usages__ltd_reverse_gear_time: yup.number().integer().nullable(),
			tel_usages__ltd_distance: yup.number().nullable(),
			tel_usages__ltd_distance_laden: yup.number().nullable(),
			tel_usages__shift: yup.number().integer().nullable(),
			tel_usages__err_msg: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'tel_usages__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'tel_usages__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__unit_number', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, editorType: {"type":"EquipmentComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'tel_usages__tel_operator_id', mapping: 'tel_operator_id', title: 'Tel Operator', type: 'int', isFk: true, fkIdField: 'tel_operators__id', fkDisplayField: 'tel_operators__name', filterType: {"type":"TelOperatorsCombo","loadAfterRender":!1}, editorType: {"type":"TelOperatorsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'tel_usages__start_date', mapping: 'start_date', title: 'Start Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'tel_usages__end_date', mapping: 'end_date', title: 'End Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'tel_usages__midpoint_date', mapping: 'midpoint_date', title: 'Midpoint Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_login_time', mapping: 'sess_login_time', title: 'Sess Login Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__session_identifier', mapping: 'session_identifier', title: 'Session Identifier', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__max_height', mapping: 'max_height', title: 'Max Height', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__max_weight', mapping: 'max_weight', title: 'Max Weight', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__max_speed', mapping: 'max_speed', title: 'Max Speed', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_start_fuel_level', mapping: 'sess_start_fuel_level', title: 'Sess Start Fuel Level', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_end_fuel_level', mapping: 'sess_end_fuel_level', title: 'Sess End Fuel Level', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_avg_load', mapping: 'sess_avg_load', title: 'Sess Avg Load', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_peak_load', mapping: 'sess_peak_load', title: 'Sess Peak Load', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_total_load', mapping: 'sess_total_load', title: 'Sess Total Load', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_weight_low', mapping: 'sess_weight_low', title: 'Sess Weight Low', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_weight_med', mapping: 'sess_weight_med', title: 'Sess Weight Med', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_weight_high', mapping: 'sess_weight_high', title: 'Sess Weight High', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_weight_low_time', mapping: 'sess_weight_low_time', title: 'Sess Weight Low Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_weight_med_time', mapping: 'sess_weight_med_time', title: 'Sess Weight Med Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_weight_high_time', mapping: 'sess_weight_high_time', title: 'Sess Weight High Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_lift_count', mapping: 'sess_lift_count', title: 'Sess Lift Count', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_height_low', mapping: 'sess_height_low', title: 'Sess Height Low', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_height_med', mapping: 'sess_height_med', title: 'Sess Height Med', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_height_high', mapping: 'sess_height_high', title: 'Sess Height High', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_height_low_time', mapping: 'sess_height_low_time', title: 'Sess Height Low Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_height_med_time', mapping: 'sess_height_med_time', title: 'Sess Height Med Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_height_high_time', mapping: 'sess_height_high_time', title: 'Sess Height High Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_avg_speed', mapping: 'sess_avg_speed', title: 'Sess Avg Speed', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_peak_speed', mapping: 'sess_peak_speed', title: 'Sess Peak Speed', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_speed_low', mapping: 'sess_speed_low', title: 'Sess Speed Low', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_speed_med', mapping: 'sess_speed_med', title: 'Sess Speed Med', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_speed_high', mapping: 'sess_speed_high', title: 'Sess Speed High', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_speed_low_time', mapping: 'sess_speed_low_time', title: 'Sess Speed Low Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_speed_med_time', mapping: 'sess_speed_med_time', title: 'Sess Speed Med Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_speed_high_time', mapping: 'sess_speed_high_time', title: 'Sess Speed High Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_speed_over_level1', mapping: 'sess_speed_over_level1', title: 'Sess Speed Over Level1', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_speed_over_level2', mapping: 'sess_speed_over_level2', title: 'Sess Speed Over Level2', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_danger', mapping: 'sess_danger', title: 'Sess Danger', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_danger_time', mapping: 'sess_danger_time', title: 'Sess Danger Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_key_time', mapping: 'sess_key_time', title: 'Sess Key Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_operator_time', mapping: 'sess_operator_time', title: 'Sess Operator Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_traction_time', mapping: 'sess_traction_time', title: 'Sess Traction Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_on_seat_idle_time', mapping: 'sess_on_seat_idle_time', title: 'Sess On Seat Idle Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_off_seat_idle_time', mapping: 'sess_off_seat_idle_time', title: 'Sess Off Seat Idle Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_laden_time', mapping: 'sess_laden_time', title: 'Sess Laden Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_laden_traction_time', mapping: 'sess_laden_traction_time', title: 'Sess Laden Traction Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_lift_time', mapping: 'sess_lift_time', title: 'Sess Lift Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_hydraulic_time', mapping: 'sess_hydraulic_time', title: 'Sess Hydraulic Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_forward_gear_time', mapping: 'sess_forward_gear_time', title: 'Sess Forward Gear Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_reverse_gear_time', mapping: 'sess_reverse_gear_time', title: 'Sess Reverse Gear Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_distance', mapping: 'sess_distance', title: 'Sess Distance', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__sess_distance_laden', mapping: 'sess_distance_laden', title: 'Sess Distance Laden', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_key_time', mapping: 'ltd_key_time', title: 'Ltd Key Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_operator_time', mapping: 'ltd_operator_time', title: 'Ltd Operator Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_traction_time', mapping: 'ltd_traction_time', title: 'Ltd Traction Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_on_seat_idle_time', mapping: 'ltd_on_seat_idle_time', title: 'Ltd On Seat Idle Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_off_seat_idle_time', mapping: 'ltd_off_seat_idle_time', title: 'Ltd Off Seat Idle Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_laden_time', mapping: 'ltd_laden_time', title: 'Ltd Laden Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_laden_moving_time', mapping: 'ltd_laden_moving_time', title: 'Ltd Laden Moving Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_lift_time', mapping: 'ltd_lift_time', title: 'Ltd Lift Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_hydraulic_time', mapping: 'ltd_hydraulic_time', title: 'Ltd Hydraulic Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_forward_gear_time', mapping: 'ltd_forward_gear_time', title: 'Ltd Forward Gear Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_reverse_gear_time', mapping: 'ltd_reverse_gear_time', title: 'Ltd Reverse Gear Time', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_distance', mapping: 'ltd_distance', title: 'Ltd Distance', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__ltd_distance_laden', mapping: 'ltd_distance_laden', title: 'Ltd Distance Laden', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__shift', mapping: 'shift', title: 'Shift', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'tel_usages__err_msg', mapping: 'err_msg', title: 'Err Msg', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'meter_readings__id', mapping: 'meter_reading.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__equipment_id', mapping: 'meter_reading.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__date', mapping: 'meter_reading.date', title: 'Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__value', mapping: 'meter_reading.value', title: 'Value', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__delta', mapping: 'meter_reading.delta', title: 'Delta', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__is_replacement', mapping: 'meter_reading.is_replacement', title: 'Replacement?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__is_bad', mapping: 'meter_reading.is_bad', title: 'Bad?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__is_pm_clock_reset', mapping: 'meter_reading.is_pm_clock_reset', title: 'Pm Clock Reset?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__comment', mapping: 'meter_reading.comment', title: 'Comment', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__meter_source_id', mapping: 'meter_reading.meter_source_id', title: 'Meter Source', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__work_order_id', mapping: 'meter_reading.work_order_id', title: 'Work Order', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__pm_event_id', mapping: 'meter_reading.pm_event_id', title: 'PM Event', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_readings__tel_usage_id', mapping: 'meter_reading.tel_usage_id', title: 'Tel Usage', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__enterprise_id', mapping: 'equipment.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__fleet_id', mapping: 'equipment.fleet_id', title: 'Fleet', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__manufacturer_id', mapping: 'equipment.manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial_number', mapping: 'equipment.serial_number', title: 'Serial #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__unit_number', mapping: 'equipment.unit_number', title: 'Unit #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__asset_number', mapping: 'equipment.asset_number', title: 'Asset # (Cust ID)', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__dept_number', mapping: 'equipment.dept_number', title: 'Dept #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__business_partner_id', mapping: 'equipment.business_partner_id', title: 'Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_manufacturer_id', mapping: 'equipment.tel_manufacturer_id', title: 'Tel Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_pid', mapping: 'equipment.tel_pid', title: 'Tel Pid', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_misc', mapping: 'equipment.is_misc', title: 'Misc?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_maint_expense', mapping: 'equipment.initial_maint_expense', title: 'Initial Maint $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_book_value', mapping: 'equipment.initial_book_value', title: 'Book Value $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__purchase_price', mapping: 'equipment.purchase_price', title: 'Purchase Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_expires', mapping: 'equipment.warranty_expires', title: 'Warranty Expires', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_hours', mapping: 'equipment.warranty_hours', title: 'Warranty Hours', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'Pm Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_hours_per_month', mapping: 'equipment.expected_hours_per_month', title: 'Expected Hours Per Month', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_hours_per_week', mapping: 'equipment.available_hours_per_week', title: 'Available Hours Per Week', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_track_hours_enable', mapping: 'equipment.is_track_hours_enable', title: 'Track Hours Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__max_hours_allowed', mapping: 'equipment.max_hours_allowed', title: 'Max Hours Allowed', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_revenue_per_month', mapping: 'equipment.expected_revenue_per_month', title: 'Expected Revenue Per Month', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__capacity', mapping: 'equipment.capacity', title: 'Capacity', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_upright_type_id', mapping: 'equipment.eq_upright_type_id', title: 'Upright Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__upright_lhloh', mapping: 'equipment.upright_lhloh', title: 'LH / LoH', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_valve_type_id', mapping: 'equipment.eq_valve_type_id', title: 'Eq Valve Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_power_type_id', mapping: 'equipment.eq_power_type_id', title: 'Eq Power Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_tire_type_id', mapping: 'equipment.eq_tire_type_id', title: 'Eq Tire Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__battery_size', mapping: 'equipment.battery_size', title: 'Battery Size', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_voltage_id', mapping: 'equipment.eq_voltage_id', title: 'Eq Voltage', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_fork_length_id', mapping: 'equipment.eq_fork_length_id', title: 'Eq Fork Length', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_attachments', mapping: 'equipment.eq_attachments', title: 'Attachments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_fluorescent_stripe', mapping: 'equipment.is_fluorescent_stripe', title: 'Fluorescent Striping?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_whelen_light', mapping: 'equipment.is_whelen_light', title: 'Whelen Lights?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_blue_led_light', mapping: 'equipment.is_blue_led_light', title: 'Blue LED Spot Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_high_vis_seatbelt', mapping: 'equipment.is_high_vis_seatbelt', title: 'Hi-Vis Seatbelt?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_rear_handle_horn', mapping: 'equipment.is_rear_handle_horn', title: 'Rear Handle w/Horn?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_contact_decals', mapping: 'equipment.is_contact_decals', title: '3-Point of Contact Decals?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pallet_protection', mapping: 'equipment.is_pallet_protection', title: 'Pallet Protection?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_laser_light', mapping: 'equipment.is_laser_light', title: 'Laser Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_operators__id', mapping: 'tel_operator.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_operators__enterprise_id', mapping: 'tel_operator.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_operators__name', mapping: 'tel_operator.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_operators__card', mapping: 'tel_operator.card', title: 'Card', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_operators__type', mapping: 'tel_operator.type', title: 'Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasOne: [
				'MeterReadings'
			],
			belongsTo: [
				'Equipment',
				'TelOperators'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'tel_usages__equipment_id',
			// 'tel_usages__tel_operator_id',
			// 'tel_usages__start_date',
			// 'tel_usages__end_date',
			// 'tel_usages__midpoint_date',
			// 'tel_usages__sess_login_time',
			// 'tel_usages__session_identifier',
			// 'tel_usages__max_height',
			// 'tel_usages__max_weight',
			// 'tel_usages__max_speed',
			// 'tel_usages__sess_start_fuel_level',
			// 'tel_usages__sess_end_fuel_level',
			// 'tel_usages__sess_avg_load',
			// 'tel_usages__sess_peak_load',
			// 'tel_usages__sess_total_load',
			// 'tel_usages__sess_weight_low',
			// 'tel_usages__sess_weight_med',
			// 'tel_usages__sess_weight_high',
			// 'tel_usages__sess_weight_low_time',
			// 'tel_usages__sess_weight_med_time',
			// 'tel_usages__sess_weight_high_time',
			// 'tel_usages__sess_lift_count',
			// 'tel_usages__sess_height_low',
			// 'tel_usages__sess_height_med',
			// 'tel_usages__sess_height_high',
			// 'tel_usages__sess_height_low_time',
			// 'tel_usages__sess_height_med_time',
			// 'tel_usages__sess_height_high_time',
			// 'tel_usages__sess_avg_speed',
			// 'tel_usages__sess_peak_speed',
			// 'tel_usages__sess_speed_low',
			// 'tel_usages__sess_speed_med',
			// 'tel_usages__sess_speed_high',
			// 'tel_usages__sess_speed_low_time',
			// 'tel_usages__sess_speed_med_time',
			// 'tel_usages__sess_speed_high_time',
			// 'tel_usages__sess_speed_over_level1',
			// 'tel_usages__sess_speed_over_level2',
			// 'tel_usages__sess_danger',
			// 'tel_usages__sess_danger_time',
			// 'tel_usages__sess_key_time',
			// 'tel_usages__sess_operator_time',
			// 'tel_usages__sess_traction_time',
			// 'tel_usages__sess_on_seat_idle_time',
			// 'tel_usages__sess_off_seat_idle_time',
			// 'tel_usages__sess_laden_time',
			// 'tel_usages__sess_laden_traction_time',
			// 'tel_usages__sess_lift_time',
			// 'tel_usages__sess_hydraulic_time',
			// 'tel_usages__sess_forward_gear_time',
			// 'tel_usages__sess_reverse_gear_time',
			// 'tel_usages__sess_distance',
			// 'tel_usages__sess_distance_laden',
			// 'tel_usages__ltd_key_time',
			// 'tel_usages__ltd_operator_time',
			// 'tel_usages__ltd_traction_time',
			// 'tel_usages__ltd_on_seat_idle_time',
			// 'tel_usages__ltd_off_seat_idle_time',
			// 'tel_usages__ltd_laden_time',
			// 'tel_usages__ltd_laden_moving_time',
			// 'tel_usages__ltd_lift_time',
			// 'tel_usages__ltd_hydraulic_time',
			// 'tel_usages__ltd_forward_gear_time',
			// 'tel_usages__ltd_reverse_gear_time',
			// 'tel_usages__ltd_distance',
			// 'tel_usages__ltd_distance_laden',
			// 'tel_usages__shift'
		],

	},

	entity: {
		methods: {

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.tel_usages__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.tel_usages__equipment_id);
				}
				return entity;
			},

			getTelOperator: async function() {
				const TelOperators = this.getAssociatedRepository('TelOperators');
				let entity = TelOperators.getById(this.tel_usages__tel_operator_id);
				if (!entity) {
					entity = await TelOperators.getSingleEntityFromServer(this.tel_usages__tel_operator_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default TelUsages;
