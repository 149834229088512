/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilProcessLogsGridPanel from './UtilProcessLogsGrid.js';

export default function UtilProcessLogsFilteredGridPanel(props) {
	return <UtilProcessLogsGridPanel
				reference="UtilProcessLogsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}