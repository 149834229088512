/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UtilErrorLogsInlineGridEditorPanel from './UtilErrorLogsInlineGridEditor.js';

export default function UtilErrorLogsFilteredInlineGridEditorPanel(props) {
	return <UtilErrorLogsInlineGridEditorPanel
				reference="UtilErrorLogsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}