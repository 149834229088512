/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import UtilErrorCodesEditor from '../Editor/UtilErrorCodesEditor.js';
import UtilErrorCodesGridColumns from './Columns/UtilErrorCodesGridColumns.js';

export default function UtilErrorCodesSideGridEditor(props) {
	return <SideGridEditor
				reference="UtilErrorCodesSideGridEditor"
				model="UtilErrorCodes"
				usePermissions={true}
				isCollapsible={false}
				Editor={UtilErrorCodesEditor}
				columnsConfig={UtilErrorCodesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}