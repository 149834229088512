/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ArrayCombo from '@onehat/ui/src/Components/Form/Field/Combo/ArrayCombo.js';


const data = [
	['add', 'Add'],
	['edit', 'Edit'],
	['delete', 'Delete'],
];

function CrudOperationsCombo(props) {
	return <ArrayCombo
				data={data}
				disableDirectEntry={true}
				{...props}
			/>;
}

export default CrudOperationsCombo;
