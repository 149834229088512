/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoOtherChargeTypesSideGridEditorPanel from './WoOtherChargeTypesSideGridEditor.js';


export default function WoOtherChargeTypesFilteredSideGridEditorPanel(props) {
	return <WoOtherChargeTypesSideGridEditorPanel
				reference="WoOtherChargeTypesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}