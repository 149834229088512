/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import MhBranchesEditor from '../Editor/MhBranchesEditor.js';
import MhBranchesGridColumns from './Columns/MhBranchesGridColumns.js';

export default function MhBranchesSideGridEditor(props) {
	return <SideGridEditor
				reference="MhBranchesSideGridEditor"
				model="MhBranches"
				usePermissions={true}
				isCollapsible={false}
				Editor={MhBranchesEditor}
				columnsConfig={MhBranchesGridColumns}
				
				
				{...props}
			/>;
}