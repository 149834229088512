/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoModesGridEditorPanel from './WoModesGridEditor.js';

export default function WoModesFilteredGridEditorPanel(props) {
	return <WoModesGridEditorPanel
				reference="WoModesFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}